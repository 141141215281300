// TODO move to shared place out of journeys
import {
    operatorRequiresPropertyValue,
    internalIsDateComparison,
} from "Pages/journeys/JourneyEditPage/Components/JourneyBuilder/Components/JourneyActionsPanel/NodeEditorPanel/Components/ConditionalFormItems/components/helpers";
import { NodeTriggerType } from "Types/Journey";
import { DynamicCondition, JoinType, ConditionEvent, EventProperty } from "Types/Segment";

import { LayoutDirection } from "../Types";

export function getGapSize(direction: LayoutDirection): number {
    return direction === "horizontal" ? 3 : 2;
}

export function joinTypeToString(joinType: JoinType | undefined): string {
    switch (joinType) {
        case JoinType.ALL:
            return "AND";
        case JoinType.ANY:
            return "OR";
        case JoinType.NONE:
            return "NOR";
    }
    return "OR";
}

export function operatorRequiresValue(operator?: string): boolean {
    return !!operator && (internalIsDateComparison(operator) || operatorRequiresPropertyValue(operator));
}

export function operatorRequiresListOfValues(operator?: string): boolean {
    return !!operator && ["isOneOf"].includes(operator);
}

function hasNameAndOperator(filter: EventProperty) {
    return filter.propertyName != null && filter.propertyOperator != null;
}

function isValidFilter(filter: EventProperty): boolean {
    const { propertyOperator, propertyValue, relativeDateValue, dateComparisonType } = filter || {};
    if (!hasNameAndOperator(filter)) {
        return false;
    }
    if (operatorRequiresValue(propertyOperator)) {
        if (internalIsDateComparison(propertyOperator) && dateComparisonType === "relative") {
            return relativeDateValue != null;
        }
        return propertyValue != null;
    }
    return true;
}

export function isValidEventGroup(conditionEvent: ConditionEvent): boolean {
    const { conditionalType, eventName } = conditionEvent;
    if (conditionalType == null) {
        return false;
    }
    if (conditionalType !== "contactAttribute" && eventName == null) {
        return false;
    }

    if (
        [NodeTriggerType.AddContactToStaticSegment, NodeTriggerType.Pageview].includes(conditionalType as any) &&
        eventName == null
    ) {
        return false;
    }
    return conditionalType == "contactAttribute"
        ? conditionEvent.filters.length > 0 && conditionEvent.filters.every((filter) => isValidFilter(filter))
        : conditionEvent.filters.every((filter) => isValidFilter(filter));
}

export function isValidConditionGroup(conditionGroup: DynamicCondition): boolean {
    return (
        conditionGroup.conditions?.length > 0 &&
        conditionGroup.conditions?.every((conditionEvent) => isValidEventGroup(conditionEvent))
    );
}
