import { createContext, useContext, useMemo, ReactNode } from "react";

import { useToggle } from "Hooks/useToggle";

export interface ValidationContextInterface {
    validationEnabled: boolean;
    enableValidation: (enable: boolean) => void;
}

export const FormValidationContext = createContext<ValidationContextInterface | null>(null);

export function ValidationContextProvider(props: { validationEnabled?: boolean; children: ReactNode }): JSX.Element {
    const validationEnabledOuter =
        useContext(FormValidationContext)?.validationEnabled || props.validationEnabled || false;
    const [validationEnabled, enableValidation] = useToggle(false);
    // enableValidation function is stable (it will not change on every render)
    // so it does not need to be wrapped in useCallback
    const contextValue = useMemo(
        () => ({ validationEnabled: validationEnabled || validationEnabledOuter, enableValidation }),
        [validationEnabledOuter, validationEnabled, enableValidation]
    );

    return <FormValidationContext.Provider value={contextValue}>{props.children}</FormValidationContext.Provider>;
}

export function useValidation(): ValidationContextInterface {
    const context = useContext(FormValidationContext);
    if (context == null) {
        throw new Error("useValidation must be used within a ValidationContextProvider");
    }
    return context;
}

export function withValidationContext<T extends Record<string, any>>(
    Component: React.ComponentType<T>
): React.ComponentType<T> {
    return function WithValidationContext(props: T & { validationEnabled?: boolean }): JSX.Element {
        return (
            <ValidationContextProvider validationEnabled={props.validationEnabled}>
                <Component {...props} />
            </ValidationContextProvider>
        );
    };
}
