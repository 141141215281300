import { parsePhoneNumber, parsePhoneNumberWithError, PhoneNumber } from "libphonenumber-js";

import { getLogger } from "Services/LoggingService";

const logger = getLogger("PhoneNumbers");

export const VALID_PHONE_CHARACTERS_REGEX = /^[\+\-\(\)\s\d]+$/;

/**
 *
 * @example "16137791348" -> "+1 (613) 779-1348"
 * @example "613779" -> "+1 613779"
 * @example "1613779" -> "+1 1613779"
 * @example undefined ->  ""
 * @example "Jane Doe" -> "Jane Doe"
 * @example "+1Jane Doe97" -> "+1Jane Doe97"
 * @example "+543513740247" -> "+543513740247"
 */
export function formatPhoneNumber(value: string | number | undefined | null, defaultValue = ""): string {
    if (!value) {
        return defaultValue;
    }

    if (typeof value === "string" && !VALID_PHONE_CHARACTERS_REGEX.test(value)) {
        return value;
    }

    const valueAsStr = String(value);
    let numberValue;
    try {
        numberValue = parsePhoneNumber(valueAsStr, "US");
    } catch (error) {
        logger.warn("Unable to parse phone number", { value: valueAsStr, error });
        return valueAsStr;
    }
    if (Number(numberValue.countryCallingCode) !== 1) {
        return numberValue.format("E.164");
    }

    const localNumber = numberValue.format("NATIONAL").trim();
    return `+1 ${localNumber}`;
}

export function formatToE164(phoneNumber?: string | null): string {
    if (!phoneNumber) {
        return "";
    }

    const digits = phoneNumber.replace(/\D/g, "").trim();
    if (!digits) {
        return "";
    }
    let numberValue: PhoneNumber;
    try {
        /**
         * The reason for not using `digits` here is because we want to maintain the
         * original country code, if there was one.
         */
        numberValue = parsePhoneNumber(phoneNumber, "US");
    } catch (error) {
        logger.warn("Unable to parse phone number", { value: phoneNumber, error });
        return phoneNumber;
    }

    return numberValue.format("E.164");
}

export function isPhoneNumberValid(phoneNumber: string): boolean {
    try {
        const pn = parsePhoneNumberWithError(phoneNumber, "US");
        return pn.isValid();
    } catch (error) {
        return false;
    }
}

export function isE164Format(phoneNumber: string): boolean {
    let numberValue: PhoneNumber;
    try {
        numberValue = parsePhoneNumber(phoneNumber, "US");
    } catch {
        return false;
    }

    return numberValue.format("E.164") === phoneNumber;
}

export function arePhoneNumbersEqual(targetPhone: string, destinationPhone: string): boolean {
    if (!(targetPhone && destinationPhone)) {
        return false;
    }
    return formatToE164(targetPhone) === formatToE164(destinationPhone);
}

export function getSmsError(e: any): string {
    if (e?.code) {
        switch (e.code) {
            case "invalid_phone_number": {
                return "Invalid phone number";
            }
            case "chat_already_in_progress": {
                return "Chat already in progress";
            }
        }
    }
    return "Task failed to be created";
}

type ContactLike = {
    firstName?: string;
    lastName?: string;
    contactPhone?: string;
    email?: string;
    name?: string;
};

/**
 * The reason why we have this logic here is because we don't want to delegate this decision to the API.
 * The API should just return the name/s, and the UI should decide where it replaces the name with the phone number.
 * Throwing this into a hook will not improve performance at all, hence we can just invoke it from components.
 * @param contact returned by API. Could contain "name" virtual prop or not
 * @returns properly formatted display name string for this contact
 */
export function formatContactDisplayName(contact: null | ContactLike): string | null {
    if (!contact) {
        return null;
    }
    const { firstName, lastName, contactPhone, email, name } = contact;

    if (name && name.length) {
        return name;
    }

    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    }

    if (firstName || lastName) {
        return (firstName || lastName) as string;
    }

    if (contactPhone) {
        return formatPhoneNumber(contactPhone);
    }

    if (email) {
        return email;
    }

    return null;
}

export function compareContacts(a: ContactLike, b: ContactLike): number {
    const aName = formatContactDisplayName(a);
    const bName = formatContactDisplayName(b);

    if (aName && bName) {
        return aName.localeCompare(bName);
    }

    if (aName) {
        return -1;
    }

    if (bName) {
        return 1;
    }

    return 0;
}
