/**
 * @Todo: consolidate this and utlis.ts when we move all the components
 * into their own directory, and have a clearler understanding of levels
 */
import { NodeTriggerType } from "Types/Journey";

export interface OptionsData extends Array<any> {
    value?: string;
}

export interface FieldData {
    name: string;
    value: any;
    touched?: boolean;
}

export function caseInsensitiveFilter(inputValue: any, option: any): boolean {
    return option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1;
}

export function showContactConditions(triggerType: NodeTriggerType): boolean {
    return [NodeTriggerType.Custom, NodeTriggerType.Pageview, NodeTriggerType.RegalVoice].includes(triggerType);
}
