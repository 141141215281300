import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { ContactAttribute } from "Types/ContactAttribute";

const initialState = {
    contactAttributes: {} as Record<string, ContactAttribute>,
};

const ContactAttributeSlice = createSlice({
    name: "ContactAttributesState",
    initialState,
    reducers: {
        setAllContactAttributes(state, action: PayloadAction<Record<string, ContactAttribute>>) {
            state.contactAttributes = action.payload;
        },
        setContactAttribute(state, action: PayloadAction<ContactAttribute>) {
            state.contactAttributes[action.payload.id] = action.payload;
        },
    },
});

export const { setContactAttribute, setAllContactAttributes } = ContactAttributeSlice.actions;
export default ContactAttributeSlice.reducer;
