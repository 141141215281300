import { createSlice, createEntityAdapter, createAsyncThunk } from "@reduxjs/toolkit";

import { RVDispatch, RootState } from "../Storage";
import { contactsSelectors, selectContactsByPhone } from "./Selectors/Selectors";

import type { Profile } from "Types/Profile";

export const contactsAdapter = createEntityAdapter<Profile>();

// use the contactsAdapter.getInitialState() to get the initial state type
export type ContactsState = ReturnType<typeof contactsAdapter.getInitialState>;

const ContactsStateSlice = createSlice({
    name: "Contacts",
    initialState: contactsAdapter.getInitialState(),
    reducers: {
        setContact: contactsAdapter.upsertOne,
        clearContacts: contactsAdapter.removeAll,
        upsertContacts: contactsAdapter.upsertMany,
        clearContact: contactsAdapter.removeOne,
        clearManyContacts: contactsAdapter.removeMany,
    },
});

export const { clearContacts } = ContactsStateSlice.actions;

const {
    setContact: setContactOnAdapter,
    upsertContacts: upsertContactsOnAdapter,
    clearManyContacts,
} = ContactsStateSlice.actions;

export default ContactsStateSlice.reducer;

export const setContact = createAsyncThunk<void, Profile, { state: RootState; dispatch: RVDispatch }>(
    "contactsState/setContact",
    (contact, { dispatch }) => {
        if (contact) {
            // remove existing contact with given phone
            const { contactPhone } = contact;
            contactPhone && dispatch(clearContactsByPhone(contactPhone));
            // remove existing contacts with any of this contact's phones. this is a double check, it shouldn't be needed
            const allPhones = contact.phones?.map(({ phoneNumber }) => phoneNumber);
            if (allPhones?.length) {
                dispatch(clearContactsByPhoneList(allPhones));
            }
            dispatch(setContactOnAdapter(contact));
        }
    }
);

export const upsertContacts = createAsyncThunk<void, Profile[], { state: RootState; dispatch: RVDispatch }>(
    "contactsState/upsertContacts",
    (contacts, { dispatch }) => {
        // remove existing contacts
        const contactPhones = contacts.reduce<string[]>((acc, contact) => {
            if (contact.contactPhone) {
                acc.push(contact.contactPhone);
            }
            return acc;
        }, []);
        dispatch(clearContactsByPhoneList(contactPhones));
        if (contacts) {
            dispatch(upsertContactsOnAdapter(contacts));
        }
    }
);

const clearContactsByPhone = createAsyncThunk<void, string, { state: RootState }>(
    "contactsState/clearContactByPhone",
    (contactPhone, { dispatch, getState }) => {
        const existingContacts = selectContactsByPhone(getState(), contactPhone);
        if (existingContacts.length) {
            dispatch(clearManyContacts(existingContacts.map((c) => c.id)));
        }
    }
);

const clearContactsByPhoneList = createAsyncThunk<void, string[], { state: RootState }>(
    "contactsState/clearContactsByPhoneList",
    (contactPhones, { dispatch, getState }) => {
        const allContacts = contactsSelectors.selectAll(getState());
        const foundContacts = allContacts.filter(
            (c) =>
                (c.contactPhone && contactPhones.includes(c.contactPhone)) ||
                c.phones?.map(({ phoneNumber }) => phoneNumber).some(contactPhones.includes)
        );
        if (foundContacts.length) {
            dispatch(clearManyContacts(foundContacts.map((c) => c.id)));
        }
    }
);
