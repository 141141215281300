import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ManagerAction } from "Pages/tasks/TaskDetails/Components/ManagerCallControls/ManagerCallControlConstants";

export type NullableManagerAction = ManagerAction | "disabled" | null;

export type ManagerActionState = {
    taskSid: string | null;
    callSid: string | null;
    activeAction: NullableManagerAction;
    loading: NullableManagerAction;
};

const initialState: ManagerActionState = {
    taskSid: null,
    callSid: null,
    activeAction: null,
    loading: null,
};

const ManagerActionStateSlice = createSlice({
    name: "managerAction",
    initialState,
    reducers: {
        setActionData(state, action: PayloadAction<Partial<ManagerActionState>>): ManagerActionState {
            return {
                ...state,
                ...action.payload,
            };
        },
        setLoading(state, action: PayloadAction<NullableManagerAction>): ManagerActionState {
            return {
                ...state,
                loading: action.payload,
            };
        },
        clearManagerAction(): ManagerActionState {
            return initialState;
        },
    },
});

export const {
    actions: { setActionData, setLoading, clearManagerAction },
    name,
    reducer,
} = ManagerActionStateSlice;
