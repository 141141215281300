import { useMemo, useCallback, useState, useRef } from "react";

import { Popover, Tooltip } from "antd";

import { IconSizes } from "Components/elements/Icon";
import { RVButton } from "Components/elements/RVButton/RVButton";

import { AdvancedStats } from "./AdvancedStats/AdvancedStats";
import { BasicStats } from "./BasicStats/BasicStats";
import { usePopoverVisibilityManager } from "./usePopoverVisibilityManager";

import styles from "./AgentStats.module.scss";

type StatProps = {
    showAdvancedStats: boolean;
};

export function AgentStats({ showAdvancedStats }: StatProps): JSX.Element {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const popoverRef = useRef<typeof Popover>(null);

    const visibilitySideEffect = useCallback(() => {
        setIsTooltipVisible(false);
    }, []);

    const { isPopoverVisible, finishedOpening, handlePopoverVisibilityChange } = usePopoverVisibilityManager({
        popoverRef,
        visibilitySideEffect,
    });

    const handleHoverDisplay = useCallback(() => {
        if (!isPopoverVisible) {
            setIsTooltipVisible(true);
        }
    }, [isPopoverVisible]);

    const handleMouseLeave = useCallback(() => {
        setIsTooltipVisible(false);
    }, []);

    const StatsContent = useMemo(() => {
        if (showAdvancedStats) {
            return <AdvancedStats shouldRefetch={isPopoverVisible} _finishedOpening={finishedOpening} />;
        }
        return <BasicStats shouldRefetch={isPopoverVisible} />;
    }, [showAdvancedStats, isPopoverVisible, finishedOpening]);

    return (
        <Popover
            ref={popoverRef}
            overlayClassName={styles.agentStatsPopoverNS}
            content={StatsContent}
            placement="bottomRight"
            trigger={["click"]}
            onOpenChange={handlePopoverVisibilityChange}
            showArrow={!showAdvancedStats}
        >
            <Tooltip title="Today's Progress" open={isTooltipVisible} mouseLeaveDelay={0}>
                <RVButton
                    aria-label="Agent Stats"
                    onMouseEnter={handleHoverDisplay}
                    onMouseLeave={handleMouseLeave}
                    className={styles.statButton}
                    data-testid="toggle-stats"
                    icon="trophy"
                    iconSize={IconSizes.DEFAULT}
                    shape="circle"
                    size="middle"
                />
            </Tooltip>
        </Popover>
    );
}
