import { useCallback } from "react";

import { useLazyQuery } from "@apollo/client";
import { NullOr } from "@regal-voice/shared-types";
import { useSelector } from "react-redux";

import { getLogger } from "Services/LoggingService";
import { type GetProfileReturn, getProfile } from "Services/marketing-api/audience/queries";
import { selectAgentInformation } from "Services/state/agent/AgentInformationSlice";
import { selectBrand } from "Services/state/brand";
import { CommunicationSource } from "Types/CommunicationSource";

import type { Profile } from "Types/Profile";

const logger = getLogger("useDefaultFromNumber");

type UseDefaultFromNumber = {
    defaultFromNumber: string;
    /**
     * Allows to get the right From Number given just a contactPhone (without having the prefetched Contact)
     */
    getDefaultFromNumberForContactPhone: (contactPhone: string) => Promise<string>;
};

function findSubBrandPhoneNumber(
    communicationSources?: NullOr<CommunicationSource[]>,
    subBrand?: string
): string | undefined {
    if (!communicationSources || !subBrand) {
        return;
    }
    const communicationSource = communicationSources.find(
        (communicationSource) => communicationSource.subBrand === subBrand
    );
    return communicationSource?.source;
}

export function useDefaultFromNumber({ contact }: { contact?: Partial<Profile> }): UseDefaultFromNumber {
    const brandInfo = useSelector(selectBrand);
    const agentInformation = useSelector(selectAgentInformation);
    const defaultBrandFromNumber = brandInfo?.defaultFromNumber;

    const [lazyGetProfile] = useLazyQuery<GetProfileReturn>(getProfile, {
        fetchPolicy: "no-cache",
    });

    const subBrandPhoneNumber = findSubBrandPhoneNumber(
        brandInfo?.communicationSources,
        contact?.rvProperties?.sub_brand as string | undefined
    );

    const defaultAgentPhoneNumber = agentInformation.attributes?.agentRegalVoicePhone;

    const getDefaultFromNumberForContactPhone = useCallback(
        async (contactPhone: string) => {
            let subBrandPhoneNumber: string | undefined;
            try {
                const response = await lazyGetProfile({
                    fetchPolicy: "no-cache",
                    variables: {
                        contactPhone,
                    },
                });
                if (response.data?.getProfile) {
                    subBrandPhoneNumber = findSubBrandPhoneNumber(
                        brandInfo?.communicationSources,
                        response.data.getProfile.rvProperties.sub_brand as string | undefined
                    );
                }
            } catch (e) {
                logger.error("Error fetching profile while calculating default from number", { error: e });
            }
            return defaultAgentPhoneNumber || subBrandPhoneNumber || defaultBrandFromNumber;
        },
        [brandInfo?.communicationSources, defaultAgentPhoneNumber, defaultBrandFromNumber, lazyGetProfile]
    );

    return {
        defaultFromNumber: defaultAgentPhoneNumber || subBrandPhoneNumber || defaultBrandFromNumber,
        getDefaultFromNumberForContactPhone,
    };
}
