import { gql } from "@apollo/client";

export const GetTeamsData = gql`
    query getTeamsData {
        getTeams {
            id
            name
        }
    }
`;
