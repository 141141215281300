const API_PROXY = "/api";
const SERVICES = {
    api: API_PROXY,
    conv: "/conv",
    ingest: `${API_PROXY}/proxy/ingest`,
} as const;

type ServiceName = keyof typeof SERVICES;

const getServiceUrl = (name: ServiceName): string => {
    return SERVICES[name];
};

export const getIngestUrl = (): string => {
    return getServiceUrl("ingest");
};

export const getApiUrl = (): string => {
    return getServiceUrl("api");
};

export const getConvUrl = (): string => {
    return getServiceUrl("conv");
};
