import { createContext, useContext, useLayoutEffect, useEffect, useState } from "react";

import { Layout as AntLayout } from "antd";
import { clsx } from "clsx";
import { noop } from "lodash";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";

import { Loading } from "Components/elements/Loading";
import AgentNavBarButtons from "Components/shared/AgentNavBarButtons/AgentNavBarButtons";
import { AgentStatusDropdown } from "Components/shared/AgentStatusDropdown/AgentStatusDropdown";
import { getEmbedUiConfigByKey } from "Services/embed/CrossDomainMessenger";
import { ReactSetter } from "Types/ReactState";

import SideNav from "./SideNavBar";

import styles from "./Layout.module.scss";

const PageContext = createContext<{
    setShowSidebar: ReactSetter<boolean>;
    setPageTitle: ReactSetter<string | undefined>;
}>({ setShowSidebar: noop, setPageTitle: noop });

export function usePageConfig({
    showSidebar = true,
    pageTitle,
}: {
    showSidebar?: boolean;
    pageTitle?: string;
} = {}): void {
    const { setShowSidebar, setPageTitle } = useContext(PageContext);

    useLayoutEffect(() => {
        setShowSidebar(showSidebar);
    }, [setShowSidebar, showSidebar]);

    useEffect(() => setPageTitle(pageTitle), [pageTitle, setPageTitle]);
}

export default function Layout({ children, pageReady }: { children: any; pageReady: boolean }): JSX.Element {
    const [showSidebar, setShowSidebar] = useState(false);
    const [pageTitle, setPageTitle] = useState<string | undefined>();

    const routeInfo = useRouteMatch<{ sub?: string }>("/agent/:sub?");
    const isAgentDesktopPage = !!routeInfo && routeInfo.params.sub !== "settings";
    const shouldHideSidenavWhenEmbedded = getEmbedUiConfigByKey("hideNavigation");
    const shouldHideLogoWhenEmbedded = getEmbedUiConfigByKey("hideLogo");
    return (
        <AntLayout className={styles.rootLayout}>
            <PageContext.Provider value={{ setShowSidebar, setPageTitle }}>
                <Helmet>
                    <title>{pageTitle ? `${pageTitle} | ` : ""}Regal.io</title>
                </Helmet>
                <AntLayout.Header className={styles.header}>
                    {!shouldHideLogoWhenEmbedded && (
                        <Link to="/">
                            <svg
                                width="144"
                                height="34"
                                viewBox="0 0 144 34"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_1832_161115)">
                                    <path
                                        d="M17.6913 33.3775L12.3594 22.4115H10.9548V33.3775H0V0.477295H14.5402C18.6593 0.477295 21.8104 1.19318 24.2337 2.8614C26.6571 4.52962 28.3528 7.39099 28.3528 10.9661C28.3528 17.6411 24.2337 20.0252 22.7784 20.8843L30.2911 33.3775H17.6891H17.6913ZM16.0926 9.20456C15.6097 8.82275 14.882 8.58412 13.6692 8.58412H10.9063V14.3047H13.6692C15.559 14.3047 17.3054 13.5888 17.3054 11.4433C17.3054 10.0137 16.5777 9.53646 16.0948 9.20239"
                                        fill="#8484FE"
                                    />
                                    <path
                                        d="M30.2911 33.3775V0.477295H54.0397V8.96376H41.1952V12.541H52.71V20.9797H41.1952V24.7934H54.5248V33.3754H30.2911V33.3775Z"
                                        fill="#8484FE"
                                    />
                                    <path
                                        d="M72.0066 33.9025C63.0408 33.9025 54.3153 28.6093 54.3153 16.9274C54.3153 5.24547 63.0386 0 72.2492 0C81.4598 0 85.3363 4.76821 86.3043 5.72272L79.0342 11.6841C77.2745 9.84664 74.8159 8.80969 72.2492 8.82271C68.3727 8.82271 65.3186 11.6841 65.3186 16.9295C65.3186 22.175 68.3727 25.0364 72.2492 25.0364C76.1257 25.0364 76.8534 23.8454 76.8534 23.8454V21.4613H71.7641V13.4933H87.5149V27.8934C84.849 30.2775 80.9724 33.9025 72.0066 33.9025"
                                        fill="#8484FE"
                                    />
                                    <path
                                        d="M109.83 33.3775L108.523 28.7525H98.9747L97.7156 33.3775H86.3242L97.9559 0.477295H110.073L121.705 33.3775H109.83V33.3775ZM103.771 11.6819L101.299 20.5025H106.243L103.771 11.6819V11.6819Z"
                                        fill="#8484FE"
                                    />
                                    <path
                                        d="M121.705 33.3775V0.477295H132.609V24.5548H143.998V33.3754H121.702L121.705 33.3775Z"
                                        fill="#8484FE"
                                    />
                                </g>
                                <defs>
                                    <clipPath id="clip0_1832_161115">
                                        <rect width="144" height="34" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </Link>
                    )}
                    {showSidebar && (
                        <>
                            <div className="flex-spacer"></div>
                            <div className={styles.navBarButtons}>{isAgentDesktopPage && <AgentNavBarButtons />}</div>
                            <AgentStatusDropdown />
                        </>
                    )}
                </AntLayout.Header>
                <AntLayout className={styles.body}>
                    {showSidebar && !shouldHideSidenavWhenEmbedded && <SideNav />}
                    <AntLayout.Content className={clsx(styles.content, { [styles.minimal]: !showSidebar })}>
                        {pageReady ? children : <Loading />}
                    </AntLayout.Content>
                </AntLayout>
            </PageContext.Provider>
        </AntLayout>
    );
}
