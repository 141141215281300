import { ReactNode, PropsWithChildren, ReactElement } from "react";

import { Form, FormItemProps, Typography } from "antd";
import { clsx } from "clsx";

import { FloatingLabelWrapper } from "./FloatingLabel";

import styles from "./EnhancedFormItem.module.scss";

export type EnhancedSelectProps = FormItemProps & {
    showFormLabel?: boolean;
    formItemHeader?: ReactNode;
    disabled?: boolean;
    containerClassName?: string;
    fixedFloatingLabel?: boolean;
    clearable?: boolean;
    placeholder?: string;
    infoItem?: ReactNode | ReactElement;
    infoText?: string;
    subInfoText?: string;
    dataTestId?: string;
};

export function EnhancedFormItem({
    name,
    showFormLabel,
    formItemHeader,
    label,
    fixedFloatingLabel,
    className,
    containerClassName,
    clearable,
    children,
    placeholder,
    infoText,
    infoItem,
    subInfoText,
    dataTestId,
    ...rest
}: PropsWithChildren<EnhancedSelectProps>): JSX.Element {
    const showRequiredLabel = rest.rules?.some(
        // @ts-expect-error type is wrong this is a BaseRule from antd
        (rule) => rule.required === true || typeof rule.validator === "function"
    );

    return (
        <div
            data-testid={dataTestId ?? `enhanced-input-${name}`}
            className={clsx("floating-design", containerClassName, { "floating-design-disabled": rest.disabled })}
        >
            {showFormLabel && (
                <div className="ant-form-item-label">
                    <label>
                        {formItemHeader ?? label} {showRequiredLabel && <sup>*</sup>}
                    </label>
                    {infoItem}
                </div>
            )}
            {infoText && <Typography.Text className="info-text">{infoText}</Typography.Text>}
            <FloatingLabelWrapper name={name} fixedFloatingLabel={fixedFloatingLabel} clearable={clearable}>
                <Form.Item
                    data-testid={`${name}-testid`}
                    label={placeholder || label}
                    name={name}
                    className={className}
                    {...rest}
                >
                    {children}
                </Form.Item>
            </FloatingLabelWrapper>
            {subInfoText && <div className={styles.formItemSubText}>{subInfoText}</div>}
        </div>
    );
}
