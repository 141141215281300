import { RVEventSchemaOption } from "Components/shared/RVEventSchemaOption/RVEventSchemaOption";

import styles from "./WebhookAttributesAutosuggest.module.scss";
export function WebhookAttributesAutosuggest({
    renderSuggestion,
    searchTerm,
    items,
}: {
    renderSuggestion?: any;
    searchTerm?: string;
    items: { label: string; value: string; dataType: string }[];
}) {
    if (!items.length) {
        return null;
    }

    return (
        <>
            <div className={styles.suggestionArea} key="autosuggest-area">
                {items.map((item) => {
                    return (
                        <div
                            key={`item-${item.value}`}
                            className={styles.suggestionItem}
                            onMouseDown={() => renderSuggestion(item.value)}
                        >
                            <RVEventSchemaOption
                                val={item.value}
                                valType={item.dataType}
                                highlightVal={searchTerm}
                                key={`option-${item.value}`}
                            />
                        </div>
                    );
                })}
            </div>
        </>
    );
}
