import { ReactNode } from "react";

import { Form, Collapse } from "antd";
import { clsx } from "clsx";

import Icon, { IconSizes } from "Components/elements/Icon";
import { Editable } from "Components/shared/Editable/Editable";
import { Row, Box } from "Components/shared/Flexbox";

import { RemoveButton } from "../Buttons";

import styles from "./CollapsePanel.module.scss";

export function CollapsePanel({
    name,
    hideConditionRemove,
    hideConditionHeader,
    activeKey,
    groupName,
    remove,
    onChange,
    children,
}: {
    name: number;
    hideConditionRemove?: boolean;
    hideConditionHeader?: boolean;
    activeKey?: Array<string | number> | string | number;
    groupName?: string;
    remove: (index: number | number[]) => void;
    onChange: (key: string | string[]) => void;
    children: ReactNode;
}): JSX.Element {
    return (
        <Collapse
            activeKey={activeKey}
            expandIconPosition="end"
            defaultActiveKey={name}
            className={clsx(styles.collapsePanel, {
                [styles.noHeader]: hideConditionHeader,
            })}
            onChange={onChange}
            expandIcon={({ isActive }) =>
                !hideConditionHeader ? (
                    <Icon
                        className={clsx(styles.icon)}
                        icon={isActive ? "arrow-down" : "arrow-right"}
                        size={IconSizes.XSMALL}
                    />
                ) : null
            }
        >
            <Collapse.Panel
                key={name}
                header={
                    !hideConditionHeader && (
                        <PanelHeader
                            hideConditionRemove={hideConditionRemove}
                            name={name}
                            groupName={groupName}
                            remove={remove}
                        />
                    )
                }
                forceRender={true}
            >
                {children}
            </Collapse.Panel>
        </Collapse>
    );
}

function PanelHeader({
    name,
    groupName = "Condition Group",
    hideConditionRemove,
    remove,
}: {
    name: number;
    hideConditionRemove?: boolean;
    groupName?: string;
    remove: (index: number) => void;
}) {
    const panelName = `${groupName} ${name + 1}`;
    return (
        <Row
            alignItems="center"
            justifyContent="space-between"
            onClick={(event) => {
                // prevent extra trigger collapse
                event.stopPropagation();
            }}
            className={styles.panelHeader}
        >
            <Box className={styles.editWrapper}>
                <Form.Item name={[name, "name"]} initialValue={panelName}>
                    <Editable inputClassName={styles.customInput} />
                </Form.Item>
            </Box>
            {!hideConditionRemove && <RemoveButton onClick={() => remove(name)} />}
        </Row>
    );
}
