import { useEffect, useState, useRef, useMemo } from "react";

import { NullOr } from "@regal-voice/shared-types";
import { Input, Popover } from "antd";
import { clsx } from "clsx";
import { debounce } from "lodash";
import InfiniteScroll from "react-infinite-scroll-component";

import { useApiWithAbort } from "Services/AbstractApiService";
import { autocompleteOptions } from "Services/Autocomplete/AutocompleteService";
import { alphabeticallySortByProperty } from "Services/HelpersService";

import { HighlightedString } from "../../../../elements/HighlightedString/HighlightedString";

import styles from "./InfiniteScrollDropdown.module.scss";

type InfiniteScrollDropdownProps = {
    disabled?: boolean;
    onChange?: (value?: string) => void;
    placeholder?: string;
    propertyFieldId: string;
    value?: string;
    className?: string;
    autofocus?: boolean;
    sufix?: JSX.Element;
    dataTestid?: string;
};

export function InfiniteScrollDropdown({
    disabled,
    onChange,
    placeholder,
    propertyFieldId,
    value,
    className,
    autofocus,
    sufix,
    dataTestid,
}: InfiniteScrollDropdownProps): JSX.Element {
    const [queryPayload, setQueryPayload] = useState<{
        searchTerm: string;
        lastEvaluatedKey: NullOr<{ id: string; info: string }>;
    }>({ searchTerm: "", lastEvaluatedKey: null });

    const [concatResults, setConcatResults] = useState<boolean>(true);
    const [lastEvaluatedKeyRef, setLastEvaluatedKeyRef] = useState<NullOr<{ id: string; info: string }>>(null);
    const [focused, setFocused] = useState<boolean>(false);
    const [events, setEvents] = useState<{ label: string; value: string }[]>([]);
    const inputRef = useRef<any>(null);

    const [dropDownOptions] = useApiWithAbort<typeof autocompleteOptions>(autocompleteOptions, {
        searchTerm: queryPayload.searchTerm,
        lastEvaluatedKey: queryPayload.lastEvaluatedKey,
        limit: 30,
        fieldId: propertyFieldId,
    });

    const debounceSearchTerm = useMemo(
        () =>
            debounce((value): void => {
                setLastEvaluatedKeyRef(null);
                setQueryPayload({
                    searchTerm: value,
                    lastEvaluatedKey: null,
                });
                setConcatResults(false);
            }, 350),
        []
    );

    useEffect(() => {
        if ((autofocus && value == "{") || value == "") {
            inputRef.current.focus({
                cursor: "end",
            });
        }
    }, [autofocus, value]);

    useEffect(() => {
        if (!dropDownOptions) {
            return;
        }
        const mappedEvents =
            dropDownOptions?.items?.map(({ info }: { info: string }) => ({
                value: info,
                label: info,
            })) || [];

        if (concatResults) {
            setEvents((prevValue) =>
                [...prevValue, ...(mappedEvents ?? [])].sort(alphabeticallySortByProperty("value"))
            );
        } else {
            setEvents(() => [...(mappedEvents ?? [])].sort(alphabeticallySortByProperty("value")));
        }
        setLastEvaluatedKeyRef(dropDownOptions?.lastEvaluatedKey || null);
    }, [dropDownOptions]);

    return (
        <Popover
            placement="bottomLeft"
            overlayClassName={styles.tooltipOverlay}
            content={
                <div id="scrollableDiv" className={clsx(styles.infiniteDropdown, className)}>
                    <InfiniteScroll
                        dataLength={events.length}
                        hasMore={events.length > 0 && !!lastEvaluatedKeyRef}
                        next={() => {
                            if (lastEvaluatedKeyRef != queryPayload.lastEvaluatedKey) {
                                setQueryPayload((prevValue) => ({
                                    ...prevValue,
                                    lastEvaluatedKey: lastEvaluatedKeyRef,
                                }));
                                setConcatResults(true);
                            }
                        }}
                        loader={null}
                        scrollableTarget="scrollableDiv"
                        scrollThreshold="0.5"
                    >
                        <>
                            {events.map((e) => (
                                <Popover
                                    key={e.value.length > 20 ? e.value.substring(1, 20) : e.value}
                                    content={
                                        <span className={styles.responsePopover}>
                                            <HighlightedString val={e.value} highlightVal={queryPayload.searchTerm} />
                                        </span>
                                    }
                                    placement="left"
                                    {...(e.value.length > 60 ? {} : { visible: false })}
                                >
                                    <div
                                        key={e.value}
                                        className={styles.dropdownItem}
                                        onClick={() => {
                                            debounceSearchTerm(e.value);
                                            onChange?.(e.value);
                                            setEvents([]);
                                        }}
                                    >
                                        <span style={{ whiteSpace: "pre" }}>
                                            <HighlightedString val={e.value} highlightVal={queryPayload.searchTerm} />
                                        </span>
                                    </div>
                                </Popover>
                            ))}
                        </>
                    </InfiniteScroll>
                </div>
            }
            style={{ maxWidth: "305px" }}
            open={focused && !!events?.length}
        >
            <Input
                placeholder={placeholder}
                disabled={!!disabled}
                value={value}
                onFocus={() => setFocused(true)}
                onBlur={() => setTimeout(() => setFocused(false), 150)}
                onChange={(e) => {
                    debounceSearchTerm(e.target.value);
                    onChange?.(e.target.value);
                }}
                ref={inputRef}
                suffix={sufix}
                data-testid={dataTestid ?? "infinite-input-scroll"}
            />
        </Popover>
    );
}
