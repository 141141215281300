import { createSlice, createSelector, PayloadAction } from "@reduxjs/toolkit";
import { filter, remove } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { UserCountsByJourney } from "Services/Journeys.service";
import {
    Elements,
    FlowElement,
    Node,
    Edge,
    Journey,
    JourneyStatus,
    NodeMetricsData,
    NodeType,
    XYPosition,
    Tag,
} from "Types/Journey";
import { buildDAG, edge as dagAddEdge, removeEdges as dagRemoveEdges, DAGState } from "Vendor/dager/ElementDAG";

import type { NullOr } from "@regal-voice/shared-types";
import type { RootReducerShape } from "Services/state/Storage";

export interface FieldData {
    name: string | number | (string | number)[];
    value?: any;
    touched?: boolean;
    validating?: boolean;
    errors?: string[];
}

type JourneyMetadataColumn = {
    usedNodeCount?: number;
};

export type JourneyState = {
    id: string;
    triggerNodeId: string;
    friendlyId: string;
    name: string;
    description?: string;
    elements: Elements;
    nodes: Node[];
    edges: Edge[];
    state: JourneyStatus;
    editedNode: NullOr<Node>;
    editedStickyNoteNodeId?: string;
    selection: NullOr<Elements>;
    copyOffset: XYPosition;
    elementFields: FieldData[];
    connectingNodesFrom: string | null;
    version: number;
    canvasHeight?: number;
    canvasAnchorPoint?: XYPosition;
    lastNodeFriendlyId: number;
    metadata: JourneyMetadataColumn;
    createdAt: string;
    updatedAt: string;
    originalCreatedAt: string;
    journeyStats: UserCountsByJourney | null;
    elementDAG: DAGState;
    expandedStickyNoteNodes: { [key: string]: boolean };
    editingJourneyDetails?: boolean;
    tags?: Tag[];
};

type NodeId = string;
export type OptionalElements = Node<any> | Edge<any> | undefined;

export const isEdge = (element: Node | Edge): element is Edge =>
    "id" in element && "source" in element && "target" in element;

export const isNode = (element: Node | Edge): element is Node =>
    "id" in element && !("source" in element) && !("target" in element);

function initData(type: string) {
    switch (type) {
        case "trigger":
            return { triggerType: null, eventName: "", friendlyId: 1 };
        case "delay":
            return { days: null, hours: null, minutes: null, seconds: null };
        case "call":
            return { campaign: {} };
    }
}

function createNode({ type, position }: { type: string; position: XYPosition }): Node {
    const node: Node = {
        id: uuidv4(),
        type,
        position,
        data: initData(type),
    };
    return node;
}

const initialAnchorPoint = { x: 0, y: 0 };
const initialElements: Elements = [createNode({ position: initialAnchorPoint, type: "trigger" })];
const initialCopyOffset = { x: 30, y: 80 };

const initialState: JourneyState = {
    id: "new",
    friendlyId: "",
    triggerNodeId: initialElements[0].id,
    name: "New Journey",
    elements: initialElements,
    nodes: initialElements as Node[],
    edges: [],
    state: JourneyStatus.DRAFT,
    editedNode: null,
    selection: null,
    copyOffset: initialCopyOffset,
    elementFields: [],
    connectingNodesFrom: null,
    version: 0,
    lastNodeFriendlyId: 1,
    metadata: {},
    createdAt: "",
    updatedAt: "",
    originalCreatedAt: "",
    journeyStats: null,
    elementDAG: {
        forward: {},
        backward: {},
        nodes: {},
    },
    expandedStickyNoteNodes: {},
    editingJourneyDetails: false,
    tags: [],
};

function getTriggerNode(elements: Elements): Node {
    // Journey will always have a trigger node, safe to assume this will return
    // a value
    return elements.find((x: any) => x.type == "trigger") as Node;
}

function resetEditedNode(state: JourneyState) {
    state.editedNode = null;
    state.elementFields = [];
}

function removeElementsById(state: JourneyState, elementsIds: Set<string>) {
    const removedElements = remove(
        state.elements,
        (el) =>
            elementsIds.has(el.id) ||
            // remove edges that are connected to nodes that are being removed
            // we cannot have edges that are connected to nodes that do not exist
            (isEdge(el) && (elementsIds.has(el.source) || elementsIds.has(el.target)))
    );
    remove(state.nodes, (el) => elementsIds.has(el.id));
    // remove edges by id and edges that are connected to nodes that are being removed
    remove(state.edges, (el) => elementsIds.has(el.id) || elementsIds.has(el.source) || elementsIds.has(el.target));

    const removedEdges: Edge[] = removedElements.filter((element) => isEdge(element)) as Edge[];

    state.elementDAG = dagRemoveEdges(state.elementDAG, removedEdges);

    if (state.editedNode != null && elementsIds.has(state.editedNode?.id)) {
        // reset edited node if it's deleted
        resetEditedNode(state);
    }

    if (state.selection) {
        state.selection = state.selection.filter((el) => !elementsIds.has(el.id));
        if (state.selection.length == 0) {
            state.selection = null;
        }
    }
}

function updateNodeData(id: string, data: Record<string, any>, elements: Elements) {
    const index = elements.findIndex((item) => item.id === id);
    if (index != -1) {
        // We do NOT want to overwrite analytics data in this, updateNodeData should specifically
        // relate to form field value cahnges. Only setNodeAnalytics should alter the analytics data
        const { analyticsData, friendlyId } = elements[index].data;
        const newData = { ...data, analyticsData, friendlyId };
        const newElement = { ...elements[index], data: newData };

        elements.splice(index, 1, newElement);
    }
}

function updateNodePosition(id: string, position: { x: number; y: number }, elements: Elements) {
    const index = elements.findIndex((item) => item.id === id);
    if (index != -1) {
        const newElement = { ...elements[index], position };
        elements.splice(index, 1, newElement);
    }
}

function filterEdges(state: any, newEdge: Edge) {
    return state.elements.filter((el: Edge) => {
        if (isNode(el)) {
            return true;
        }

        const source = newEdge.sourceHandle ? "sourceHandle" : "source";
        return el[source] !== newEdge[source];
    });
}

function getConnectedElementChildren(
    nodeId: string,
    connectedElements: Map<string, OptionalElements>,
    edgeSourceMap: Map<NodeId, Array<Edge>>,
    elementMap: Map<string, OptionalElements>
) {
    if (edgeSourceMap.has(nodeId)) {
        // If the node provided to this function exists in the previously generated edgeSourceMap,
        // it will indicate the node has an edge (or edges) connecting it to  a node to a child (or children)
        edgeSourceMap.get(nodeId)?.forEach((edge) => {
            // add both edge and node to connected elements set
            connectedElements.set(edge.id, elementMap.get(edge.id));
            connectedElements.set(edge.target, elementMap.get(edge.target));
            // do the same for all children
            getConnectedElementChildren(edge.target, connectedElements, edgeSourceMap, elementMap);
        });
    }
    return connectedElements;
}

function getAllConnectedElements(elements: Elements): Array<OptionalElements> {
    // Return all elements which are connected to the trigger node

    let trigger: Node | undefined;
    // Map of edge by source id, <sourceId: Edge>
    const edgeSourceMap = new Map<NodeId, Array<Edge>>();
    // All elements, in a map
    const elementMap = new Map<string, OptionalElements>();
    const connectedElements = new Map();

    elements?.forEach((x: any) => {
        if (x.type == "trigger") {
            trigger = x;
        }
        if (x.type === "buttonedge") {
            edgeSourceMap.set(x.source, [...(edgeSourceMap.get(x.source) || []), x]);
        }
        elementMap.set(x.id, x);
    });

    if (!trigger) {
        // Don't think there can not be a trigger
        return [];
    }

    // Starting with trigger node, iterate through connections
    connectedElements.set(trigger?.id, trigger);
    const connectedElementMap = getConnectedElementChildren(trigger.id, connectedElements, edgeSourceMap, elementMap);
    return Array.from(connectedElementMap.values());
}

function mergeNodeAnalytics(elements: Elements, analytics: Record<string, NodeMetricsData>) {
    return elements.map((element) => {
        return {
            ...element,
            data: {
                ...element.data,
                analyticsData: analytics[element.id],
            },
        };
    });
}

function addNodeToJourney(state: JourneyState, node: Node) {
    if (state.lastNodeFriendlyId !== undefined) {
        if (node.data !== undefined) {
            state.lastNodeFriendlyId += 1;
            node.data.friendlyId = state.lastNodeFriendlyId;
        }
    }

    state.elements = state.elements.concat(node);
    state.nodes = state.nodes.concat(node as Node);
}

function getUsedNodeCount(metadata: any) {
    let returnValue = 0;
    if (metadata && metadata.usedNodeCount) {
        returnValue = metadata.usedNodeCount;
    }
    return returnValue;
}

function initLastNodeFriendlyId(elements: Elements<any>, metadata: any) {
    const friendlyIds = elements
        .filter((el) => isNode(el))
        .map((el) => el.data)
        .filter((data) => data !== null && data !== undefined)
        .map((data) => data.friendlyId)
        .filter((friendlyId) => friendlyId !== undefined);

    const lastUsedFromFlowMetadata = getUsedNodeCount(metadata);
    if (friendlyIds.length == 0) {
        return lastUsedFromFlowMetadata;
    } else {
        return friendlyIds.reduce((prevValue, curValue) => Math.max(prevValue, curValue), lastUsedFromFlowMetadata);
    }
}

function enrichFlowElements(elements: Elements<any>, journey: Journey): Elements<any> {
    const { nodeAnalytics, stickyNotes } = journey;

    // When available, inject node analyitcs into the node 'data' object
    if (!!nodeAnalytics) {
        elements = mergeNodeAnalytics(elements, nodeAnalytics);
    }

    // If this journey has sticky notes (which are stored outside of the flow),
    // Include them in the elements we want to render
    if (!!stickyNotes) {
        elements = elements.concat(
            stickyNotes.map((stickyNote) => {
                return {
                    id: stickyNote.nodeId,
                    data: stickyNote.data,
                    position: stickyNote.position,
                    type: NodeType.StickyNote,
                };
            })
        );
    }

    return elements;
}

const JourneyStateSlice = createSlice({
    name: "JourneyState",
    initialState,
    reducers: {
        setJourney(state: JourneyState, action: PayloadAction<Journey>) {
            const {
                id,
                friendlyId,
                flow,
                name,
                description,
                state: status,
                version,
                originalCreatedAt,
                createdAt,
                updatedAt,
                createdBy,
                updatedBy,
                metadata,
                tags,
            } = action.payload;
            const elementFields: FieldData[] = [];
            const elements = enrichFlowElements(flow, action.payload);

            const triggerNode = getTriggerNode(flow);

            let elementDAG = {
                forward: {},
                backward: {},
                nodes: {},
            };
            try {
                // building DAG can throw if flow has cycle
                elementDAG = buildDAG(flow);
            } catch (DAGCyclicError) {}

            return {
                ...state,
                id,
                triggerNodeId: triggerNode?.id,
                friendlyId,
                elements,
                nodes: elements.filter((el) => isNode(el)) as Node[],
                edges: elements.filter((el) => isEdge(el)) as Edge[],
                elementDAG,
                name,
                description,
                state: status,
                editedNode: null,
                selection: state.selection || null,
                copyOffset: initialCopyOffset,
                elementFields,
                version,
                metadata,
                originalCreatedAt,
                createdAt,
                updatedAt,
                createdBy,
                updatedBy,
                lastNodeFriendlyId: initLastNodeFriendlyId(elements, metadata),
                editingJourneyDetails: false,
                tags,
            };
        },
        /**
         * once the journey is fetched for the first time, when refetching
         * there should be the case to set everything just data that could change on server
         */
        updateJourney(state: JourneyState, action: PayloadAction<Journey>) {
            const { state: status, version, updatedAt, metadata, flow } = action.payload;
            const elements = enrichFlowElements(flow, action.payload);

            return {
                ...state,
                state: status,
                version,
                updatedAt,
                metadata,
                elements,
                nodes: elements.filter((el) => isNode(el)) as Node[],
                edges: elements.filter((el) => isEdge(el)) as Edge[],
                editingJourneyDetails: state.editingJourneyDetails,
            };
        },
        reset() {
            return {
                ...initialState,
            };
        },
        setName(state: JourneyState, action: PayloadAction<string>) {
            state.name = action.payload;
        },
        setDetails(state: JourneyState, action: PayloadAction<{ name: string; description?: string; tags?: Tag[] }>) {
            const { name, description, tags } = action.payload;
            state.name = name;
            state.description = description;
            state.tags = tags;
        },
        addNode(state: JourneyState, action: PayloadAction<Node>) {
            addNodeToJourney(state, action.payload);
        },
        addEdge(state: JourneyState, action: PayloadAction<Edge>) {
            const { id, source, target } = action.payload;
            dagAddEdge(state.elementDAG, source, target, id);
            state.elements = [...filterEdges(state, action.payload), action.payload];
            const newEdges = filterEdges(state, action.payload).filter(isEdge);
            state.edges = [...newEdges, action.payload];
        },
        setNodes(state: JourneyState, action: PayloadAction<Node[]>) {
            state.nodes = action.payload;
            state.elements = state.elements.filter((el) => !isNode(el)).concat(action.payload);
        },
        setEdges(state: JourneyState, action: PayloadAction<Edge[]>) {
            state.edges = action.payload;
            state.elements = state.elements.filter((el) => !isEdge(el)).concat(action.payload);
        },
        removeElementById(state: JourneyState, action: PayloadAction<string>) {
            const elementId = action.payload;
            removeElementsById(state, new Set([elementId]));
        },
        removeElements(state: JourneyState, action: PayloadAction<Elements>) {
            const elementsIds: string[] = action.payload
                .filter((el: FlowElement) => el.type !== NodeType.Trigger)
                .map((el: FlowElement) => el.id);
            removeElementsById(state, new Set(elementsIds));
        },
        editNodeProperties(state: JourneyState, action: PayloadAction<Node>) {
            // edit node only if exists in journey.
            // this is to avoid editing a node that has been deleted
            const isInFlow = state.nodes.some((node) => node.id === action.payload.id);
            if (isInFlow) {
                // if editing journey details, selecting a node should close the journey editor
                state.editingJourneyDetails = false;
                state.editedNode = action.payload;
                state.elementFields = Object.entries(state.editedNode.data).map(([name, value]) => ({
                    name,
                    value,
                }));
            }
        },
        clearEditedNode(state: JourneyState) {
            resetEditedNode(state);
        },
        editStickyNote(state: JourneyState, action: PayloadAction<string>) {
            state.editedStickyNoteNodeId = action.payload;
            state.expandedStickyNoteNodes = { [action.payload]: true };
        },
        updateStickyNote(
            state: JourneyState,
            action: PayloadAction<{ id: string; text: string; author: string; date: any }[]>
        ) {
            if (state.editedStickyNoteNodeId) {
                const node = state.nodes.find((item) => item.id === state.editedStickyNoteNodeId);
                if (node) {
                    node.data.notes = action.payload;
                }
                const element = state.elements.find((item) => item.id === state.editedStickyNoteNodeId);
                if (element) {
                    element.data.notes = action.payload;
                }
            }
        },
        expandStickyNotes(state: JourneyState, action: PayloadAction<boolean>) {
            if (!action.payload) {
                state.expandedStickyNoteNodes = {};
            } else {
                const notes = state.nodes.filter((el) => el.type == NodeType.StickyNote);
                state.expandedStickyNoteNodes = Object.fromEntries(notes.map((el) => [el.id, true]));
            }
        },
        collapseStickyNote(state: JourneyState, action: PayloadAction<string>) {
            delete state.expandedStickyNoteNodes[action.payload];
        },
        selectElements(state: JourneyState, action: PayloadAction<NullOr<Elements>>) {
            let selection = action.payload;
            if (action.payload != null) {
                const newSelection = action.payload;
                const currentSelection = state.selection;

                let edgesToAdd: Elements = [];
                // automatically add edge when a new node was added to selection
                // and an edge between added node and curent selection exist
                // this works only for multiselect with Shift+Click
                // when highlighting an area of nodes, currentSelection is set to null by react-flow
                if (newSelection && currentSelection && newSelection.length == currentSelection.length + 1) {
                    const currentSelectionIds = currentSelection.map(({ id }) => id);
                    const elementsAdded = newSelection.filter((el) => !currentSelectionIds.includes(el.id));
                    if (elementsAdded.length == 1 && isNode(elementsAdded[0])) {
                        // only if we added one node to selection
                        const addedNodeId = elementsAdded[0].id;
                        const selectedNodesIds = action.payload.filter(isNode).map(({ id }) => id);

                        edgesToAdd = state.elements.filter(
                            (el) =>
                                isEdge(el) && // is edge
                                !currentSelectionIds.includes(el.id) && // is not already selected
                                ((el.source == addedNodeId && selectedNodesIds.includes(el.target)) || // edge has added node as source and target in current selection
                                    (el.target == addedNodeId && selectedNodesIds.includes(el.source))) // edge has added node as target and source in current selection
                        );
                    }
                }
                selection = [...action.payload, ...edgesToAdd].map((el) => ({ ...el, selected: true }));
                state.selection = selection;
            } else {
                state.selection = action.payload;
            }
            if (selection != null) {
                state.elements = state.elements.map((el) => {
                    if (selection?.find(({ id }) => id == el.id)) {
                        return { ...el, selected: true };
                    } else {
                        return el;
                    }
                });
            }
            state.copyOffset = initialCopyOffset;
        },
        copySelection(state: JourneyState) {
            const { selection } = state;
            if (selection) {
                // trigger node is not duplicated
                const nodes: Array<Node> = selection.filter(
                    (el) => isNode(el) && el.type !== NodeType.Trigger
                ) as Array<Node>;
                const { copyOffset } = state;
                // map original node id to cloned node id
                const idsMap: Record<string, string> = {};
                // duplicate selected nodes and
                // save mapping from original node id to new node id needed for duplicationg edges
                const duplicatedNodes = nodes.map((node) => {
                    const {
                        id,
                        data,
                        type,
                        position: { x, y },
                    } = node;
                    const newId = uuidv4();
                    const newNode: Node = {
                        id: newId,
                        type,
                        position: { x: x + copyOffset.x, y: y + copyOffset.y },
                        // need to be careful to make a copy of data
                        data: { ...data },
                    };
                    idsMap[id] = newId;
                    // save node and [original id, newId]
                    return newNode;
                });
                // add nodes to journey state
                duplicatedNodes.forEach((node) => addNodeToJourney(state, node));

                // remove edges without valid source/target - this happens when:
                // selection includes edge witout its connected nodes
                const selectedEdges = selection.filter(
                    (el) => isEdge(el) && idsMap[el.source] != null && idsMap[el.target] != null
                ) as Array<Edge>;
                const duplicatedEdges = selectedEdges.map((edge) => {
                    let sourceHandle = undefined;
                    let targetHandle = undefined;
                    if (edge.sourceHandle?.startsWith(edge.source)) {
                        sourceHandle = edge.sourceHandle.replace(edge.source, idsMap[edge.source]);
                    }
                    if (edge.targetHandle?.startsWith(edge.target)) {
                        targetHandle = edge.targetHandle.replace(edge.source, idsMap[edge.source]);
                    }
                    const newEdge = {
                        ...edge,
                        id: uuidv4(),
                        source: idsMap[edge.source],
                        target: idsMap[edge.target],
                        sourceHandle,
                        targetHandle,
                    };
                    return newEdge;
                });
                // update DAG
                duplicatedEdges.forEach((edge) => dagAddEdge(state.elementDAG, edge.source, edge.target, edge.id));

                // add edges to flow elements
                state.elements = [...state.elements, ...duplicatedEdges];
                // add edges to edges array used by reactflow 11
                state.edges = [...state.edges, ...duplicatedEdges];

                // update selection
                state.selection = [...duplicatedNodes, ...duplicatedEdges];

                // update selected state of flow elements
                // duplicated elements becomes selected because most of the time the user will move them
                state.elements = state.elements.map((el) => {
                    const selected = state.selection?.find(({ id }) => id == el.id) != null;
                    return { ...el, selected };
                });
                state.nodes = state.nodes.map((el) => {
                    const selected = state.selection?.find(({ id }) => id == el.id) != null;
                    return { ...el, selected };
                });
                state.edges = state.edges.map((el) => {
                    const selected = state.selection?.find(({ id }) => id == el.id) != null;
                    return { ...el, selected };
                });

                if (duplicatedNodes.length == 1) {
                    state.editedNode = duplicatedNodes[0];
                } else {
                    state.editedNode = null;
                }
            }
        },
        removeSelection(state: JourneyState) {
            if (state.selection) {
                const elementsId: string[] = state.selection
                    .filter((el: FlowElement) => el.type !== NodeType.Trigger)
                    .map((el: FlowElement) => el.id);
                removeElementsById(state, new Set(elementsId));
                state.selection = null;
                state.copyOffset = initialCopyOffset;
            }
        },
        setFields(state: JourneyState, action: PayloadAction<FieldData[]>) {
            state.elementFields = action.payload;
        },
        updateNode(state: JourneyState, action: PayloadAction<{ id: string; data: Record<string, any> }>) {
            const { id, data } = action.payload;
            const { elements, nodes } = state;
            updateNodeData(id, data, elements);
            updateNodeData(id, data, nodes);
            if (state.selection) {
                updateNodeData(id, data, state.selection);
            }

            if (data && typeof data === "object") {
                state.elementFields = Object.entries(data).map(([key, value]) => ({ name: key, value }));
            }
        },
        updateNodePos(state: JourneyState, action: PayloadAction<{ id: string; position: { x: number; y: number } }>) {
            const { id, position } = action.payload;
            const nodes: Array<Node> = (state.selection?.filter((el: FlowElement) => isNode(el)) as Array<Node>) || [];
            const {
                position: { x: initialX, y: initialY },
            } = nodes.find((n) => n.id == id) as Node;

            const deltaX = initialX - position.x;
            const deltaY = initialY - position.y;

            nodes.forEach((node) => {
                const {
                    id: nodeId,
                    position: { x, y },
                } = node;
                const updatedPosition = {
                    x: x - deltaX,
                    y: y - deltaY,
                };
                const { elements } = state;
                updateNodePosition(nodeId, updatedPosition, elements);
                if (state.selection) {
                    const { selection } = state;
                    updateNodePosition(nodeId, updatedPosition, selection);
                    state.copyOffset = initialCopyOffset;
                }
            });
        },
        updateSelectionPos(state: JourneyState, action: PayloadAction<Node[]>) {
            const selectedNodes = action.payload;
            const { elements } = state;
            if (selectedNodes && Array.isArray(selectedNodes)) {
                selectedNodes.forEach((node) => {
                    if (state.selection) {
                        const selected = state.selection?.find((it) => it.id == node.id);
                        if (selected) {
                            updateNodePosition(node.id, node.position, elements);
                        }
                    }
                });
                state.copyOffset = initialCopyOffset;
            }
        },
        updateMultipleNodePos(state: JourneyState, action: PayloadAction<Node[]>) {
            // Same as updeateSelectionPos but does not require nodes to be selected
            const updatedNodes = action.payload;
            const { elements, nodes } = state;
            if (!!updatedNodes) {
                updatedNodes.forEach((node) => {
                    updateNodePosition(node.id, node.position, elements);
                    updateNodePosition(node.id, node.position, nodes);
                });
            }
        },
        connectNodes(state: JourneyState, action: PayloadAction<string | null>) {
            state.connectingNodesFrom = action.payload;
        },
        updateCanvasHeight(state: JourneyState, action: PayloadAction<number>) {
            // This and the corresponding selector are an annoying work around to a frustrating react-flow
            // state management issue, where canvas height is not stored very well
            state.canvasHeight = action.payload;
        },
        updateCanvasAnchorPoint(state: JourneyState, action: PayloadAction<XYPosition>) {
            state.canvasAnchorPoint = action.payload;
        },
        resetCanvasAnchorPoint(state: JourneyState) {
            state.canvasAnchorPoint = undefined;
        },
        setNodeAnalytics(state: JourneyState, action: PayloadAction<Record<string, NodeMetricsData>>) {
            const { payload: analytics } = action;
            if (!!analytics) {
                state.elements = mergeNodeAnalytics(state.elements, analytics);
                state.nodes = state.elements.filter((el) => isNode(el)) as Node[];
            }
        },
        setJourneyUsersStats(state: JourneyState, action: PayloadAction<UserCountsByJourney | null>) {
            const { payload: stats } = action;
            state.journeyStats = stats;
        },
        setElementDAG(state, action: PayloadAction<any>) {
            state.elementDAG = action.payload;
        },
        setEditingJourneyDetails(state, action: PayloadAction<boolean>) {
            state.editingJourneyDetails = action.payload;
        },
        addJourneyTag(state, action: PayloadAction<Tag>) {
            const { id, name } = action.payload;
            state.tags?.push({ id, name });
        },
        removeJourneyTag(state, action: PayloadAction<string>) {
            const tagId = action.payload;
            state.tags = filter(state.tags, (tag) => tag.id != tagId);
        },
    },
});

function filterEmptyNotes(element: Node | Edge) {
    if (element.type === NodeType.StickyNote) {
        const { notes } = element.data;
        return notes && notes.length > 0;
    } else {
        return true;
    }
}

const selectJourney = (state: RootReducerShape) => state.journey.journey;

export const selectJourneyId = createSelector(selectJourney, (journey) => journey.id);
export const selectFlow = createSelector(selectJourney, (journey) => journey.elements);
// remove selected property from elements to avoid loading journey with selected elements
// and remove empty sticky notes
export const selectFlowForSave = createSelector(selectJourney, (journey) =>
    journey.elements.map((el) => ({ ...el, selected: false })).filter(filterEmptyNotes)
);
export const selectNodes = createSelector(selectJourney, (journey) => journey.nodes);
export const selectEdges = createSelector(selectJourney, (journey) => journey.edges);
export const selectConnectedElements = createSelector(selectJourney, (journey) => {
    return getAllConnectedElements(journey.elements);
});
export const selectName = createSelector(selectJourney, (journey) => journey.name);
export const selectDetails = createSelector(selectJourney, (journey) => {
    const { name, description, tags } = journey;
    return { name, description, tags };
});
export const selectState = createSelector(selectJourney, (journey) => journey.state);
export const selectFriendlyId = createSelector(selectJourney, (journey) => journey.friendlyId);
export const selectVersion = createSelector(selectJourney, (journey) => journey.version || 0);
export const selectEditedNode = createSelector(selectJourney, (journey) => journey.editedNode);
export const selectEditingJourneyDetails = createSelector(selectJourney, (journey) => journey.editingJourneyDetails);
export const selectSelection = createSelector(selectJourney, (journey) => journey.selection);
export const selectFormFields = createSelector(selectJourney, (journey) => journey.elementFields);
export const selectJourneyMetaData = createSelector(selectJourney, (journey) => {
    const { createdAt, updatedAt, version, originalCreatedAt, id, friendlyId, name, state: journeyState } = journey;
    return { createdAt, updatedAt, version, originalCreatedAt, id, friendlyId, name, state: journeyState };
});
export const selectTriggerNode = createSelector(selectJourney, (journey) =>
    journey.elements?.find((x: any) => x.type == "trigger")
);
export const selectTriggerNodeId = createSelector(selectJourney, (journey) => journey.triggerNodeId);
export const selectConnectingNodesFrom = createSelector(selectJourney, (journey) => journey.connectingNodesFrom);
export const selectCanvasHeight = createSelector(selectJourney, (journey) => journey.canvasHeight);
export const selectCanvasAnchorPoint = createSelector(selectJourney, (journey) => journey.canvasAnchorPoint);
export const selectElementDAG = createSelector(selectJourney, (journey) => journey.elementDAG);

export const selectMetadata = createSelector(selectJourney, (journey) => journey.metadata);
export const selectJourneyStats = createSelector(selectJourney, (journey) => journey.journeyStats);
export const selectLastNodeFriendlyId = createSelector(selectJourney, (journey) => journey.lastNodeFriendlyId);
export const selectStickyNodes = createSelector(selectJourney, (journey) => {
    return journey.nodes.filter((el) => el.type == NodeType.StickyNote);
});
export const selectExpandedStickyNoteNodes = createSelector(selectJourney, (journey) => {
    return journey.expandedStickyNoteNodes;
});

export const {
    setJourney,
    updateJourney,
    setName,
    setDetails,
    addNode,
    addEdge,
    setNodes,
    setEdges,
    removeElements,
    editNodeProperties,
    clearEditedNode,
    editStickyNote,
    updateStickyNote,
    expandStickyNotes,
    collapseStickyNote,
    selectElements,
    copySelection,
    removeSelection,
    setFields,
    updateNode,
    updateNodePos,
    updateSelectionPos,
    removeElementById,
    reset,
    connectNodes,
    updateMultipleNodePos,
    updateCanvasHeight,
    updateCanvasAnchorPoint,
    resetCanvasAnchorPoint,
    setNodeAnalytics,
    setJourneyUsersStats,
    setElementDAG,
    setEditingJourneyDetails,
    addJourneyTag,
    removeJourneyTag,
} = JourneyStateSlice.actions;

export const journeyReducer = JourneyStateSlice.reducer;
