export enum FactorType {
    EMAIL = "email",
    CALL = "call",
    SMS = "sms",
}

export interface ResetPasswordPayload {
    recoveryToken: string;
    newPassword: string;
}

export type User = {
    email: string;
    family_name: string;
    given_name: string;
    name: string;
    sub: string;
    brand?: string;
    roles?: UserRole[];
};

export type AuthState = {
    isAuthenticated: boolean;
    user: User;
    brand: string;
};

export enum UserRole {
    ADMIN = "admin",
    SUPERVISOR = "supervisor",
    AGENT = "agent",
}

export type RegalAuthProfile = {
    oktaUserId: string;
    email: string;
    firstName: string;
    lastName: string;
    name: string;
    brandSlug: string;
    roles: string[];
};
