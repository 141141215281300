import { ComponentProps } from "react";

import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { clsx } from "clsx";

import Icon, { IconSizes } from "Components/elements/Icon";
import { RVButton } from "Components/elements/RVButton/RVButton";

import styles from "./Buttons.module.scss";

type ButtonProps = ComponentProps<typeof RVButton> & {
    label: string;
};

export function AddButton({ label, hidden, ...rest }: ButtonProps): JSX.Element {
    const hiddenStyle = hidden ? { display: "none" } : {};
    return (
        <div className="flex-row align-center" style={hiddenStyle}>
            <RVButton icon={<PlusCircleOutlined />} size="middle" {...rest}>
                {label}
            </RVButton>
        </div>
    );
}

export function RemoveButton({ onClick }: { onClick: () => void }): JSX.Element {
    return <MinusCircleOutlined className={styles.removeIcon} onClick={onClick} />;
}

export function CollapseButton({ onClick, expanded }: { expanded?: boolean; onClick?: () => void }): JSX.Element {
    return (
        <Icon
            className={clsx(styles.icon, { [styles.expanded]: expanded })}
            icon={"arrow-right"}
            size={IconSizes.XSMALL}
            onClick={onClick}
        />
    );
}
