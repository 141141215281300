export const AppPagePaths = {
    fallback: "/",
    agentDesktop: "/agent",
    agents: "/agents",
    campaigns: "/campaigns",
    dashboard: "/reporting",
    audience: "/audience",
    journeys: "/journeys",
    recentActivity: "/recent-activity",
    queues: "/queues",
    tasks: "/tasks",
    phoneNumbers: "/phone-numbers",
    recentTasks: "/recent-tasks",
    segment: "/segments",
    regalLive: "/regal-live",
    aiAgents: "/ai-agents",
    transcripts: "/transcripts",
    settings: "/settings",
    admin: "/admin",
};
