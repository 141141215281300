import { gql } from "@apollo/client";

export const typeDefs = gql`
    input QueryParams {
        orderBy: [SortParams]
    }
    input SortParams {
        key: String
        value: string
    }
`;
