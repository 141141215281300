import { SetStateAction, useEffect, useRef, useState, Dispatch } from "react";

import { FormOutlined } from "@ant-design/icons";
import { Input } from "antd";
import { clsx } from "clsx";

import { LoadingTitle } from "Components/elements/Loading/LoadingTitle";

import styles from "./Editable.module.scss";

const ESCAPE_KEY = 27;

export function Editable({
    value,
    onChange,
    defaultValue = "",
    startWithEditView = false,
    loading = false,
    inputClassName,
    previewAs,
}: {
    value?: string;
    onChange?: (value: string) => void;
    defaultValue?: string;
    startWithEditView?: boolean;
    loading?: boolean;
    inputClassName?: string;
    previewAs?: React.ElementType<any>;
}): JSX.Element {
    const [isEditing, setEditing] = useState<boolean>(startWithEditView);
    const [innerValue, setInnerValue] = useState<string>(defaultValue);
    const [iconHovered, setIconHovered] = useState<boolean>(false);

    function handleSubmit(value: string) {
        onChange?.(value);
    }

    useEffect(() => {
        setInnerValue(value || "");
    }, [value]);

    // the edit should not persist when user clicks out of the box
    // the problem is that clicking on save icon is seen as blur event by div wrapper
    function onBlur() {
        if (!iconHovered) {
            setEditing(false);
            setInnerValue(value || "");
        }
    }

    return loading ? (
        <LoadingTitle />
    ) : (
        <div className={styles.editable} onBlur={onBlur}>
            {isEditing ? (
                <EditableInput
                    value={innerValue}
                    initialValue={value || ""}
                    onChange={setInnerValue}
                    setValue={handleSubmit}
                    setEditing={setEditing}
                    setIconHovered={setIconHovered}
                    inputClassName={inputClassName}
                />
            ) : (
                <EditablePreview value={innerValue} setEditing={setEditing} as={previewAs} />
            )}
        </div>
    );
}

function EditablePreview({
    value,
    setEditing,
    as,
}: {
    value: string;
    setEditing: Dispatch<SetStateAction<boolean>>;
    as?: React.ElementType<any>;
}): JSX.Element {
    const Component = as || "span";
    return (
        <div className={clsx("flex-row align-center", styles.previewWrapper)} onClick={() => setEditing(true)}>
            <Component>{value}</Component>
            <FormOutlined className={styles.previewIcon} />
        </div>
    );
}

function EditableInput({
    value,
    initialValue,
    setEditing,
    onChange,
    setValue,
    setIconHovered,
    inputClassName,
}: {
    value: string;
    initialValue: string;
    setEditing: Dispatch<SetStateAction<boolean>>;
    onChange: (newValue: string) => void;
    setValue: (newValue: string) => void;
    setIconHovered: (hovered: boolean) => void;
    inputClassName?: string;
}): JSX.Element {
    const inputRef = useRef<any>(null);

    useEffect(() => {
        inputRef.current.focus();
    }, []);

    function updateValue() {
        setValue(value);
        setEditing(false);
        setIconHovered(false);
    }

    function handleOnKeyPress(e: React.KeyboardEvent) {
        if (e.keyCode == ESCAPE_KEY) {
            onChange(initialValue);
            setEditing(false);
            setIconHovered(false);
        }
    }

    return (
        <div className={clsx(styles.editWrapper)}>
            <Input
                ref={inputRef}
                value={value}
                className={clsx(styles.editableInput, inputClassName)}
                bordered={true}
                maxLength={50}
                onChange={(e) => onChange(e.target.value)}
                onPressEnter={updateValue}
                onBlur={updateValue}
                onKeyDown={handleOnKeyPress}
            />
        </div>
    );
}
