import { JoinType, EventProperty, DynamicCondition, ConditionEvent, ConditionList } from "Types/Segment";

export const joinTypeDefault: JoinType = JoinType.ANY;

export const propertyDefaults: EventProperty = { propertyOperator: "isSet" };

export const eventDefaults: ConditionEvent = {
    eventOperator: "isSet",
    filters: [propertyDefaults],
    lastEventOnly: false,
};

export const conditionDefaults: DynamicCondition = {
    joinType: joinTypeDefault,
    conditions: [eventDefaults],
};

export const initialCondition: DynamicCondition = { ...conditionDefaults, name: "Condition Group 1" };

export const conditionListDefaults: ConditionList = {
    dynamicConditions: [initialCondition],
    joinType: joinTypeDefault,
};

export const eventTypeLabel: Record<string, string> = {
    contactAttribute: "Contact Attribute",
    custom: "Property Name",
    customEvent: "Property Name",
    regalVoiceEvent: "Property Name",
    triggeringEvent: "Property Name",
};

export const eventNameLabel: Record<string, string> = {
    contactAttribute: "Contact Attribute",
    customEvent: "Event Name",
    custom: "Event Name",
    regalVoiceEvent: "Event Name",
    triggeringEvent: "Property Name",
};
