import { FillChart } from "Components/shared/AgentNavBarButtons/AgentStats/AdvancedStats/Charts/FillChart/FillChart";
type StatProps = {
    loading: boolean;
    count: number;
};
// for now the target is hardcoded
const TARGET_DAILY = 150;
export const CompletedTaskTotals = ({ loading, count }: StatProps): JSX.Element => {
    const percentage = count / TARGET_DAILY;
    const desctiptor = count === 1 ? "Task" : "Tasks";
    return (
        <FillChart
            loading={loading}
            chartHeader="Total Completed Tasks"
            chartHeaderSubtitle="Today"
            chartHeaderInfoValue={`${count}`}
            chartHeaderInfoDescriptor={desctiptor}
            chartSubtextZero={"0 Tasks"}
            chartSubtextFull={`Target: ${TARGET_DAILY} Tasks`}
            percentage={percentage}
            hasMinDisplay={true}
        ></FillChart>
    );
};
