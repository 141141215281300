import { useState, useEffect } from "react";

import { useLazyQuery } from "@apollo/client";

import { REGAL_VOICE_DEFAULT_EVENTS } from "Pages/journeys/JourneyEditPage/Components/JourneyBuilder/Components/JourneyActionsPanel/NodeEditorPanel/Components/Shared/FormItemInputs/constants";
import { useQueryContactAttributes } from "Pages/journeys/JourneyEditPage/Components/JourneyBuilder/shared/hooks/useQueryContactAttributes";
import { getEventFields, getEventNames } from "ServerData/EventSchema/queries";
import { ConditionalEventType } from "Types/Journey";

import type { EventSchemaOptionEntry } from "ServerData/EventSchema/eventSchemaTypes";

export enum UsedInFeature {
    ConditionalNode,
    Segmentation,
    TriggeringNode,
}

export function getEventTypeKey(conditionalType: string): string {
    return conditionalType === "regalVoiceEvent" ? "regal_voice_event" : "track";
}

// REMOVE THIS HOOK
// @see src/ServerData/EventSchema/Hooks/useEventNamesQuery/useEventNamesQuery.ts
// @deprecated
export function useEventNames(
    conditionalType: string,
    eventName: string,
    source?: UsedInFeature
): { eventNames: string[]; eventSchema: EventSchemaOptionEntry } {
    const [eventNames, setEventNames] = useState([] as string[]);
    const [eventSchema, setEventSchema] = useState<EventSchemaOptionEntry>({});
    const { loadContactAttributes, contactAttributes } = useQueryContactAttributes({});

    const isContactCondtional = conditionalType === "contactAttribute";

    useEffect(() => {
        isContactCondtional && loadContactAttributes();
    }, [isContactCondtional]);

    function getEventFieldQueryVariables() {
        let eventTypeVariable = conditionalType;
        eventTypeVariable = getEventTypeKey(eventTypeVariable);

        const queryVar = { eventName, eventType: eventTypeVariable };
        return queryVar;
    }

    const [loadEventFields] = useLazyQuery(getEventFields, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
        variables: getEventFieldQueryVariables(),
        onCompleted(data) {
            const schemaOptions = {} as Record<string, any>;
            for (const item of data.getEventSchema as Record<string, string>[]) {
                schemaOptions[item.fieldName] = {
                    fieldType: item.fieldType,
                    fieldId: item.fieldId,
                };
            }
            setEventSchema(schemaOptions);
        },
    });

    const [loadEventNames] = useLazyQuery(getEventNames, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
        variables: {
            eventType: getEventTypeKey(conditionalType),
        },
        onCompleted(data) {
            setEventNames(
                [...data.getEventNames.items].sort((a, b) =>
                    a.toLowerCase() > b.toLowerCase() ? 1 : b.toLowerCase() > a.toLowerCase() ? -1 : 0
                )
            );
            setEventSchema({});
            if (eventName?.length > 0) {
                loadEventFields({
                    variables: getEventFieldQueryVariables(),
                });
            }
        },
    });

    useEffect(() => {
        // Dont have to load event names if the condition is based on triggering event (we DO still
        // have to load the event fields)
        if (source == UsedInFeature.TriggeringNode && conditionalType == ConditionalEventType.RegalVoice) {
            setEventNames(REGAL_VOICE_DEFAULT_EVENTS);
            return;
        }
        if (conditionalType != "triggeringEvent") {
            loadEventNames({
                variables: {
                    eventType: getEventTypeKey(conditionalType),
                },
            });
        }
    }, [conditionalType]);

    useEffect(() => {
        const { eventName: eventNameVariable, eventType } = getEventFieldQueryVariables();
        if (!!eventNameVariable?.trim()) {
            loadEventFields({
                variables: {
                    eventType,
                    eventName: eventNameVariable,
                },
            });
        } else {
            setEventSchema({});
        }
    }, [eventName, conditionalType]);

    return { eventNames, eventSchema: isContactCondtional ? contactAttributes : eventSchema };
}
