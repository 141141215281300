import AutoComplete from "antd/lib/auto-complete";
import { Rule } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";

import { EnhancedFormItem } from "Components/elements/FloatingLabel/EnhancedFormItem";
import { HighlightedString } from "Components/elements/HighlightedString/HighlightedString";
import { HighlightSelect } from "Pages/journeys/JourneyEditPage/Components/JourneyBuilder/Components/JourneyActionsPanel/NodeEditorPanel/Components/Shared/FormItemInputs/HighlightSelect";
import {
    caseInsensitiveFilter,
    OptionsData,
} from "Pages/journeys/JourneyEditPage/Components/JourneyBuilder/Components/JourneyActionsPanel/NodeEditorPanel/shared";

export function EventNameSelect({
    value,
    onChange,
    label,
    placeholder,
    eventNames,
    name,
    hidden,
    dependencies,
    rules,
    allowCustomEventName,
}: {
    value?: any;
    onChange?: (evt: any) => void;
    label: string;
    placeholder?: string;
    eventNames: string[];
    name: NamePath;
    hidden?: boolean;
    dependencies?: NamePath[];
    rules?: Rule[];
    allowCustomEventName: boolean;
}): JSX.Element {
    function createEventNameValue(v: any, h?: string) {
        return { value: v, label: <HighlightedString val={v} highlightVal={h} /> };
    }
    function createEventNameOptionValues(values?: Array<any>, highlight?: string): OptionsData {
        return !!values ? values.map((v) => createEventNameValue(v, highlight)) : [];
    }
    return (
        <EnhancedFormItem
            name={name}
            label={label}
            hidden={hidden}
            rules={rules}
            dependencies={dependencies}
            data-testid={`event-name-${name}`}
        >
            {allowCustomEventName ? (
                <AutoComplete
                    options={createEventNameOptionValues(eventNames)}
                    onChange={onChange}
                    filterOption={caseInsensitiveFilter}
                />
            ) : (
                <HighlightSelect value={value} onChange={onChange} options={eventNames} placeholder={placeholder} />
            )}
        </EnhancedFormItem>
    );
}
