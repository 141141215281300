export function operatorRequiresPropertyValue(propertyOperator: string): boolean {
    return [
        "equals",
        "notEquals",
        "contains",
        "startsWith",
        "endsWith",
        "greaterThan",
        "greaterThanEquals",
        "isOneOf",
        "lessThan",
        "lessThanEquals",
    ].includes(propertyOperator);
}

export function operatorRequiresPropertyValueIncludingDate(
    propertyOperator: string,
    dateComparisonType: string | undefined
): boolean {
    const nonDatePropertyNeedsPropertyValue = [
        "equals",
        "notEquals",
        "contains",
        "startsWith",
        "endsWith",
        "greaterThan",
        "greaterThanEquals",
        "isOneOf",
        "lessThan",
        "lessThanEquals",
    ].includes(propertyOperator);
    const datePropertyNeedsPropertyValue =
        internalIsDateComparison(propertyOperator) && dateComparisonType == "specific";
    return nonDatePropertyNeedsPropertyValue || datePropertyNeedsPropertyValue;
}

export function internalIsDateComparison(propertyOperator: string) {
    return ["after", "before", "exactlyOn", "onOrAfter", "onOrBefore", "anyTimeExcept"].includes(propertyOperator);
}

export function getConditionalFormFieldValue(formValues: any, name: string, rootField?: any) {
    const { filters, propertyOperator, propertyFieldId, propertyName, dateComparisonType, contactAttribute } =
        formValues;
    switch (name) {
        case "propertyOperator":
            return rootField ? filters?.[rootField.name]?.[name] : propertyOperator;
        case "propertyFieldId":
            return rootField ? filters?.[rootField.name]?.[name] : propertyFieldId;
        case "propertyName":
            return rootField ? filters?.[rootField.name]?.[name] : propertyName;
        case "dateComparisonType":
            return rootField ? filters?.[rootField.name]?.[name] : dateComparisonType;
        case "contactAttribute":
            return contactAttribute;
        case "filters":
            return filters;
    }
}
