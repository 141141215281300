import { clsx } from "clsx";

import { InfiniteScrollDropdown } from "./Components/InfiniteScrollDropdown/InfiniteScrollDropdown";
import { withHandlebarAutosuggest, HandelbarAutosuggestHOCProps } from "./withHandlebarAutosuggest";

import styles from "./AutocompleteStringValue.module.scss";

export interface AutocompleteStringProps extends HandelbarAutosuggestHOCProps {
    value?: any;
    onChange?: (value: any) => void;
    rootField?: any;
    propertyOperator?: string;
    propertyValueDisabled: boolean;
    propertyFieldId: string;
    isContactCondtional: boolean;
    maxTagCount?: number | "responsive";
    sufix?: JSX.Element;
    hideOverlayArrow?: boolean;
    dataTestid?: string;
    hidePlaceholder?: boolean;
}

export function AutocompleteStringValue({
    rootField,
    propertyValueDisabled,
    propertyFieldId,
    isContactCondtional,
    hidePlaceholder = false,
    ...rest
}: AutocompleteStringProps): JSX.Element {
    const hasRootField = !!rootField;

    return (
        <InfiniteScrollDropdown
            disabled={propertyValueDisabled}
            placeholder={
                hidePlaceholder
                    ? undefined
                    : !hasRootField
                    ? isContactCondtional
                        ? "Attribute Value"
                        : "Property Value"
                    : undefined
            }
            propertyFieldId={propertyFieldId}
            className={clsx({
                [styles.infiniteDropdown]: !hasRootField,
                [styles.infiniteDropdownMultiProperty]: hasRootField,
            })}
            {...rest}
        />
    );
}

export const AutocompleteStringValueWithHandlebars =
    withHandlebarAutosuggest<AutocompleteStringProps>(AutocompleteStringValue);
