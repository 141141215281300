import { Row } from "Components/shared/Flexbox";

// These component are the next stat panels that we are going to build
// import { AdvancedDispositions } from "./StatPanels/AdvancedDispositions/AdvancedDispositions";
// import { AdvancedQueues } from "./StatPanels/AdvancedQueues/AdvancedQueues";
import { AdvancedTotals } from "./StatPanels/AdvancedTotals/AdvancedTotals";

import styles from "./AdvancedStats.module.scss";

type StatProps = {
    shouldRefetch: boolean;
    _finishedOpening: boolean;
};
export const AdvancedStats = ({ shouldRefetch, _finishedOpening }: StatProps): JSX.Element => {
    return (
        <Row justifyContent="space-between" className={styles.statsContainer}>
            <AdvancedTotals shouldRefetch={shouldRefetch} />
            {/* {_finishedOpening && (
                <>
                    <AdvancedDispositions _shouldRefetch={shouldRefetch} />
                    <AdvancedQueues _shouldRefetch={shouldRefetch} />
                </>
            )} */}
        </Row>
    );
};
