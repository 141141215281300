import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SummarySids {
    [key: string]: boolean;
}
export interface TasksSummaryModalState {
    value?: string;
    submitted: SummarySids;
}

const initialState: TasksSummaryModalState = {
    submitted: {},
};

const TaskSummaryModalSlice = createSlice({
    name: "TaskSummarySlice",
    initialState,
    reducers: {
        setTasksModal: (state, action: PayloadAction<string>) => {
            state.value = action.payload;
        },
        setTaskSummarySid: (state, action: PayloadAction<string>) => {
            state.submitted[action.payload] = true;
        },
        removeTaskSummarySid: (state, action: PayloadAction<string>) => {
            delete state.submitted[action.payload];
        },
        removeTasksModalBySid: (state, action: PayloadAction<string>) => {
            if (state.value == action.payload) {
                state.value = undefined;
            }
        },
    },
});

export const { setTasksModal, setTaskSummarySid, removeTasksModalBySid, removeTaskSummarySid } =
    TaskSummaryModalSlice.actions;
export default TaskSummaryModalSlice.reducer;
