import { getLogger } from "Services/LoggingService";

const logger = getLogger("Performance");

export const Marks = {
    getSelectTaskName: (id: string): string => `SelectTask:${id}`,
    getUserInfoPanel: (id: string): string => `UserInfoPanel-Rendered:${id}`,
    getManualOutboundCallCreate: (taskSid: string): string => `ManualOutboundCallCreate:${taskSid}`,
    getManualOutboundSmsCreate: (taskSid: string): string => `ManualOutboundSMSCreate:${taskSid}`,
    getAttributeUpdateId: (profileId: string, attribute: string): string =>
        `getAttributeUpdateId:${profileId}:${attribute}`,
};
export const Measurements = {
    UserInfoPanel: "SelectTask-Render-UserInfoPanel",
    ManualOutboundCallTaskRender: "ManualOutboundCallTaskRender",
    ManualOutboundSmsTaskRender: "ManualOutboundSmsTaskRender",
    ContactAttributeUpdate: "ContactAttributeUpdate",
};

export function performanceMark(markName: string, markOptions: Partial<{ detail: any; startTime: number }> = {}): void {
    const existingMarks = performance.getEntriesByName(markName);

    if (existingMarks.length == 0) {
        performance.mark(markName, markOptions);
    }
}

export function performanceMeasure(measureName: string, startMark: string, clearMarks = false): void {
    const startMarks = performance.getEntriesByName(startMark);
    if (startMarks.length > 0) {
        const measure = performance.measure(measureName, startMark);

        // Datadog already has a `duration` metric in nanoseconds, so we don't need
        // to add a new facet.  Since there are 1000000 nanoseconds in 1 ms, just multiply
        logger.log(measureName, {
            metricName: measureName,
            duration: measure.duration * 1000000,
        });

        performance.clearMeasures(measureName);

        if (clearMarks) {
            performance.clearMarks(startMark);
        }
    }
}
