import { gql } from "@apollo/client";

import type { ContactListSavedViewBase } from "./types";
/**
 * @todo consolidate this query with the one in the
 * Pages/agentDesktop/agentInterface/AgentPanes/AgentActionPaneSelection/ContactsPanel/Components/ContactListSavedViews/queries.ts
 *
 * the nav and page are to far appart to put this some place shared that makes sense.
 * so once we move the nave close to the page we can move this query to a shared place.
 */

export type ContactListPanelSavedView = ContactListSavedViewBase & {
    // this is just id's so we arent using the team type
    teams?: string[];
};

export const getSidePanelContactViewsQuery = gql`
    query GetContactViews($savedViewsQueryInput: SavedViewsQueryInput!) {
        getAllSavedViewsForPage(savedViewsQueryInput: $savedViewsQueryInput) {
            items {
                id
                isDefault
                name
                page
                shareWithAllUsers
                teams {
                    id
                }
                pageQuery
                createdBy
                createdById
            }
        }
    }
`;
