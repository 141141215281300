import { Menu } from "antd";
import { clsx } from "clsx";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import SideNavContainer from "Components/shared/SidenavItems/SideNavContainer";
import { AgentDesktopSubPages } from "Services/state/agent/desktopUI/AgentDesktopUISlice";
import { selectTasksLength } from "Services/state/tasks/Selectors";
import { CollapsiblePanel, KNOWN_PANELS } from "Utils/CollapsiblePanels";

import { ContactListSavedViews } from "./ContactListSavedViews";

import styles from "./AgentDesktopSideNav.module.scss";

export default function AgentDesktopSideNav(): JSX.Element {
    const { pathname } = useLocation();
    const numTasks = useSelector(selectTasksLength);

    return (
        <CollapsiblePanel panelName={KNOWN_PANELS.adSubNav.name} expandedWidth={KNOWN_PANELS.adSubNav.width}>
            <div className={clsx(styles.container)}>
                <SideNavContainer sidenavTitle="Agent Desktop" trigger={null} data-testid="agent-desktop-sidenav">
                    <div className={clsx(styles.menuWrap, styles.taskMenuItem)}>
                        <Menu.Item
                            className={clsx(styles.menuItem, {
                                [styles.selected]: pathname === "/agent",
                            })}
                        >
                            <Link to="/agent">Current Tasks</Link>
                        </Menu.Item>
                        <div className={styles.countSpacer}>
                            <p data-testid="task-count" className={styles.taskCount}>
                                {numTasks ? numTasks : ""}
                            </p>
                        </div>
                    </div>

                    <div className={styles.menuWrap}>
                        <Menu.Item
                            className={clsx(styles.menuItem, {
                                [styles.selected]: pathname === `/agent/${AgentDesktopSubPages.CONTACT_PAGE}`,
                            })}
                        >
                            <Link to={`/agent/${AgentDesktopSubPages.CONTACT_PAGE}`}>My Contacts</Link>
                        </Menu.Item>
                    </div>

                    <ContactListSavedViews pathname={pathname} />
                </SideNavContainer>
            </div>
        </CollapsiblePanel>
    );
}
