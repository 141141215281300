import type { GetAgentStatsState } from "./queries";

export function totalCount(data?: GetAgentStatsState): number {
    if (!data?.getDailyStats) {
        return 0;
    }

    const { getDailyStats } = data;
    let total = 0;
    // fallback just for safety
    total += getDailyStats.callCount || 0;
    total += getDailyStats.smsCount || 0;
    total += getDailyStats.emailCount || 0;

    return total;
}
