import { lazy, ComponentType } from "react";

import { renderErrorMessage } from "Services/LoggingService";

const MAX_RETRIES = 5;

/**
 * Wrapper for import function - allows for retrying import of chunks when network connectivity deteriorates
 */
export function lazyComponent(
    componentName: string,
    importFn: () => Promise<{ default: ComponentType<any> }>
): ReturnType<typeof lazy> {
    function load(retryCount = 0): Promise<{ default: ComponentType<any> }> {
        return new Promise((resolve, reject) => {
            importFn()
                .then(resolve)
                .catch((error) => {
                    if (retryCount >= MAX_RETRIES) {
                        renderErrorMessage({
                            content: `Error loading ${componentName}`,
                            error,
                            loggerContext: "Lazy Component Loader",
                            duration: 10,
                            extraData: {
                                componentName,
                            },
                        });
                        return reject(error);
                    }
                    // 2**7 = 1/8 of a second
                    // 2**11 = 2 seconds
                    const retryDelay = Math.pow(2, 7 + retryCount);

                    setTimeout(() => load(retryCount + 1).then(resolve, reject), retryDelay);
                });
        });
    }

    return lazy(load);
}
