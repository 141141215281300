import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";

import { useFlags } from "Services/FeatureFlagService";

import styles from "../ConditionalFormItems.module.scss";

export function RealtiveDatePopover(): JSX.Element {
    const { relativeDateFormatUrl } = useFlags();
    return (
        <Popover
            title={<b>Examples</b>}
            content={
                <div className={styles.relativeDatePopover}>
                    <span>now-3h-14m-6d = 3 hours, 14 minutes, 6 days ago</span>
                    <span>now+1M+1w = 1 month, 1 week from now</span>
                    <span>now/d = 12am today</span>
                    <span>now/d-1d = 1 day before 12am today</span>

                    <span className={styles.popoverLink}>
                        See more examples{" "}
                        <a href={relativeDateFormatUrl || ""} target="_">
                            here.
                        </a>
                    </span>
                </div>
            }
        >
            <InfoCircleOutlined className={styles.relativeDatePopOverIcon} />
        </Popover>
    );
}
