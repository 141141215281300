import { combineReducers } from "redux";

import { name as conferenceSliceName, reducer as conferenceReducer } from "./ConferenceStateSlice";
import connectionQualityReducer from "./ConnectionQualityStateSlice";
import { name as networkQualityName, reducer as networkQualityReducer } from "./NetworkQualitySlice";

export type ConferenceState = {
    [conferenceSliceName]: ReturnType<typeof conferenceReducer>;
    connectionQuality: ReturnType<typeof connectionQualityReducer>;
    [networkQualityName]: ReturnType<typeof networkQualityReducer>;
};

export default combineReducers<ConferenceState>({
    [conferenceSliceName]: conferenceReducer,
    connectionQuality: connectionQualityReducer,
    [networkQualityName]: networkQualityReducer,
});
