import { MouseEvent, useMemo } from "react";

import { Col, Menu, Tooltip, Typography } from "antd";
import { clsx } from "clsx";

import { Loading } from "Components/elements/Loading";
import { Row } from "Components/shared/Flexbox";
import { DialPadActions } from "Components/shared/SearchableDialPad/SearchableDialPad.types";
import { formatPhoneNumber, isPhoneNumberValid } from "Services/CommunicationService";

import ContactSearchActions from "../ContactSearchActions/ContactSearchActions";

import type { Profile } from "Types/Profile";

import styles from "./ContactSearchDropdown.module.scss";

const MIN_PHONE_NUMBER_LENGTH = 10;

function stopPropagation(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
}

function generateContactDisplayInfo(item: Profile, searchedData?: string) {
    const fullName = getFullName(item);
    // the requirement is to show a contact's name, if there is a name associated with the contact
    // otherwise use either the phone number or the email, depending on which is available
    // and also based on what the agent searched for
    const primaryPeripherals = [item.contactPhone, item.email].filter(Boolean) as Array<string>;
    const secondaryPeripherals = [item.externalId, item.subBrand].filter(Boolean) as Array<string>;
    const allPeripherals = primaryPeripherals.concat(secondaryPeripherals);
    const matchingPeripheral = allPeripherals.find((info) => searchedData && info?.indexOf(searchedData) !== -1);
    // `formatPhoneNumber` checks if the input text contains valid phone number characters before formatting
    // so no need to double check here too (the peripheral could be an email address)
    const principalInfo = fullName || formatPhoneNumber(matchingPeripheral);
    // `formatPhoneNumber` checks if the input text contains valid phone number characters before formatting
    // so no need to double check here too (the peripheral could be an email address)
    const primaryPeripheralsInfo = primaryPeripherals.map((info) => formatPhoneNumber(info));
    return { principalInfo, primaryPeripheralsInfo, secondaryPeripherals, fullName };
}

export function getFullName(item: Profile): string | undefined {
    let fullName;
    // these shouldnt ever be undefined but just in case
    if (item?.firstName) {
        fullName = item.firstName;
    }
    if (item?.lastName) {
        fullName = fullName ? `${fullName} ${item.lastName}` : item.lastName;
    }

    return fullName;
}

export default function ContactSearchDropdown({
    data,
    loading,
    searchedBy,
    searchedData,
    onMenuItemSelect,
}: {
    data: Profile[] | undefined;
    loading: boolean;
    searchedBy: "contactPhone" | "contactName" | "email" | undefined;
    searchedData: string | null;
    onMenuItemSelect: (contact: Partial<Profile>, action: DialPadActions) => void;
}): JSX.Element {
    const ContentsDisplay = useMemo(() => {
        if (loading) {
            return (
                <Menu.Item key="loading" className={styles.menuDisabled}>
                    <div className={styles.menuStatusItem}>
                        <Loading text={null} />
                    </div>
                </Menu.Item>
            );
        }

        if (!data?.length) {
            if (
                searchedBy === "contactPhone" &&
                searchedData &&
                searchedData.length >= MIN_PHONE_NUMBER_LENGTH &&
                !isPhoneNumberValid(searchedData)
            ) {
                return (
                    <Menu.Item key="error" className={styles.menuDisabled}>
                        <div className={clsx(styles.menuStatusItem, styles.menuErrorItem)}>Invalid phone number.</div>
                    </Menu.Item>
                );
            }

            return (
                <Menu.Item key="noContent" className={styles.menuDisabled}>
                    <div className={styles.menuStatusItem}>
                        {searchedBy === "contactPhone"
                            ? "No matching contact."
                            : "No matching contact. Try phone number instead."}
                    </div>
                </Menu.Item>
            );
        }

        return data.map((item) => {
            // if a contact has multiple phone numbers and the agent searches by phone number
            // we get multiple entries with the same id (id is actually the contact's id)
            // React will complain about duplicated ids
            const uniqueId = `${item.id}-${item.contactPhone}-${item.email}`;
            const { principalInfo, primaryPeripheralsInfo, secondaryPeripherals, fullName } =
                generateContactDisplayInfo(item, searchedData?.replace(/[^a-zA-Z0-9]/g, ""));

            const tooltip = (
                <>
                    {fullName && <div>{fullName}</div>}
                    {primaryPeripheralsInfo.map((info) => (
                        <div key={info}>{info}</div>
                    ))}
                    {secondaryPeripherals.map((info) => (
                        <div key={info}>{info}</div>
                    ))}
                </>
            );

            return (
                <Menu.Item key={uniqueId} className={styles.menuWrapper}>
                    <Row justifyContent="space-between" onClick={stopPropagation}>
                        <Tooltip title={tooltip} placement="bottom" mouseEnterDelay={1}>
                            <Col className={styles.infoCol}>
                                <Row className={styles.infoRow}>
                                    <Typography.Text
                                        className={styles.contactName}
                                        data-testid={`principal-info-${principalInfo}`}
                                        ellipsis={true}
                                    >
                                        {principalInfo}
                                    </Typography.Text>
                                </Row>
                                <Row className={clsx(styles.infoRow, styles.peripheral)}>
                                    <Typography.Text data-testid={`primary-peripheral-info-${principalInfo}`}>
                                        {primaryPeripheralsInfo.join(" | ")}
                                    </Typography.Text>
                                </Row>
                                {secondaryPeripherals && (
                                    <Row className={clsx(styles.infoRow, styles.peripheral)}>
                                        <Typography.Text data-testid={`secondary-peripheral-info-${principalInfo}`}>
                                            {secondaryPeripherals.join(" | ")}
                                        </Typography.Text>
                                    </Row>
                                )}
                            </Col>
                        </Tooltip>
                        <ContactSearchActions value={item} onClick={onMenuItemSelect} />
                    </Row>
                </Menu.Item>
            );
        });
    }, [data, loading, onMenuItemSelect, searchedBy, searchedData]);

    return (
        <Menu mode="inline" className={styles.menu}>
            {ContentsDisplay}
        </Menu>
    );
}
