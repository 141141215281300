const MIN_FILL = 0;
const MAX_FILL = 100;

export const getFilledPercentage = (percentage: number, hasMinDisplay: boolean): string => {
    let fill = percentage * (MAX_FILL - MIN_FILL) + MIN_FILL;

    if (hasMinDisplay) {
        // we want to make sure we have at least 1% to show
        // so we just hard set it to 1 if it is less than 1
        if (fill < 1 && fill > 0) {
            fill = 1;
        }
    }
    fill = Math.floor(fill);
    fill = Math.min(fill, MAX_FILL);
    return `${fill}%`;
};
