import { useState, useEffect } from "react";

const WAIT_DEFAULT_ITERATIONS = 50;
export const WAIT_DEFAULT_INTERVAL_TIME = 100;

type UseWaitForObjectOptions<T> = {
    fn: () => T | undefined;
    onError?: () => void;
    iterations?: number;
    intervalTime?: number;
    skip?: boolean;
};

export function useWaitFor<T>({
    fn,
    onError,
    iterations = WAIT_DEFAULT_ITERATIONS,
    intervalTime = WAIT_DEFAULT_INTERVAL_TIME,
    skip = false,
}: UseWaitForObjectOptions<T>): T | undefined {
    const [result, setResult] = useState<T | undefined>(undefined);

    useEffect(() => {
        if (skip) {
            return;
        }
        let iteration = 0;
        const interval = setInterval(() => {
            if (iteration >= iterations) {
                clearInterval(interval);
                if (onError) {
                    onError();
                }
                return;
            }

            const value = fn();
            if (value) {
                clearInterval(interval);
                setResult(() => value);
            }

            iteration++;
        }, intervalTime);

        return () => {
            clearInterval(interval);
        };
        // We only care about the fn.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fn, skip]);

    return result;
}
