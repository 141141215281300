import { gql } from "@apollo/client";

export const getProfiles = gql`
    query ($queryParams: QueryParams!) {
        getProfiles(queryParams: $queryParams) {
            items {
                id
                contactPhone
                firstName
                lastName
                rvProperties
                email
                externalId
                subBrand
            }
        }
    }
`;

export const getPhonesEmails = gql`
    query ($queryParams: QueryParams!) {
        getPhonesEmails(queryParams: $queryParams) {
            items {
                id
                contactPhone
                firstName
                lastName
                email
                rvProperties
                externalId
                subBrand
            }
        }
    }
`;
