import { createSelector } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import { RootState } from "../../../Storage";
import { EmailUIPersistState } from "./EmailUIPersistSlice";

export const EmailThreadsSliceSelector = (state: RootState): EmailUIPersistState => state.emailUIPersist;

export const selectPersistedThread = createSelector(EmailThreadsSliceSelector, (allThreads) =>
    memoize((persistenceKey: string) => allThreads[persistenceKey])
);
