import { createSelector, Dictionary } from "@reduxjs/toolkit";

import { selectADSubPage, AgentDesktopSubPages } from "Services/state/agent/desktopUI/AgentDesktopUISlice";
import { RootReducerShape } from "Services/state/Storage";

import { contactsAdapter } from "../ContactsStateProvider";
import { ContactsState } from "../IndexProvider";
import { createContactFromPartial, isContactId } from "../utils";

import type { Profile } from "Types/Profile";

// THE ROOT STATE AND SLICE ACCECESSORS
export const RootContactsState = (state: RootReducerShape): ContactsState => state.contacts;
export const selectContactsStateSlice = createSelector(RootContactsState, (contactsState) => contactsState.contacts);
export const selectActiveContactStateSlice = createSelector(
    RootContactsState,
    (contactsState) => contactsState.activeContact
);
/**
 * see https://redux-toolkit.js.org/api/createEntityAdapter#selector-functions
 */
export const contactsSelectors = contactsAdapter.getSelectors(selectContactsStateSlice);

// still need this fallback to contactPhone while we make sure all tasks and SSE contact updates are created with profileId
export const selectContactByIdOrPhone = createSelector(
    contactsSelectors.selectById,
    contactsSelectors.selectAll,
    (_: RootReducerShape, contactIdOrPhone: string) => contactIdOrPhone,
    (contact, contacts: Profile[], contactIdOrPhone: string): Profile | undefined => {
        if (contact) {
            return contact;
        }
        return contacts.find(
            (c) =>
                c.contactPhone === contactIdOrPhone ||
                c.phones?.map(({ phoneNumber }) => phoneNumber).includes(contactIdOrPhone)
        );
    }
);

export const selectContactsByPhone = createSelector(
    contactsSelectors.selectAll,
    (_: RootReducerShape, contactPhone: string) => contactPhone,
    (contacts: Profile[], contactPhone: string): Profile[] => {
        return contacts.filter((c) => c?.contactPhone === contactPhone);
    }
);

export const { selectEntities: selectContactsDictionary } = contactsSelectors;

export const selectActiveContactValueUniqueId = createSelector(
    selectActiveContactStateSlice,
    selectADSubPage,
    (activeSlice, subPage) => {
        if (subPage === AgentDesktopSubPages.TASKS_PAGE) {
            return activeSlice.taskListActiveContact;
        } else if (subPage === AgentDesktopSubPages.CONTACT_PAGE) {
            return activeSlice.contactListActiveContact;
        }
    }
);

/**
 * TODO - When we remove selectActiveContact from the tasks selectors
 * we should rename this to selectActiveContact, with implied page awareness
 */
// the selector returns the contact associated with the active task
// not the contact associated with the contactlist.
// it can be replaced with selectPageAwareContactObject
// that takes the subPage (currently tasks, and contacts) into account
export const selectPageAwareContactObject = createSelector(
    selectActiveContactValueUniqueId,
    contactsSelectors.selectEntities,
    (contactIdOrPhone, contactEntities: Dictionary<Profile>) => {
        if (!contactIdOrPhone) {
            return;
        }
        let contact = contactEntities[contactIdOrPhone];

        if (!contact) {
            // this will catch if the number is one of the contact's phone numbers,
            // but maybe not the primary
            contact = Object.values(contactEntities).find(
                (c) =>
                    c?.contactPhone === contactIdOrPhone ||
                    c?.phones?.map(({ phoneNumber }) => phoneNumber).includes(contactIdOrPhone)
            );
            // if we still don't have a contact, we'll create one from the partial
            if (!contact) {
                const isId = isContactId(contactIdOrPhone);
                let contactPhone: string | undefined;
                let id: string | undefined;
                if (isId) {
                    id = contactIdOrPhone;
                } else {
                    contactPhone = contactIdOrPhone;
                }
                contact = createContactFromPartial({
                    ...(contactPhone ? { contactPhone } : {}),
                    ...(id ? { id, profileId: id } : {}),
                });
            }
        }
        return contact;
    }
);
