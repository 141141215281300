import { useCallback } from "react";

import { clsx } from "clsx";
import dayjs, { Dayjs } from "dayjs";

import RVDayJSPicker from "Components/elements/RVDayJSPicker/RVDayJSPicker";
import { DateFormat } from "Services/TimeService";

import styles from "../ConditionalFormItems.module.scss";

export function DatePickerItem({
    value,
    onChange,
    placeholder,
}: {
    onChange?: (value: string | undefined) => void;
    value?: any;
    placeholder?: string;
}): JSX.Element {
    const dateValue = value ? dayjs(value) : null;

    const onDateChange = useCallback(
        (newDate: Dayjs | null) => {
            let value;
            if (newDate) {
                value = newDate.format("YYYY-MM-DDTHH:mm:00");
            }
            onChange?.(value);
        },
        [onChange]
    );

    return (
        //todo  this should be replaced with an RVDatePicker
        <RVDayJSPicker
            picker={undefined}
            placeholder={placeholder}
            format={DateFormat.DATE_TIME_TZ_INDEPENDENT}
            onChange={onDateChange}
            onSelect={onDateChange}
            value={dateValue}
            className={clsx(styles.datetimeBorder)}
            showNow={false}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            showTime={{
                format: DateFormat.PRETTY_SHORT_TIME_DEFAULT,
                use12Hours: true,
            }}
        />
    );
}
