import { clsx } from "clsx";

import {
    RVNumericNonScientificInput,
    type RVNumericNonScientificInputProps,
} from "Components/FormItems/Inputs/RVNumericNonScientificInput/RVNumericNonScientificInput";

import styles from "./SharedInputs.module.scss";

//TODO: delay node shoudl be updated to use this, dont have time to test right now though
// make a container which extends InputProps so the `...rest` spread doesnt raise
// type errors
type NumericContainerProps = {
    label?: string;
} & RVNumericNonScientificInputProps;

export function NumericOnlyInput({
    value,
    onChange,
    label,
    className = "",
    maxDigits,
    ...rest
}: NumericContainerProps): JSX.Element {
    return (
        <div className={clsx("flex-row align-center", styles.delayInput)}>
            <RVNumericNonScientificInput
                className={clsx(styles.inputNumber, className)}
                value={value || ""}
                onChange={onChange}
                maxDigits={maxDigits}
                {...rest}
            />
            <span>{label}</span>
        </div>
    );
}
