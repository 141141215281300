import React, { useCallback } from "react";

import { Select } from "antd";

export function RVNumericArrayInput({
    value,
    onChange,
}: {
    onChange?: (value: number[] | undefined) => void;
    value?: any;
}): JSX.Element {
    const handleOnChange = useCallback(
        (values: number[]) => {
            const numericValues = values
                .map((value) => {
                    const newNumber = (value as any) * 1;
                    if (Number.isNaN(newNumber)) {
                        return;
                    }
                    return newNumber;
                })
                .filter((value) => value != undefined) as number[];
            onChange?.(numericValues);
        },
        [onChange]
    );

    // we want to use enter to make a new tag. we dont want to use enter to submit the form
    const stopEnterPropigation = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            // stop the event from bubbling up
            e.stopPropagation();
        }
    }, []);

    return (
        <Select
            mode="tags"
            value={value}
            onChange={handleOnChange}
            tokenSeparators={[" ", ","]}
            open={false}
            onKeyDown={stopEnterPropigation}
        />
    );
}
