import { ReactNode } from "react";

import { Layout, Menu } from "antd";
import { SiderProps } from "antd/lib/layout/Sider";
import { ItemType } from "antd/lib/menu/hooks/useItems";

import sidenavItems from "./SidenavItems.module.scss";

interface SidenavItemsProps extends SiderProps {
    sidenavTitle: string;
    /**
     * @deprecated `children` will be removed in next major version. Please use `items` instead
     */
    children?: ReactNode;
    items?: ItemType[];
}

export default function SideNavContainer({ sidenavTitle, children, items, ...props }: SidenavItemsProps): JSX.Element {
    const itemsProps = !children && !!items ? { items } : {};

    return (
        <Layout.Sider className={sidenavItems.sidebarSettings} {...props}>
            <h2 className={sidenavItems.settingsMenuTitle}>{sidenavTitle}</h2>
            <Menu style={{ backgroundColor: "#6a6acb" }} theme="dark" {...itemsProps}>
                {!!children && children}
            </Menu>
        </Layout.Sider>
    );
}
