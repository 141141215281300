import { NullOr } from "@regal-voice/shared-types";

import { AutocompleteOption } from "Services/Autocomplete/AutocompleteService";

export const initOptions = (value: string | string[] | null | undefined): string[] => {
    if (!value) {
        return [];
    } else if (Array.isArray(value)) {
        // this needs to be spread so that we don't mutate the original array
        const sorted = value.slice().sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
        return sorted;
    } else {
        return [value];
    }
};

/**
 * Flattens the options that come from the server, and depending if it's searching,
 * it will also add the search term or the already selected values at the top of the list.
 */
export const processSelectOptions = (
    availableOptions: NullOr<AutocompleteOption>,
    searchTerm: string,
    currentlySelectedValues: string[]
) => {
    const isSearching = searchTerm.trim().length > 0;
    const options = isSearching ? [searchTerm] : initOptions(currentlySelectedValues);
    if (availableOptions) {
        options.push(
            ...availableOptions.items
                .map((item) => item.info)
                .filter((item) => !options.includes(item) && searchTerm !== item)
        );
    }
    return options;
};
