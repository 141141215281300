import { Tooltip, Form, Switch } from "antd";

import Icon, { IconSizes } from "Components/elements/Icon";
import { Row } from "Components/shared/Flexbox";

import styles from "./LastEventFormItem.module.scss";

export function LastEventFormItem({ name, hidden }: { name: number; hidden?: boolean }): JSX.Element {
    return (
        <Row
            className={styles.lastEventOnly}
            justifyContent="space-between"
            alignItems="center"
            gap={2}
            style={{ visibility: hidden ? "hidden" : "visible", height: hidden ? "0" : "unset" }}
        >
            <Row alignItems="center">
                <label htmlFor="lastEventOnly">
                    <strong>Last Event Only</strong>
                </label>
                <Tooltip
                    placement="top"
                    title={
                        <div className={styles.infoSpan}>
                            If contact has more than one instance of this event, only reference the last instance (not
                            all) to satisfy condition
                        </div>
                    }
                >
                    <Icon className={styles.smallIcon} size={IconSizes.SMALL} icon="info-circle" />
                </Tooltip>
            </Row>
            <Form.Item
                name={[name, "lastEventOnly"]}
                valuePropName="checked"
                initialValue={false}
                noStyle
                hidden={hidden}
            >
                <Switch />
            </Form.Item>
        </Row>
    );
}
