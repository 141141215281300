import { useCallback } from "react";

import { LogoutOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/client";
import { Menu } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Icon, { IconSizes } from "Components/elements/Icon";
import { logger as authLogger, logout } from "Services/AuthService";
import { getEmbedUiConfigByKey } from "Services/embed/CrossDomainMessenger";
import { useFlags } from "Services/FeatureFlagService";
import { updateAgent } from "Services/marketing-api/agents/mutations";
import { selectAgentActivity, selectWorkerSid } from "Services/state/agent/AgentInformationSlice";
import { setDidManuallyLogOut } from "Services/state/users/UserSlice";

import {
    AgentAvailabilityDropdown,
    MENU_ITEM_ARIA_LABEL_PREFIX,
} from "../AgentAvailabilityDropdown/AgentAvailabilityDropdown";
import AgentStatusDisplay from "./AgentStatusDisplay";

export function AgentStatusDropdown(): JSX.Element {
    const dispatch = useDispatch();
    const agentActivity = useSelector(selectAgentActivity);
    const [updateAgentMutation] = useMutation(updateAgent);
    const workerSid = useSelector(selectWorkerSid);
    const { showNewProductAlert, newProductAlertUrl, email, hasPreRecordedVmDrops } = useFlags();
    const showEmail = email > 0;
    const showAgentSettings = showEmail || hasPreRecordedVmDrops;
    const shouldHideAgentSettingsWhenEmbedded = getEmbedUiConfigByKey("hideAgentSettings");
    const shouldActuallyShowAgentSettings = showAgentSettings && !shouldHideAgentSettingsWhenEmbedded;

    function handleOnStatusChange(status: string) {
        if (agentActivity.name !== status) {
            updateAgentMutation({
                variables: {
                    updateUserData: {
                        status,
                        twilioSid: workerSid,
                    },
                },
            });
        }
    }

    const manualLogoutHandler = useCallback(() => {
        authLogger.log("Logging out from status dropdown");
        dispatch(setDidManuallyLogOut(true));
        logout();
    }, [dispatch]);

    return (
        <AgentAvailabilityDropdown
            onStatusChange={handleOnStatusChange}
            currentAgentStatus={agentActivity.name}
            additionalMenuItems={
                <>
                    {showNewProductAlert && (
                        <>
                            <Menu.Item key="logout" icon={<Icon icon="megaphone" size={IconSizes.SMALL} />}>
                                <a href={newProductAlertUrl} target="_blank" rel="noreferrer">
                                    What&apos;s New
                                </a>
                            </Menu.Item>
                            <Menu.Divider />
                        </>
                    )}
                    {shouldActuallyShowAgentSettings && (
                        <Menu.Item
                            key="Settings"
                            icon={<Icon icon="settings" size={IconSizes.SMALL} />}
                            aria-label={`${MENU_ITEM_ARIA_LABEL_PREFIX}settings`}
                        >
                            <Link to="/agent/my-settings">My Settings</Link>
                        </Menu.Item>
                    )}
                    <Menu.Item
                        key="logout"
                        icon={<LogoutOutlined />}
                        onClick={manualLogoutHandler}
                        aria-label={`${MENU_ITEM_ARIA_LABEL_PREFIX}logout`}
                    >
                        Log out
                    </Menu.Item>
                </>
            }
        >
            <span translate="no" style={{ cursor: "pointer" }}>
                <AgentStatusDisplay userActivityInformation={agentActivity} />
            </span>
        </AgentAvailabilityDropdown>
    );
}
