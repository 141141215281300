import { useEffect } from "react";

import { useQuery } from "@apollo/client";
import { Typography } from "antd";

import { GET_AGENT_TASK_STATS } from "../queries";

import type { GetAgentStatsState } from "../queries";

import styles from "./BasicStats.module.scss";

export function totalCount(data?: GetAgentStatsState): number {
    if (!data?.getDailyStats) {
        return 0;
    }

    const { getDailyStats } = data;
    let total = 0;
    // fallback just for safety
    total += getDailyStats.callCount || 0;
    total += getDailyStats.smsCount || 0;
    total += getDailyStats.emailCount || 0;

    return total;
}

type StatProps = {
    shouldRefetch: boolean;
};
export const BasicStats = ({ shouldRefetch }: StatProps): JSX.Element => {
    const { data, loading, error, refetch } = useQuery<GetAgentStatsState>(GET_AGENT_TASK_STATS, {});

    const total = totalCount(data);
    useEffect(() => {
        if (shouldRefetch) {
            refetch();
        }
    }, [shouldRefetch, refetch]);

    if (loading) {
        return <Typography.Text>Loading...</Typography.Text>;
    }

    if (error) {
        return <Typography.Text>Error Loading Stats.</Typography.Text>;
    }

    return (
        <span>
            <Typography.Text>Total Tasks Completed Today</Typography.Text>
            <Typography.Text className={styles.completed}>{total}</Typography.Text>
        </span>
    );
};
