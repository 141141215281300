import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RVTask } from "@regal-voice/shared-types";
import * as _ from "lodash";

function notFunction(x: any) {
    return !_.isFunction(x);
}

export function mapResToTask(reservation: any, { enabled, inboundConferenceWorkflowId }: any = {}): RVTask | undefined {
    if (!reservation) {
        return;
    }

    return {
        ..._.omit(_.pickBy(reservation.task, notFunction), [
            "dateCreated",
            "dateUpdated",
            "_worker",
            "_log",
            "_request",
            "transfers",
            "_events",
        ]),
        autoAnswer:
            reservation.task?.workflowSid === inboundConferenceWorkflowId
                ? enabled
                : reservation.task.attributes.autoAnswer,
        reservationCreatedDate: reservation.dateCreated?.toISOString(),
        reservationUpdatedDate: reservation.dateUpdated?.toISOString(),
        createdDate: reservation.task.dateCreated?.toISOString(),
        updatedDate: reservation.task.dateUpdated?.toISOString(),
        channelType: reservation.task.taskChannelUniqueName,
        sid: reservation.sid,
        status: reservation.status,
        taskSid: reservation.task.sid,
        taskStatus: reservation.task.status,
        workerSid: reservation._worker.sid,
    } as RVTask & { autoAnswer: boolean };
}

export interface ActiveTaskState {
    value?: string;
}

const initialState: ActiveTaskState = {};

/**
 * The activeTask slice manages the state of the task currently shown in the activity view of the agent desktop.
 */
const ActiveTaskSlice = createSlice({
    name: "activeTask",
    initialState,
    reducers: {
        updateActiveTask: (state, action: PayloadAction<RVTask | undefined>) => {
            state.value = action.payload?.sid;
        },
        clearActiveTask: () => {
            return initialState;
        },
    },
});

export const { updateActiveTask, clearActiveTask } = ActiveTaskSlice.actions;
export default ActiveTaskSlice.reducer;
