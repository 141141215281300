import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { NullOr } from "@regal-voice/shared-types";

export type ActiveContactState = {
    // this is the storing the id for the contact that is set when we select a task
    taskListActiveContact: NullOr<string>;
    // this is a storing the id for the contact we are selecting from the list
    contactListActiveContact: NullOr<string>;
};

const initialState: ActiveContactState = {
    taskListActiveContact: null,
    contactListActiveContact: null,
};

const ActiveContactStateSlice = createSlice({
    name: "ActiveContact",
    initialState,
    reducers: {
        setSelectedTaskContact(state, action: PayloadAction<string>) {
            state.taskListActiveContact = action.payload;
        },
        setSelectedListContact(state, action: PayloadAction<string>) {
            state.contactListActiveContact = action.payload;
        },
        clearSelectedTaskContact(state) {
            state.taskListActiveContact = null;
        },
        clearSelectedListContact(state) {
            state.contactListActiveContact = null;
        },
    },
});

export const { setSelectedTaskContact, setSelectedListContact, clearSelectedTaskContact, clearSelectedListContact } =
    ActiveContactStateSlice.actions;
export default ActiveContactStateSlice.reducer;
