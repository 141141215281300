import generatePicker from "antd/lib/date-picker/generatePicker";
import { Dayjs, extend } from "dayjs";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
// eslint-disable-next-line import/order
import "antd/lib/date-picker/style/index";

extend(weekday);
extend(localeData);

/**
 * DO NOT USE THIS COMPONENT DIRECTLY
 * This is a wrapper around the antd DatePicker component that uses DayJS as the date library
 * This is used to ensure that the date library is consistent across the application
 * If you need to used a DatePicker, use the RVTimePicker or the RVDatePicker components
 **/
const RVDayJSPicker = generatePicker<Dayjs>(dayjsGenerateConfig as any);
export default RVDayJSPicker;
