import { BehaviorSubject } from "rxjs";

const METHODS = ["subscribe", "getValue", "pipe"] as const;

export type ReadonlyBehaviorSubject<T> = Pick<BehaviorSubject<T>, (typeof METHODS)[number]>;

export function readOnly<T>(subject$: BehaviorSubject<T>): ReadonlyBehaviorSubject<T> {
    return METHODS.reduce(
        (acc, mthd) => ({
            ...acc,
            [mthd]: subject$[mthd].bind(subject$),
        }),
        {} as ReadonlyBehaviorSubject<T>
    );
}
