import { Tooltip } from "antd";
import { useSelector } from "react-redux";

import {
    RVButton,
    getTooltipButtonContainerStyles,
    getTooltipButtonStyles,
} from "Components/elements/RVButton/RVButton";
import { DialPadActions } from "Components/shared/SearchableDialPad/SearchableDialPad.types";
import { isPhoneNumberValid as validatePhoneNumber } from "Services/CommunicationService";
import { useFlags } from "Services/FeatureFlagService";
import { selectDeviceCallSid } from "Services/state/conferences/Selectors";
import { doesContactHaveNoEmail, doesContactHaveNoPhone } from "Services/state/contacts/utils";
import { validateEmail } from "Services/validation/EmailValidationService";

import type { Profile } from "Types/Profile";

import styles from "./ContactSearchActions.module.scss";

const MESSAGES = {
    SEND_EMAIL: "Send an email",
    NO_EMAIL: "No email associated with contact",
    SEND_SMS: "Send a text",
    MAKE_CALL: "Make a call",
    NO_PHONE: "No phone numbers associated with contact",
    ACTIVE_CONFERENCE: "There is already an active conference",
    INCORRECT_PHONE_NUMBER: "Contact phone number is invalid",
};

export default function ContactSearchActions({
    value,
    onClick,
    disabled,
}: {
    value: Partial<Profile> | undefined;
    onClick: (contact: Partial<Profile>, action: DialPadActions) => void;
    disabled?: boolean;
}): JSX.Element | null {
    const { email: emailVersion } = useFlags();

    const isValidPhoneNumber = value?.contactPhone ? validatePhoneNumber(value.contactPhone) : false;
    const isValidEmail = value?.email ? validateEmail(value.email) : false;

    const connectionCallSid = useSelector(selectDeviceCallSid);

    const contactHasNoEmail = doesContactHaveNoEmail(value);
    const contactHasNoPhone = doesContactHaveNoPhone(value);
    const disableCallButton = !!connectionCallSid || disabled || contactHasNoPhone || !isValidPhoneNumber;
    const disableSmsButton = disabled || contactHasNoPhone || !isValidPhoneNumber;
    const disableEmailButton = disabled || contactHasNoEmail || !isValidEmail;

    function sendSmsTooltip() {
        if (contactHasNoPhone) {
            return MESSAGES.NO_PHONE;
        }

        if (!isValidPhoneNumber) {
            return MESSAGES.INCORRECT_PHONE_NUMBER;
        }

        return MESSAGES.SEND_SMS;
    }

    function placeCallButtonTooltip() {
        if (contactHasNoPhone) {
            return MESSAGES.NO_PHONE;
        }

        if (connectionCallSid) {
            return MESSAGES.ACTIVE_CONFERENCE;
        }

        if (!isValidPhoneNumber) {
            return MESSAGES.INCORRECT_PHONE_NUMBER;
        }

        return MESSAGES.MAKE_CALL;
    }

    if (!value) {
        return null;
    }

    function handleOnClick(action: DialPadActions) {
        if (disabled || !value) {
            return;
        }
        onClick(value, action);
    }

    return (
        <div className={styles.contactSearchActions}>
            {emailVersion > 0 && (
                <Tooltip title={contactHasNoEmail ? MESSAGES.NO_EMAIL : MESSAGES.SEND_EMAIL} placement="bottom">
                    {/* For antd Tooltips to work well on disabled buttons, we need this workaround from https://github.com/react-component/tooltip/issues/18#issuecomment-411476678 */}
                    <span style={getTooltipButtonContainerStyles(disableEmailButton)}>
                        <RVButton
                            icon="email"
                            disabled={disableEmailButton}
                            shape="circle"
                            size="middle"
                            type="text"
                            className={styles.contactSearchButton}
                            onClick={() => handleOnClick(DialPadActions.EMAIL)}
                            data-testid="ContactSearchActions-EMAIL"
                            style={getTooltipButtonStyles(disableEmailButton)}
                        />
                    </span>
                </Tooltip>
            )}
            <Tooltip title={sendSmsTooltip()} placement="bottom">
                {/* For antd Tooltips to work well on disabled buttons, we need this workaround from https://github.com/react-component/tooltip/issues/18#issuecomment-411476678 */}
                <span style={getTooltipButtonContainerStyles(disableSmsButton)}>
                    <RVButton
                        icon="task-chat"
                        disabled={disableSmsButton}
                        shape="circle"
                        size="middle"
                        type="text"
                        className={styles.contactSearchButton}
                        onClick={() => handleOnClick(DialPadActions.SMS)}
                        data-testid="ContactSearchActions-SMS"
                        style={getTooltipButtonStyles(disableSmsButton)}
                    />
                </span>
            </Tooltip>
            <Tooltip title={placeCallButtonTooltip()} placement="bottom">
                {/* For antd Tooltips to work well on disabled buttons, we need this workaround from https://github.com/react-component/tooltip/issues/18#issuecomment-411476678 */}
                <span style={getTooltipButtonContainerStyles(disableCallButton)}>
                    <RVButton
                        icon="place-call"
                        disabled={disableCallButton}
                        shape="circle"
                        size="middle"
                        type="text"
                        className={styles.contactSearchButton}
                        onClick={() => handleOnClick(DialPadActions.CALL)}
                        data-testid="ContactSearchActions-CALL"
                        style={getTooltipButtonStyles(disableCallButton)}
                    />
                </span>
            </Tooltip>
        </div>
    );
}
