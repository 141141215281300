import { SidenavItems } from "Components/shared/SidenavItems/SidenavItems";
import { useFlags } from "Services/FeatureFlagService";

import type { PageSubNavItem } from "Types/Settings";

export function PhoneNumbersSideNavBar(): JSX.Element {
    const { showBusinessProfiles, showBrandedNumbers, maxNumberBusinessProfiles } = useFlags();

    const settings: Array<PageSubNavItem> = [
        {
            key: "business-profile",
            title: `Business Profile${maxNumberBusinessProfiles > 1 ? "s" : ""}`,
            href: "/business-profile",
            isVisible: showBusinessProfiles,
        },
        {
            key: "branded-phone-number",
            title: "Branded Caller ID",
            href: "/branded-phone-numbers",
            isVisible: showBrandedNumbers,
        },
    ];

    return <SidenavItems sidenavTitle="Phone Numbers" items={settings} basePath="/phone-numbers" />;
}
