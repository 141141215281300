import { HighlightedString } from "Components/elements/HighlightedString/HighlightedString";

import styles from "./RVEventSchemaOption.module.scss";

export function RVEventSchemaOption({
    val,
    highlightVal,
    hideTypeLabel,
    valType,
}: {
    val: string;
    highlightVal?: string;
    hideTypeLabel?: boolean;
    valType: string;
}): JSX.Element {
    return (
        <span className={styles.eventSchemaOptionContainer} key={`event-schema-option-${val}`}>
            <HighlightedString val={val} highlightVal={highlightVal} />
            {!hideTypeLabel && <span className={styles.typeMetaInfo}>Data Type: {valType} </span>}
        </span>
    );
}

export function createSchemaOption(v: string, t: string, h?: string, hideTypeLabel?: boolean) {
    return {
        value: v,
        label: <RVEventSchemaOption val={v} highlightVal={h} hideTypeLabel={hideTypeLabel} valType={t} />,
    };
}
