import styles from "./HighlightedString.module.scss";

export function HighlightedString({ val, highlightVal }: { val: string; highlightVal?: string }): JSX.Element {
    function getHighlightMatchIndices() {
        const optionText = val.toLowerCase();
        const optionHighlight = highlightVal?.toLowerCase() || "";

        const spliceIndices = [];
        let idx = optionText.indexOf(optionHighlight);

        while (idx !== -1) {
            spliceIndices.push([idx, idx + optionHighlight.length]);
            idx = optionText.indexOf(optionHighlight, idx + 1);
        }

        return spliceIndices;
    }

    function highlightValueMatches(): Array<JSX.Element | string> {
        const highlightMatches = getHighlightMatchIndices();
        const strBuffer: Array<JSX.Element | string> = [];
        let strPointer = 0;

        highlightMatches.forEach(([startIndex, endIndex]) => {
            strBuffer.push(val.slice(strPointer, startIndex));
            strBuffer.push(
                <span className={styles.highlight} key={`${startIndex}-${endIndex}`}>
                    {val.slice(startIndex, endIndex)}
                </span>
            );
            strPointer = endIndex;
        });

        strBuffer.push(val.slice(strPointer));

        return strBuffer;
    }

    return (
        <span key={val} className={styles.highlightContainer}>
            {!!highlightVal ? highlightValueMatches() : val}
        </span>
    );
}
