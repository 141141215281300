import { PlayCircleFilled, StopFilled } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import { useSelector } from "react-redux";

import { getColor } from "Services/HelpersService";
import { selectBrandAvailableStatuses } from "Services/state/brand";

import styles from "./AgentAvailabilityDropdown.module.scss";

type AgentAvailabilityDropdownProps = {
    onStatusChange?: (key: string) => void;
    children?: JSX.Element;
    currentAgentStatus?: string;
    additionalMenuItems?: JSX.Element;
};

export const MENU_ITEM_ARIA_LABEL_PREFIX = "agent-availability-dropdown-item-";

export function AgentAvailabilityDropdown({
    children,
    onStatusChange,
    currentAgentStatus,
    additionalMenuItems,
}: AgentAvailabilityDropdownProps): JSX.Element {
    const availableStatuses = useSelector(selectBrandAvailableStatuses);

    return (
        <Dropdown
            overlayClassName={styles.dropdown}
            overlay={
                <Menu translate="no" selectedKeys={[currentAgentStatus ?? ""]}>
                    <Menu.ItemGroup>
                        {availableStatuses.map(({ name, available }) => (
                            <Menu.Item
                                onClick={() => onStatusChange?.(name)}
                                key={name}
                                aria-label={`${MENU_ITEM_ARIA_LABEL_PREFIX}${name}`}
                                icon={
                                    available ? (
                                        <PlayCircleFilled style={{ color: getColor("green3") }} />
                                    ) : (
                                        // eslint-disable-next-line react/jsx-no-undef
                                        <StopFilled style={{ color: getColor("gray3") }} />
                                    )
                                }
                            >
                                <p className={styles.dropdownItem}>{name}</p>
                            </Menu.Item>
                        ))}
                    </Menu.ItemGroup>
                    <Menu.Divider />

                    {additionalMenuItems}
                </Menu>
            }
        >
            {children}
        </Dropdown>
    );
}
