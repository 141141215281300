import type { Profile } from "Types/Profile";

export type GetProfilesQueryReturn = {
    getPhonesEmails: {
        items: Profile[];
        total: number;
    };
};

export enum DialPadViews {
    CONTACT_SEARCH = "contactSearch",
    FROM_NUMBER_PICKER = "fromNumberPicker",
}

export enum DialPadActions {
    CALL = "call",
    SMS = "sms",
    EMAIL = "email",
}
