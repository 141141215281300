import { useState } from "react";

import { useLazyQuery } from "@apollo/client";
import { camelCase, remove } from "lodash";

import { EventSchemaOptionEntry } from "ServerData/EventSchema/eventSchemaTypes";
import { EventSchemaListReturnType, getEventFields } from "ServerData/EventSchema/queries";
import { useFlags } from "Services/FeatureFlagService";
import { convertTopLevelAttribute } from "Services/HelpersService";

// @see src/ServerData/EventSchema/Hooks/useContactAttributesQuery/useContactAttributesQuery.ts
// @deprecated
export function useQueryContactAttributes({
    attributesMapper = convertTopLevelAttribute,
    formatToObject = true,
}: {
    attributesMapper?: (s: string, convertFn: (p: string) => string) => string;
    formatToObject?: boolean;
}) {
    const [contactAttributes, setContactAttributes] = useState<EventSchemaOptionEntry>({});
    const { journeyHideUnflattenOptIn } = useFlags();
    function contactAttributesFilter(d: Record<string, any>): boolean {
        /*
            filter specific attributes from list, currently anythign under rv_segment as
            well as the specific key rv_properties.do_not_trigger_journeys. This will
            exclude ALL CHILDREN of a key
        */
        const filterAttributes = [
            "context",
            "ref_id",
            "original_phone",
            "original_email",
            "created_at",
            "last_updated_by",
            "contact_from",
            "rv_static_segments",
            "rv_properties.do_not_trigger_journeys",
            "_rv_linked_profile_table_version",
            "_rv_linked_session_table_version",
            "_rv_migration_status",
            "last_session_id",
            "lead_score",
            "linked_profile_id",
            "linked_session_id",
            "linked_user_id",
            "opt_in.[].ip",
            "opt_in.[].name",
            "opt_in.[].source",
            "opt_in.[].subscribed",
            "opt_in.[].text",
            "opt_in.[].timestamp",
            "ref_id",
            "session_external_id",
            "session_id",
            "version",
            "address",
            "age",
            "birthday",
            "custom_entities",
            "updated_at",
            "_rv_should_send_profile_event_to_destinations",
        ];

        if (journeyHideUnflattenOptIn) {
            filterAttributes.push("opt_in");
        }

        return filterAttributes.reduce((shouldFilter, attr) => d.fieldName.startsWith(attr) || shouldFilter, false);
    }
    function cleanContactAttributes(data: Record<string, any>) {
        const { getEventSchema: contactAttrbutes } = data;
        const requiredOptions = [
            {
                fieldName: "contactPhone",
                fieldType: "string",
            },
            {
                fieldName: "externalId",
                fieldType: "string",
            },
            {
                fieldName: "email",
                fieldType: "string",
            },
            {
                fieldName: "firstName",
                fieldType: "string",
            },
            {
                fieldName: "lastName",
                fieldType: "string",
            },
        ];

        remove(contactAttrbutes, contactAttributesFilter);
        const contactAttributeValues = [...requiredOptions, ...(contactAttrbutes || [])]?.map(
            (d: Record<string, any>) => [
                attributesMapper(d.fieldName, camelCase),
                {
                    fieldType: d.fieldType,
                    fieldId: d.fieldId,
                },
            ]
        );

        if (formatToObject) {
            return Object.fromEntries(contactAttributeValues);
        }

        return contactAttributeValues;
    }

    const [loadContactAttributes, { loading }] = useLazyQuery<EventSchemaListReturnType>(getEventFields, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
        variables: {
            eventType: "identify",
            eventName: "user_properties",
        },
        onCompleted(data) {
            const { getEventSchema: contactAttrbutes } = data;
            remove(contactAttrbutes, contactAttributesFilter);

            setContactAttributes(cleanContactAttributes(data));
        },
    });

    return { loadContactAttributes, contactAttributes, loading };
}
