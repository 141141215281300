import { PlayCircleFilled, StopFilled } from "@ant-design/icons";

import Icon, { IconSizes, IconVariants } from "Components/elements/Icon";
import { getColor } from "Services/HelpersService";

import styles from "./AgentIcon.module.scss";

export function AgentIcon({ available }: { available: boolean }): JSX.Element {
    return (
        <div className={styles.iconWrapper}>
            <Icon
                icon="headset"
                color="light-purple"
                size={IconSizes.LARGE}
                variant={IconVariants.LARGE_CIRCLE}
                bgColor="purple-1"
            />
            <div className={styles.availableStatus}>
                {available ? (
                    <PlayCircleFilled style={{ color: getColor("green3") }} />
                ) : (
                    <StopFilled style={{ color: getColor("gray3") }} />
                )}
            </div>
        </div>
    );
}
