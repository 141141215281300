import { useCallback } from "react";

import { Input, InputProps } from "antd";

const isDeletingCharacters = (newValue: string, value: string) => {
    if (!value) {
        return false;
    }
    return newValue.length < `${value}`.length;
};

export type RVNumericNonScientificInputProps = {
    value?: any;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    className?: string;
    maxDigits?: number;
} & InputProps;

// please dont blame me for this component.
// I was just preserving it as it was, but make
// it cleaner and reusable
export function RVNumericNonScientificInput({
    value,
    onChange,
    className = "",
    maxDigits,
    ...rest
}: RVNumericNonScientificInputProps) {
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            // value * 1 will return value if value is a number, else NaN
            const newNumber = (e.target.value as any) * 1;
            if (maxDigits && `${e.target.value}`.length > maxDigits) {
                return;
            }
            if (Number.isNaN(newNumber) && !isDeletingCharacters(`${e.target.value}`, value)) {
                return;
            }
            onChange?.(e);
        },
        [maxDigits, onChange, value]
    );

    return <Input value={value || ""} onChange={handleChange} className={className} {...rest} />;
}
