import { combineReducers } from "redux";

import { journeyNotesReducer } from "./JourneysNotesStateSlice";
import { journeyReducer } from "./JourneyStateSlice";

type JourneyState = {
    journey: ReturnType<typeof journeyReducer>;
    journeysNotes: ReturnType<typeof journeyNotesReducer>;
};

export default combineReducers<JourneyState>({
    journey: journeyReducer,
    journeysNotes: journeyNotesReducer,
});
