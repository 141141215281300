import { Rule } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";

import { EnhancedFormItem } from "Components/elements/FloatingLabel/EnhancedFormItem";
import { TypeaheadSelect } from "Pages/journeys/JourneyEditPage/Components/JourneyBuilder/Components/JourneyActionsPanel/NodeEditorPanel/Components/Shared/FormItemInputs/TypeaheadSelect";

import type { EventSchemaOptionEntry } from "ServerData/EventSchema/eventSchemaTypes";

export function PropertySelect({
    value,
    onChange,
    placeholder,
    label,
    eventSchema,
    dependencies,
    name,
    rules,
    hidden,
}: {
    value?: any;
    onChange?: (evt: any) => void;
    placeholder?: string;
    label: string;
    eventSchema: EventSchemaOptionEntry;
    dependencies?: NamePath[];
    name: NamePath;
    rules?: Rule[];
    hidden?: boolean;
}): JSX.Element {
    return (
        <EnhancedFormItem
            name={name}
            label={label}
            hidden={hidden}
            dependencies={dependencies}
            rules={rules}
            data-testid={`property-select-${name}`}
        >
            <TypeaheadSelect value={value} onChange={onChange} eventSchema={eventSchema} placeholder={placeholder} />
        </EnhancedFormItem>
    );
}
