import { useState, useEffect } from "react";

import { interval, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { prettyTimeSince } from "Services/TimeService";

export function useTimeElapsed({ startTime }: { startTime: Date | number | string }) {
    const [timeDisplay, setTimeDisplay] = useState<string>(() => prettyTimeSince(startTime));

    useEffect(() => {
        const notifier = new Subject<null>();

        interval(500)
            .pipe(takeUntil(notifier))
            .subscribe(() => setTimeDisplay(prettyTimeSince(startTime)));

        return () => notifier.next(null);
    }, [startTime]);
    return { timeDisplay };
}
