import { memo, useState } from "react";

import { Form } from "antd";
import { NamePath } from "antd/lib/form/interface";
import { clsx } from "clsx";

import { hasValue } from "./utils";

import styles from "./FloatingLabel.module.scss";

// these very clearly are used, eslint doesnt seem to like, anon
// functions and destructured props
// eslint-disable-next-line react-redux/no-unused-prop-types
type FormItemFieldType = { getFieldValue: any; prefixName?: NamePath };

type LabelProps = {
    children: JSX.Element;
    name?: NamePath;
    scrollable?: boolean;
    fixedFloatingLabel?: boolean;
    clearable?: boolean;
};
function FloatingLabel({ children, name, scrollable, fixedFloatingLabel, clearable = false }: LabelProps): JSX.Element {
    const [focused, setFocused] = useState(false);
    const floatClass = scrollable ? "floating-label-scrollable" : "floating-label";

    return (
        <Form.Item noStyle shouldUpdate>
            {({ getFieldValue, prefixName }: FormItemFieldType) => {
                // this was the original logic in this component, but it wasnt always correct
                const prefixPath = Array.isArray(prefixName) ? prefixName : [prefixName];
                const namePath = Array.isArray(name) ? name : name ? [name] : [];
                const path = prefixName ? [...prefixPath, ...namePath] : namePath;
                const pathValue = getFieldValue(path);

                // this logic is from a copy pasted version of this component.
                // and this works. so we are falling back to this logic
                // further investigation needs to be done to figure out why
                // the original logic wasnt working
                const nameValue = getFieldValue(name);
                const shouldFloatLabel = hasValue(pathValue) || hasValue(nameValue) || (focused && !clearable);
                return (
                    <div
                        onBlur={() => setFocused(false)}
                        onFocus={() => setFocused(true)}
                        className={
                            shouldFloatLabel
                                ? clsx(styles.floatLabel, floatClass, {
                                      ["fixed-floating-label"]: fixedFloatingLabel,
                                  })
                                : ""
                        }
                    >
                        {children}
                    </div>
                );
            }}
        </Form.Item>
    );
}

export const FloatingLabelWrapper = memo(FloatingLabel);
