import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ManagerCallAction } from "@regal-voice/shared-types";

export const CONFERENCE_PARTICIPANT_STATUS = {
    queued: "queued", // documented in Twilio docs but apparently not used
    initiated: "initiated", // our default status for optimistic/placeholder participants
    ringing: "ringing",
    inProgress: "in-progress",
    canceled: "canceled",
    completed: "completed",
    busy: "busy",
    noAnswer: "no-answer",
    failed: "failed",
} as const;

export type ConferenceParticipantStatus =
    (typeof CONFERENCE_PARTICIPANT_STATUS)[keyof typeof CONFERENCE_PARTICIPANT_STATUS];

// This is Twilio's Conference Participant status, keeping it for reference. It seems like we're using call status instead
// @see {@link https://www.twilio.com/docs/voice/api/conference-participant-resource#participant-properties|Twilio Docs}
//
// status: "queued" | "connecting" | "ringing" | "connected" | "complete" | "failed";

/**
 * This map provides strict types on which attributes earch participant type will have.
 */
type ConferenceParticipantAttributesByType = ConfPartByType<{
    queue: ConferenceParticipantQueueAttributes;
    internal: ConferenceParticipantInternalAttributes;
    contact: ConferenceParticipantContactAttributes;
    external: ConferenceParticipantExternalAttributes;
    "external-phonebook": ConferenceParticipantExternalPhonebookAttributes;
    unknown: ConferenceParticipantAllPartialAttributes;
}>;

type ActualParticipantTypesWithoutFallback = Exclude<ConferenceParticipantType, "unknown">;

/**
 * This type constraint ensures that anyone adding a new type of participant will have to define the right base properties.
 */
type ConfPartByType<
    T extends {
        [K in ActualParticipantTypesWithoutFallback]: { type: K } & ConferenceParticipantBaseAttributes;
    }
> = T;

type ConferenceParticipantBaseAttributes = {
    muted: boolean;
    hold: boolean;
    label: string;
    status: ConferenceParticipantStatus;
    taskSid: string;
};

type ConferenceParticipantJoinableAttributes = {
    managerAction?: ManagerCallAction;
    callSid: string;
    joinedAt?: number;
};

type ConferenceParticipantInternalAttributes = ConferenceParticipantBaseAttributes &
    ConferenceParticipantJoinableAttributes & {
        agentId: number;
        callerUri: string;
        type: "internal";
    };

type ConferenceParticipantContactAttributes = ConferenceParticipantBaseAttributes &
    ConferenceParticipantJoinableAttributes & {
        profileId: number;
        type: "contact";
        phoneNumber: string;
    };

type ConferenceParticipantExternalAttributes = ConferenceParticipantBaseAttributes &
    ConferenceParticipantJoinableAttributes & {
        type: "external";
        phoneNumber: string;
    };

type ConferenceParticipantExternalPhonebookAttributes = ConferenceParticipantBaseAttributes &
    ConferenceParticipantJoinableAttributes & {
        type: "external-phonebook";
        phoneNumber: string;
    };

type ConferenceParticipantQueueAttributes = ConferenceParticipantBaseAttributes & {
    queueId: number;
    type: "queue";
};

/**
 * A fallback type with all partial attributes.
 */
type ConferenceParticipantAllPartialAttributes =
    | ConferenceParticipantContactAttributes
    | ConferenceParticipantInternalAttributes
    | ConferenceParticipantExternalAttributes
    | ConferenceParticipantExternalPhonebookAttributes
    | ConferenceParticipantQueueAttributes;

export type ConferenceParticipantType =
    | "queue"
    | "internal"
    | "contact"
    | "external"
    | "external-phonebook"
    | "unknown";

export type ConferenceParticipant<T extends ConferenceParticipantType = "unknown"> =
    ConferenceParticipantAttributesByType[T];

export type ConferenceSnapshot = {
    conferenceFriendlyId: string;
    /**
     * Kept for backwards compatibility on all actions. Sometimes conference will start without it populated. Later it should always have a value.
     */
    conferenceSid?: string;
    startedAt: number;
    recording: boolean;
    /**
     * This will hold the last updated timestamp for any participant (sequence #).
     */
    lastUpdatedAt: number;
    participants: ConferenceParticipant[];
};

export const __initialConferenceSnapshotState: ConferenceSnapshot = {
    conferenceFriendlyId: "",
    startedAt: 0,
    recording: false,
    lastUpdatedAt: 0,
    participants: [],
};

const conferenceSnapshotSlice = createSlice({
    name: "conferenceSnapshot",
    initialState: __initialConferenceSnapshotState,
    reducers: {
        /**
         * Updates state with the new snapshot only if the lastUpdatedAt timestamp is newer than the current one.
         */
        updateConferenceSnapshot(state, action: PayloadAction<ConferenceSnapshot>) {
            if (!action.payload.lastUpdatedAt) {
                throw new Error("Tried to update conference with no lastUpdatedAt");
            }
            if (state.lastUpdatedAt < action.payload.lastUpdatedAt) {
                return { ...action.payload };
            }
        },
        /**
         * Resets the conference snapshot entirely.
         */
        clearConferenceSnapshot() {
            return __initialConferenceSnapshotState;
        },
        /**
         * Used to optimistically mute/unmute the agent in the conference.
         * Will eventually be replaced by the actual conference snapshot.
         */
        toggleAgentMute(state, action: PayloadAction<{ callerUri: string }>) {
            const agent = state.participants.find(
                (p) => p.type === "internal" && p.callerUri === action.payload.callerUri
            );
            if (!agent) {
                throw new Error("Could not find current agent in conference state snapshot");
            }
            agent.muted = !agent.muted;
        },
        /**
         * Used to optimistically hold/unhold a participant in the conference.
         * Will eventually be replaced by the actual conference snapshot.
         */
        toggleParticipantHold(state, action: PayloadAction<{ callSid: string }>) {
            const participant = state.participants.find((p) => "callSid" in p && p.callSid === action.payload.callSid);
            if (participant) {
                participant.hold = !participant.hold;
            }
        },
        /**
         * Used to optimistically toggle the recording state of the conference.
         * Will eventually be replaced by the actual conference snapshot.
         */
        toggleRecording(state) {
            state.recording = !state.recording;
        },
        /**
         * Used to optimistically drop a participant from the conference.
         * If you're dropping a queue, you should provide the queueId, otherwise the callSid.
         * Will eventually be replaced by the actual conference snapshot.
         */
        dropParticipant(state, action: PayloadAction<{ callSid?: string; queueId?: number }>) {
            if (!action.payload.callSid && !action.payload.queueId) {
                throw new Error("Tried to drop participant without a callSid or queueId");
            }
            if (action.payload.callSid) {
                const participantIndex = state.participants.findIndex(
                    (p) => "callSid" in p && p.callSid === action.payload.callSid
                );
                if (participantIndex !== -1) {
                    state.participants.splice(participantIndex, 1);
                }
            } else if (action.payload.queueId) {
                const participantIndex = state.participants.findIndex(
                    (p) => p.type === "queue" && p.queueId === action.payload.queueId
                );
                if (participantIndex !== -1) {
                    state.participants.splice(participantIndex, 1);
                }
            }
        },
    },
});

export const {
    updateConferenceSnapshot,
    clearConferenceSnapshot,
    toggleAgentMute,
    toggleParticipantHold,
    toggleRecording,
    dropParticipant,
} = conferenceSnapshotSlice.actions;
export default conferenceSnapshotSlice.reducer;
