import { useMemo } from "react";

// eslint-disable-next-line @regal-voice/regal-voice-ui/use-typed-flag-provider
import { useFlags as ldUseFlags } from "launchdarkly-react-client-sdk";

import { FLAGS_DEFAULTS_FORMATTED, FlagTypes } from "Application/thirdParty/launchDarkly";

export const useFlags = (): FlagTypes => {
    const flags = ldUseFlags<FlagTypes>();
    const flagsProxy = useMemo(
        () =>
            new Proxy(flags, {
                get: (target, prop) => {
                    const targetProp = target[prop as keyof FlagTypes];
                    if (typeof targetProp !== "undefined") {
                        return targetProp;
                    }

                    if (prop in FLAGS_DEFAULTS_FORMATTED) {
                        return FLAGS_DEFAULTS_FORMATTED[prop as keyof FlagTypes];
                    }

                    return undefined;
                },
            }),
        [flags]
    );

    return flagsProxy;
};
