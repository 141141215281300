import { RVTask } from "@regal-voice/shared-types";

import { Zone } from "Services/TimeService";

import type { Profile } from "Types/Profile";

export function createContactFromPartial(partialContact: Partial<Profile>): Profile {
    return Object.assign(
        {
            id: "",
            profileId: "",
            contactPhone: "",
            name: "",
            phones: [],
            customProperties: {},
            contactCreatedAt: "",
            rvProperties: {},
            createdAt: "",
            updatedAt: "",
            email: "",
            emails: [],
            homepageUrl: "",
            leadScore: "",
            firstName: "",
            lastName: "",
            optIn: {},
            shortUrl: "",
        },
        partialContact
    );
}

export function createContactFromTask({
    id,
    contactPhone,
    email,
    task,
}: {
    id?: string;
    contactPhone?: string;
    email?: string;
    task?: RVTask;
}): Profile {
    const computedContactPhone = (task?.attributes?.contactPhone || contactPhone) ?? "";
    const computedContactEmail = (task?.attributes?.email || email) ?? "";
    const computedContactId = (task?.attributes?.profileId || id) ?? "";
    return {
        id: computedContactId,
        profileId: computedContactId,
        contactPhone: computedContactPhone,
        phones: computedContactPhone ? [computedContactPhone] : [],
        name: task?.attributes?.name ?? "",
        email: computedContactEmail,
        emails: computedContactEmail ? [computedContactEmail] : [],
        customProperties: {},
        rvProperties: {
            timezone: Zone.et,
        },
        contactCreatedAt: "",
        createdAt: "",
        updatedAt: "",
        homepageUrl: "",
        leadScore: "",
        firstName: "",
        lastName: "",
        optIn: {},
        shortUrl: "",
    };
}

/**
 * Determines if a given string is an actual id or phone number. IDs will range in the 30/40 characters.
 * Simple regexes checking if it has letters and numbers.
 * @param idOrPhone
 */
export function isContactId(idOrPhone?: string): boolean {
    return Boolean(idOrPhone?.length && idOrPhone.length > 20 && /[a-z]/.test(idOrPhone) && /\d/.test(idOrPhone));
}

function mergeObjArr<T extends Record<string, unknown>>(arrOne: T[], arrTwo: T[] | undefined, idKey: keyof T): T[] {
    const result: Record<PropertyKey, T> = {};
    for (const item of arrOne || []) {
        const itemKey = item[idKey] as PropertyKey;
        result[itemKey] = item;
    }

    if (arrTwo) {
        for (const item of arrTwo) {
            const itemKey = item[idKey] as PropertyKey;
            result[itemKey] = { ...result[itemKey], ...item };
        }
    }

    return Object.values(result);
}

export function mergeContact(contact: Profile, partialContact: Partial<Profile>): Profile {
    if (partialContact.phones) {
        partialContact.phones = mergeObjArr(contact.phones || [], partialContact.phones, "phoneNumber");
    }

    if (partialContact.emails) {
        partialContact.emails = mergeObjArr(contact.emails || [], partialContact.emails, "email");
    }

    const contactUpdated = {
        ...contact,
        ...partialContact,
        customProperties: {
            ...contact.customProperties,
            ...partialContact.customProperties,
        },
    };
    return contactUpdated;
}

export function doesContactHaveNoPhone(contact?: Partial<Profile>): boolean {
    return !contact?.contactPhone && !contact?.phones?.length;
}

export function doesContactHaveNoEmail(contact?: Partial<Profile>): boolean {
    return !contact?.email && !contact?.emails?.length;
}
