import { useCallback, useMemo, useState } from "react";

import { Form, Radio, Select, Switch, Tooltip, Typography } from "antd";

import Icon, { IconSizes } from "Components/elements/Icon";
import { Team } from "Types/Team";

import { useShareableContactsTeamData } from "./Hooks/useShareableContactsTeamData";

import type { FormInstance } from "antd";

import styles from "./ShareableContactsFormItems.module.scss";

export const SHARE_TYPE_OPTIONS = [
    ["All", "All Users"],
    ["Teams", "Specific Teams"],
] as const;

export type FormItemProps = {
    form: FormInstance;
    data: unknown;
    isReadOnly: boolean;
};

const SHARE_INFO_TIP =
    "By sharing this list with others, it will appear on their Agent Desktop under “Shared Contact Lists” and apply to contacts they own.";

export const ShareableContactsFormItems = ({ data, form, isReadOnly }: FormItemProps) => {
    const { shareBy } = data as { shareBy: string };
    const [isShareable, setIsShareable] = useState(!!shareBy);
    const { teamsList, loading } = useShareableContactsTeamData();
    const teamOptions = useMemo(() => {
        // we need to type this map even though we shoud know
        // that teamsList is an array of Team objects
        return teamsList.map((team: Team) => ({
            label: team.name,
            value: team.id,
        }));
    }, [teamsList]);

    const updateFormForToggle = useCallback(() => {
        form.setFieldsValue({
            shareBy: undefined,
            sharedTeams: undefined,
        });
    }, [form]);

    const updateFormForSharedBy = useCallback(() => {
        form.setFieldsValue({
            sharedTeams: undefined,
        });
    }, [form]);

    const clickToggle = useCallback(() => {
        const nowIsShareable = !isShareable;
        if (nowIsShareable) {
            form.setFieldsValue({
                shareBy: "All",
            });
        }
        setIsShareable(nowIsShareable);
    }, [form, isShareable]);

    // Conditional Rules
    const shareByRules = useMemo(() => {
        return [
            {
                required: isShareable,
                message: "Shared With is required.",
            },
        ];
    }, [isShareable]);
    const teamSelectionRules = useMemo(() => {
        return [
            {
                required: shareBy === "Teams",
                message: "Team Selection is required.",
            },
        ];
    }, [shareBy]);

    return (
        <div>
            <div className={styles.toggleRow}>
                <div className={styles.toggleInfo}>
                    <Typography.Title level={4}>Share List With Others</Typography.Title>
                    <Tooltip placement="right" title={SHARE_INFO_TIP}>
                        <Icon size={IconSizes.SMALL} icon={"info-circle"}></Icon>
                    </Tooltip>
                </div>
                <Switch
                    data-testid="share-switch"
                    checked={isShareable}
                    onClick={clickToggle}
                    onChange={updateFormForToggle}
                />
            </div>
            {isShareable && (
                <div className={styles.shareRow}>
                    <Typography.Title level={4}>Shared With</Typography.Title>
                    <Form.Item name={"shareBy"} rules={shareByRules}>
                        <Radio.Group className="selection-radio" size="large" onChange={updateFormForSharedBy}>
                            {SHARE_TYPE_OPTIONS.map(([value, label]) => (
                                <Radio key={value} value={value} data-testid={`shared-${value}`}>
                                    {label}
                                </Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                </div>
            )}
            {shareBy === "Teams" && isShareable && (
                <div className={styles.teamRow}>
                    <Typography.Title level={4}>Team Selection</Typography.Title>
                    <Typography.Text>Specify which teams this list will be visible to.</Typography.Text>
                    <Form.Item name="sharedTeams" rules={teamSelectionRules}>
                        <Select
                            size="large"
                            placeholder="Teams"
                            mode="multiple"
                            options={teamOptions}
                            disabled={loading || isReadOnly}
                            data-testid="teams"
                        ></Select>
                    </Form.Item>
                </div>
            )}
        </div>
    );
};
