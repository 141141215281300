import { Select } from "antd";
import { Rule } from "antd/lib/form";
import { NamePath } from "antd/lib/form/interface";
import { LabeledValue } from "antd/lib/select";

import { EnhancedFormItem } from "Components/elements/FloatingLabel/EnhancedFormItem";

export interface SelectOption {
    value: string;
    name: string;
}

export type SelectOptionType = SelectOption | string;

function optionsValue(option: SelectOption | string) {
    if (typeof option === "string") {
        return option;
    }
    return option.value;
}

function optionLabel(option: SelectOption | string) {
    if (typeof option === "string") {
        return option;
    }
    return option.name;
}

export function FormSelect({
    name,
    label,
    options,
    hidden,
    rules,
    dependencies,
    onChange,
    className,
    mode,
    loading,
    defaultValue,
    ...rest
}: {
    name: NamePath;
    label: string;
    options: SelectOptionType[];
    hidden?: boolean;
    rules?: Rule[];
    dependencies?: NamePath[];
    onChange?: (value: any) => void;
    className?: string;
    mode?: "multiple" | "tags";
    loading?: boolean;
    defaultValue?: string | string[] | number | number[] | LabeledValue | LabeledValue[];
}): JSX.Element {
    return (
        <EnhancedFormItem
            className={className || ""}
            name={name}
            label={label}
            hidden={hidden}
            rules={rules}
            dependencies={dependencies}
            {...rest}
        >
            <Select
                defaultValue={defaultValue}
                mode={mode}
                loading={loading}
                getPopupContainer={(trigger) => trigger.parentElement}
                style={{ width: "100%" }}
                onChange={onChange}
                data-testid={`form-select-${name}`}
            >
                {options.map((option) => (
                    <Select.Option
                        key={optionsValue(option)}
                        name={optionsValue(option)}
                        value={optionsValue(option)}
                        data={option}
                    >
                        {optionLabel(option)}
                    </Select.Option>
                ))}
            </Select>
        </EnhancedFormItem>
    );
}
