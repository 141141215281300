import { gql } from "@apollo/client";

// TODO, decide if we can consolidate these mutations
export const updateAgent = gql`
    mutation ($updateUserData: UpdateUserInput!) {
        updateAgent(updateUserData: $updateUserData) {
            name
            status
            twilioStatusChangedAt
            email
            twilioSid
            skills {
                name
            }
        }
    }
`;

export const updateAgentPanel = gql`
    mutation ($updateUserData: UpdateUserInput!) {
        updateAgent(updateUserData: $updateUserData) {
            name
            status
            twilioStatusChangedAt
            email
            twilioSid
            skills {
                name
            }
            teams {
                id
                name
            }
        }
    }
`;
