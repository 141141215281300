import { Button } from "antd";

import styles from "./ErrorMessage.module.scss";

export default function ErrorMessage(): JSX.Element {
    const onReloadLinkClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        window.location.reload();
    };

    return (
        <div data-testid="sse-error-message">
            We&apos;ve encountered an error. Please{" "}
            <Button type="link" className={styles.reloadButton} onClick={onReloadLinkClick}>
                reload
            </Button>{" "}
            the page to receive new tasks (when you are not on a live call).
        </div>
    );
}
