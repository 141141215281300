import { ConditionEvent, DynamicCondition, JoinType, Segment, SegmentType } from "Types/Segment";

import { joinTypeDefault } from "./ConditionsList/constants";

import type { ConditionalOptions } from "./ConditionsList/Types";

const eventDefaults: ConditionEvent = {
    eventOperator: "isSet",
    filters: [{ propertyOperator: "isSet" }],
    lastEventOnly: false,
    conditionalType: "contactAttribute",
};
export const contactAttributeDefaults: DynamicCondition = {
    joinType: JoinType.ALL,
    conditions: [eventDefaults],
};

export const conditionalOptions: ConditionalOptions = {
    dynamicConditions: [contactAttributeDefaults],
    conditionDefaults: contactAttributeDefaults,
    joinType: JoinType.ALL,
    direction: "horizontal",
    conditionalListOptions: {
        hideConditionRemove: true,
    },
    eventListOptions: { hideConditionSelector: true },
};

export const segmentDefaults: Partial<Segment> = {
    type: SegmentType.DYNAMIC,
    conditions: [],
    dynamicConditions: [contactAttributeDefaults],
    joinType: joinTypeDefault,
    journeysToTrigger: [],
};
