import { Menu, Typography } from "antd";
import { clsx } from "clsx";
import { Link } from "react-router-dom";

import { AgentDesktopSubPages } from "Services/state/agent/desktopUI/AgentDesktopUISlice";

import AdMenuStyles from "../../../AgentDesktopSideNav.module.scss";

type SavedViewGroupProps = {
    title: string;
    items: Array<any>;
    pathname: string;
};
export const SavedViewGroup = ({ title, items, pathname }: SavedViewGroupProps) => {
    return (
        <>
            <div className={AdMenuStyles.menuWrap}>
                <Typography.Text className={AdMenuStyles.subNavTitle}>{title}</Typography.Text>
            </div>
            {/* 
                @todo: this should be wrapped in a SubMenu, 
                Probably not worth doing until we are serious
                about moving to antd5.
                - Also we can remove the menuWrap divs but that will require a bunch of CSS changes
            */}
            {items.map((view) => {
                return (
                    <div key={view.id} className={clsx(AdMenuStyles.menuWrap, AdMenuStyles.taskMenuItem)}>
                        <Link to={`/agent/${AgentDesktopSubPages.CONTACT_PAGE}/${view.id}`}>
                            <Menu.Item
                                key={view.id}
                                className={clsx(AdMenuStyles.menuItem, {
                                    [AdMenuStyles.nested]: true,
                                    [AdMenuStyles.selected]:
                                        pathname === `/agent/${AgentDesktopSubPages.CONTACT_PAGE}/${view.id}`,
                                })}
                            >
                                <Typography.Paragraph
                                    className={AdMenuStyles.subNav}
                                    ellipsis={{
                                        rows: 2,
                                        tooltip: { placement: "right", title: view.name },
                                    }}
                                >
                                    {view.name}
                                </Typography.Paragraph>
                            </Menu.Item>
                        </Link>
                    </div>
                );
            })}
        </>
    );
};
