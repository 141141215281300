import { useContext, useEffect, useMemo, useState, useRef, useCallback } from "react";

import { Select, SelectProps } from "antd";
import { assignWith, chain } from "lodash";

import { UserContext } from "App/contexts";
import { VALID_PHONE_CHARACTERS_REGEX, formatPhoneNumber } from "Services/CommunicationService";
import { alphabeticallySortByProperty } from "Services/HelpersService";
import { PhoneEntry } from "Types/Campaign";

import styles from "./SearchableFromNumber.module.scss";

export const dynamicPhoneEntry: PhoneEntry = {
    source: "<dynamic>",
    externalDisplayName: "",
    internalDisplayName: "Set dynamically based on contact profile",
};

const formatInitialValue = (value: string): string => {
    if (value === dynamicPhoneEntry.source) {
        return value;
    }

    return `+${value?.replace(/\D/g, "")}`;
};

interface SearchableFromProps {
    onChange?: (value: string) => void;
    loading?: boolean;
    initialValue?: string;
    allowDynamic?: boolean;
    agentPhoneNumber?: PhoneEntry;
    staticNumbers?: Array<PhoneEntry>;
    size?: "small" | "middle" | "large";
    isFormItem?: boolean;
    value?: string;
}

function getUniquelyMergedNumbers(
    staticNumbers: Array<PhoneEntry>,
    dynamicNumbers: Array<PhoneEntry> = []
): Array<PhoneEntry> {
    return chain([...staticNumbers, ...dynamicNumbers])
        .groupBy("source")
        .map<PhoneEntry>(
            (objs: Array<PhoneEntry>): PhoneEntry =>
                assignWith(
                    {},
                    ...objs,
                    (val1: string | undefined | null, val2: string | undefined | null) => val1 || val2
                )
        )
        .value();
}

/**
 * TODO: This component should be refactored into a pure Form Item implementation.
 * Right now it's in a weird limbo between controlled and uncontrolled.
 */
export function SearchableFromNumber({
    onChange,
    loading = false,
    initialValue = "",
    allowDynamic = false,
    staticNumbers = [],
    size = "large",
    agentPhoneNumber,
    isFormItem = false,
    value,
}: SearchableFromProps): JSX.Element {
    const { brand } = useContext(UserContext);
    const searchableFromNumberWrapper = useRef<HTMLDivElement>(null);

    const [searchedBy, setSearchedBy] = useState<"source" | "internalDisplayName" | undefined>(undefined);
    const [selectValue, setSelectValue] = useState<string>(initialValue ? formatInitialValue(initialValue) : "");
    const valueToUse = isFormItem ? value : selectValue;

    const allNumbers = useMemo(() => {
        const computedStaticNumbers = [...staticNumbers];
        if (agentPhoneNumber) {
            computedStaticNumbers.push(agentPhoneNumber);
        }

        const communicationSources = brand?.communicationSources;

        if (!communicationSources || !communicationSources.length) {
            return computedStaticNumbers;
        }

        return getUniquelyMergedNumbers(computedStaticNumbers, communicationSources).sort(
            alphabeticallySortByProperty("internalDisplayName")
        );
    }, [brand?.communicationSources, staticNumbers, agentPhoneNumber]);

    const filterOption: SelectProps<string>["filterOption"] = (input, option) => {
        if (!option) {
            return false;
        }

        const doSearchBy = VALID_PHONE_CHARACTERS_REGEX.test(input) ? "source" : "internalDisplayName";
        setSearchedBy(doSearchBy);

        if (doSearchBy === "source") {
            return (option.value as string)?.toLocaleLowerCase().includes(input.toLocaleLowerCase().replace(/\D/g, ""));
        }

        if (doSearchBy === "internalDisplayName" && option?.key) {
            return (
                allNumbers[Number(option.key)]?.internalDisplayName
                    ?.toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase()) || false
            );
        }

        return false;
    };

    const onSelect = useCallback(
        (value: string) => {
            setSelectValue(value);
            onChange?.(value);
        },
        [onChange]
    );

    useEffect(() => {
        !selectValue && initialValue && setSelectValue(formatInitialValue(initialValue));
    }, [selectValue]);

    useEffect(() => {
        initialValue && setSelectValue(formatInitialValue(initialValue));
    }, [initialValue]);

    return (
        <div style={{ flex: 1, display: "flex" }} ref={searchableFromNumberWrapper}>
            <Select
                data-testid="searchable-from-number"
                size={size}
                onSelect={onSelect}
                className="flex-spacer"
                popupClassName={styles.selectDropDown}
                showSearch
                value={valueToUse}
                filterOption={filterOption}
                loading={loading}
                getPopupContainer={() => searchableFromNumberWrapper.current as HTMLElement}
                notFoundContent={
                    searchedBy === "source"
                        ? "No matching phone numbers"
                        : "No matching agent. Try phone number instead."
                }
            >
                {allNumbers.map((item: PhoneEntry, index) => (
                    <Select.Option key={index} className={styles.agentFromNumberItem} value={item.source}>
                        <div className="agentFromNumberItemContent">
                            <div className="flex-row justify-between row-spacer">
                                <span>{formatPhoneNumber(item.source)}</span>
                                <span className={styles.internalDisplayName}>{item.internalDisplayName}</span>
                            </div>
                        </div>
                    </Select.Option>
                ))}
                {allowDynamic && (
                    <Select.Option key="dynamic" value={dynamicPhoneEntry.source}>
                        <div className={styles.dynamicPhoneEntry}>
                            <span>{dynamicPhoneEntry.internalDisplayName}</span>
                        </div>
                    </Select.Option>
                )}
            </Select>
        </div>
    );
}
