import { Skeleton } from "antd";

import { LoadingTitleSize } from "./shared";

/**
 *
 * @param size - From the enum LoadingTitleSize; width to render this skeleton
 * @returns JSX.Element (Loading Skeleton)
 */
export function LoadingTitle({
    size = LoadingTitleSize.MEDIUM,
}: {
    size?: LoadingTitleSize | number | string;
}): JSX.Element {
    return <Skeleton active paragraph={false} title={{ width: size }} />;
}
