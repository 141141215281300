import { useCallback, useReducer } from "react";

function init({
    getDataLength,
    canCollapseItem,
}: {
    getDataLength: () => number;
    canCollapseItem: (itemKey: number) => boolean;
}) {
    const validItems = Array.from({ length: getDataLength() }, (_, key) => [key, canCollapseItem(key)]);
    return Object.fromEntries(validItems);
}

type ACTIONTYPE =
    | { type: "toggleItems"; payload: { [groupKey: number]: boolean } }
    | { type: "addItem"; payload: number }
    | { type: "removeItem"; payload: number };

function collapseReducer(state: { [groupKey: number]: boolean }, action: ACTIONTYPE) {
    switch (action.type) {
        case "toggleItems":
            return { ...state, ...action.payload };
        case "addItem":
            return { ...state, [action.payload]: false };
        case "removeItem":
            const entries = Object.entries(state)
                .filter(([key]) => key !== `${action.payload}`)
                .map(([key, value]) => {
                    const keyAsNumber = Number(key);
                    return [keyAsNumber > action.payload ? keyAsNumber - 1 : key, value];
                });
            return Object.fromEntries(entries);
    }
}

export function useCollapsedItems(
    getDataLength: () => number,
    canCollapseItem: (itemKey: number) => boolean
): {
    collapsedItems: Record<number, boolean>;
    collapseItemHandler: (item: { [groupKey: number]: boolean }) => void;
    collapseAllItems: () => void;
    addCollapsedItem: (item: number) => void;
    removeCollapsedItem: (item: number) => void;
} {
    // collapsedItems: Record<string, boolean> where key is item index
    const [collapsedItems, collapseItems] = useReducer(collapseReducer, { getDataLength, canCollapseItem }, init);

    const collapseAllItems = useCallback(() => {
        const validItems = Array.from({ length: getDataLength() }, (_, key) => [key, canCollapseItem(key)]);
        const collapsedItems = Object.fromEntries(validItems);
        collapseItems({ type: "toggleItems", payload: collapsedItems });
    }, [getDataLength, canCollapseItem]);

    const collapseItemHandler = useCallback((item: { [groupKey: number]: boolean }) => {
        collapseItems({ type: "toggleItems", payload: item });
    }, []);

    const addCollapsedItem = useCallback((item: number) => {
        collapseItems({ type: "addItem", payload: item });
    }, []);

    const removeCollapsedItem = useCallback((item: number) => {
        collapseItems({ type: "removeItem", payload: item });
    }, []);

    return { collapsedItems, collapseItemHandler, collapseAllItems, addCollapsedItem, removeCollapsedItem };
}
