import { useEffect, useRef, useState, useMemo } from "react";

import { Popover } from "antd";
import { TooltipPlacement } from "antd/lib/tooltip";
import { clsx } from "clsx";
import {
    Editor,
    EditorState,
    CompositeDecorator,
    ContentState,
    DraftDecorator,
    Modifier,
    DraftHandleValue,
} from "draft-js";
import { debounce, isEqual } from "lodash";

import { getTriggerRange, moveSelectionToEnd } from "../../Shared/Helpers/handlebarHelpers";

import styles from "./AutosuggestEditor.module.scss";
import "draft-js/dist/Draft.css";

export enum Variant {
    Editor = "EDITOR",
    Input = "INPUT",
}

const cleanInputOfNewlines = (text: string): string => text.replace(/\n/g, "");

export function AutosuggestEditor({
    onChange,
    value,
    hasSuggestions,
    decorators,
    trigger,
    renderTagFn,
    onSeachTermChange,
    children,
    variant = Variant.Editor,
    placeholder,
    autofocus,
    hideOverlayArrow,
    sufix,
    popoverPlacement,
    dataTestid,
}: {
    onChange?: any;
    value?: string;
    hasSuggestions: boolean;
    decorators: DraftDecorator[];
    trigger: string;
    renderTagFn: any;
    onSeachTermChange: any;
    children: JSX.Element;
    variant?: Variant;
    placeholder?: string;
    autofocus?: boolean;
    hideOverlayArrow?: boolean;
    sufix?: JSX.Element;
    popoverPlacement?: TooltipPlacement;
    dataTestid?: string;
}): JSX.Element {
    function getBlockStyleFn(): (() => string) | undefined {
        const editorBlockStyleFnUsesDefault = undefined; // Just use the default
        const inputBlockStyleFn: () => string = () => styles.webhookInputData;
        return variant == Variant.Editor ? editorBlockStyleFnUsesDefault : inputBlockStyleFn;
    }
    const blockStyleFn = useMemo(getBlockStyleFn, [variant]);
    function getStyleClasses(): string {
        const editorClasses = `custom-autosuggest-editor ${styles.draftjsEditor} ${styles.webhookEditor}`;
        const inputClasses = `custom-autosuggest-editor ${styles.draftjsEditor} ant-input`;
        return variant == Variant.Editor ? editorClasses : inputClasses;
    }

    function getHandleReturn(): (() => DraftHandleValue) | undefined {
        const editorHandleReturnUsesDefault = undefined; // Just use the default
        const inputHandleReturnDisabled: () => DraftHandleValue = () => "handled"; // disables return
        return variant == Variant.Editor ? editorHandleReturnUsesDefault : inputHandleReturnDisabled;
    }
    const handleReturnFn = useMemo(getHandleReturn, [variant]);

    function getHandlePastedText():
        | ((text: string, html: string | undefined, editorState: EditorState) => DraftHandleValue)
        | undefined {
        const editorPastedTextFnUsesDefault = undefined; // Just use the default
        function inputPastedTextFnReplacesNewLines(
            text: string,
            html: string | undefined,
            editorState: EditorState
        ): DraftHandleValue {
            const replaceNewLines = Modifier.replaceText(
                editorState.getCurrentContent(),
                editorState.getSelection(),
                cleanInputOfNewlines(text)
            );

            onEditorChange(EditorState.push(editorState, replaceNewLines, "insert-characters"));
            return "handled";
        }
        return variant == Variant.Editor ? editorPastedTextFnUsesDefault : inputPastedTextFnReplacesNewLines;
    }
    const handlePastedTextFn = useMemo(getHandlePastedText, [variant]);

    const [autoSuggestState, setAutoSuggestState] = useState({
        editorState: EditorState.createWithContent(
            ContentState.createFromText(value || ""),
            new CompositeDecorator(decorators)
        ),
        autocompleteState: null,
        onLoaditerator: 0,
    });

    useEffect(() => {
        // if value is undefined is not initialized; if contents are equal do not update editor state
        if (value == undefined || autoSuggestState.editorState.getCurrentContent().getPlainText() == value) {
            return;
        }

        // format for specific Variant
        const text = variant == Variant.Input ? cleanInputOfNewlines(value) : value;
        setAutoSuggestState((prevValue) => ({
            ...prevValue,
            editorState: EditorState.createWithContent(
                ContentState.createFromText(text),
                new CompositeDecorator(decorators)
            ),
        }));
    }, [value]);

    const editor = useRef<Editor | null>();

    const focus = () => {
        editor.current?.focus();
    };

    useEffect(() => {
        if (autofocus && value == trigger) {
            // set focus on editor and move selection to end
            editor.current?.focus();
            const editorState = moveSelectionToEnd(autoSuggestState.editorState);
            setAutoSuggestState((prevValue) => ({
                ...prevValue,
                editorState,
            }));
        }
    }, [autofocus, value]);

    const setFormValue = useMemo(
        () =>
            debounce((newValue) => {
                if (!isEqual(value, newValue)) {
                    onChange?.(newValue);
                }
            }, 350),
        // TODO: add onChange here as well. It will require to go a few layers up and memoize the onChange callback
        [value]
    );

    function onEditorChange(editorState: any) {
        const newValue = editorState.getCurrentContent().getPlainText();
        if (!isEqual(value, newValue)) {
            setFormValue(newValue);
        }
        setAutoSuggestState((prevValue) => ({
            ...prevValue,
            editorState,
        }));
    }

    useEffect(() => {
        const triggerRange = getTriggerRange(autoSuggestState.editorState, trigger);
        if (!triggerRange) {
            setAutoSuggestState((prevValue) => ({
                ...prevValue,
                autocompleteState: null,
            }));
            onSeachTermChange({
                searchTerm: undefined,
                renderSuggestion: renderSuggestion,
            });
            return;
        }

        setAutoSuggestState((prevValue) => ({
            ...prevValue,
            autocompleteState: {
                searchText: triggerRange.text.slice(0, triggerRange.text.length),
                selectedIndex: 0,
            } as any,
        }));
        onSeachTermChange({
            searchTerm: triggerRange.text.slice(0, triggerRange.text.length),
            renderSuggestion: renderSuggestion,
        });
    }, [autoSuggestState.editorState]);

    function renderSuggestion(text: string) {
        const { editorState } = autoSuggestState;

        setAutoSuggestState((prevValue) => ({
            ...prevValue,
            editorState: renderTagFn(editorState, text),
            autocompleteState: null,
        }));
    }

    return (
        <div className={getStyleClasses()} onClick={focus} data-testid={dataTestid || "autosuggest-editor-container"}>
            <Popover
                content={<>{children}</>}
                overlayClassName={clsx(styles.autosuggestEditorOverlay, {
                    [styles.dropdownOverlayNoArrow]: hideOverlayArrow,
                })}
                placement={popoverPlacement || "top"}
                open={!!(autoSuggestState?.autocompleteState && hasSuggestions)}
                // I'm not sure if this is a failing of antd's types or why the parent element is optional here.
                // disabling this rule as part of clean up
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                getPopupContainer={(trigger) => trigger.parentElement!}
                autoAdjustOverflow={false}
            >
                <div className={styles.avoidMovingTooltip}>
                    <Editor
                        ref={(node) => (editor.current = node)}
                        editorState={autoSuggestState.editorState}
                        onChange={onEditorChange}
                        placeholder={placeholder}
                        blockStyleFn={blockStyleFn}
                        handleReturn={handleReturnFn}
                        handlePastedText={handlePastedTextFn}
                    />
                    {sufix && <div className={styles.infoIcon}>{sufix}</div>}
                </div>
            </Popover>
        </div>
    );
}
