import { SidenavItems } from "Components/shared/SidenavItems/SidenavItems";
import { AgentDesktopPaths } from "Pages/agentDesktop/paths";
import { AgentSettingsPaths } from "Pages/settings/AgentSettings/Pages/paths";
import { useFlags } from "Services/FeatureFlagService";
import { PageSubNavItem } from "Types/Settings";

export default function SettingsSideNavBar(): JSX.Element {
    const {
        customVoicemailRecordings,
        adminContactAttributes,
        taskPreferencesCancelation,
        showRoutingRules,
        showUserManagement,
        email: emailVersion,
        ivrPage,
        customObjectsV1,
        multipleContactOwners,
        showEventManagement,
        hasExternalTransferPhonebook,
        taskPreferencesByTeam,
        hasPreRecordedVmDrops,
    } = useFlags();

    const showEmail = emailVersion > 0;
    const showAgentSettings = showEmail || hasPreRecordedVmDrops;

    const settings: Array<PageSubNavItem> = [
        { key: "general", title: "General Settings", href: "/general-settings" },
        {
            key: "my-settings",
            title: "My Settings",
            href: AgentDesktopPaths.mySettings,
            children: [
                {
                    key: "my-integrations",
                    title: "Integrations",
                    href: `${AgentDesktopPaths.mySettings}${AgentSettingsPaths.integrations}`,
                    isVisible: showEmail,
                },
                {
                    key: "my-recordings",
                    title: "My Pre-recorded Voicemail Drops",
                    href: `${AgentDesktopPaths.mySettings}${AgentSettingsPaths.recordings}`,
                    isVisible: hasPreRecordedVmDrops,
                },
            ],
            tabs: [
                `${AgentDesktopPaths.mySettings}${AgentSettingsPaths.integrations}`,
                `${AgentDesktopPaths.mySettings}${AgentSettingsPaths.recordings}`,
            ],
            isVisible: showAgentSettings,
        },

        ...(!taskPreferencesByTeam
            ? [
                  {
                      key: "templates",
                      title: "Templates",
                      href: "/templates",
                      tabs: ["/templates", "/templates/new", "/templates/:templateId"],
                  },
              ]
            : [
                  {
                      key: "agentDesktop",
                      title: "Agent Desktop",
                      href: "/task-preferences",
                      children: [
                          {
                              key: "TaskPreferences",
                              title: "Task Preferences",
                              href: "/agent-desktop/task-preferences",
                              tabs: ["/agent-desktop/task-preferences", "/agent-desktop/task-preferences/:id"],
                          },
                          {
                              key: "dispositions",
                              title: "Dispositions",
                              href: "/agent-desktop/dispositions",
                          },
                          {
                              key: "tags",
                              title: "Tags",
                              href: "/agent-desktop/tags",
                          },
                          {
                              key: "cancellationReasons",
                              title: "Cancellation Reasons",
                              href: "/agent-desktop/cancellation-reasons",
                          },
                          {
                              key: "templates",
                              title: "Templates",
                              href: "/agent-desktop/templates",
                              tabs: [
                                  "/agent-desktop/templates",
                                  "/agent-desktop/templates/new",
                                  "/agent-desktop/templates/:templateId",
                              ],
                          },
                      ],
                  },
              ]),
        {
            key: "userManagement",
            title: "User Management",
            href: "/user-management/users",
            isVisible: showUserManagement,
            // show "User Management" as selected if any of
            // these URLs match the current location
            tabs: [
                "/user-management/users",
                "/user-management/users/new",
                "/user-management/users/:userId",
                "/user-management/teams",
                "/user-management/teams/new",
                "/user-management/teams/:teamId",
                "/user-management/skills",
                "/user-management/skills/new",
                "/user-management/skills/:skillId",
            ],
        },
        ...(!taskPreferencesByTeam
            ? [
                  {
                      key: "taskPreferences",
                      title: "Task Preferences",
                      href: "/task-preferences",
                      isVisible: taskPreferencesCancelation,
                  },
              ]
            : []),
        {
            key: "externalTransferPhonebook",
            title: "External Transfer Phonebook",
            href: "/external-transfer-phonebook",
            isVisible: hasExternalTransferPhonebook,
        },
        {
            key: "contactLists",
            title: "Contact Lists",
            href: "/contact-lists",
            isVisible: multipleContactOwners,
        },
        {
            key: "routingRules",
            title: "Task Routing",
            href: "/routing-rules/rules",
            tabs: [
                "/routing-rules/rules",
                "/routing-rules/queues",
                "/routing-rules/queues/new",
                "/routing-rules/queues/:queueId",
                "/routing-rules/attributes",
            ],
            isVisible: showRoutingRules,
        },
        { key: "phoneNumbers", title: "Active Phone Numbers", href: "/phone-numbers" },
        { key: "ivrs", title: "IVRs", href: "/ivrs", isVisible: ivrPage, tabs: ["/ivrs", "/ivrs/new", "/ivrs/:ivrId"] },
        { key: "recordings", title: "Recordings", href: "/recordings", isVisible: customVoicemailRecordings },
        ...(showEventManagement
            ? [
                  {
                      key: "dataManagement",
                      title: "Data Management",
                      href: customObjectsV1 ? "/data-management/object-types" : "/your-data",
                      isVisible: adminContactAttributes || showEventManagement,
                      children: showEventManagement
                          ? [
                                {
                                    key: "objects",
                                    title: "Objects",
                                    href: customObjectsV1 ? "/data-management/object-types" : "/your-data",
                                    tabs: [
                                        "/your-data",
                                        "/data-management/object-types",
                                        "/data-management/object-types/new",
                                        "/data-management/object-types/:objectType",
                                    ],
                                    isVisible: adminContactAttributes,
                                },
                                {
                                    key: "events",
                                    title: "Events",
                                    href: "/data-management/events",
                                    tabs: ["/data-management/events/:eventType/:eventName"],
                                    isVisible: showEventManagement,
                                },
                            ]
                          : [],
                  },
              ]
            : [
                  {
                      key: "dataManagement",
                      title: "Data Management",
                      href: customObjectsV1 ? "/data-management/object-types" : "/your-data",
                      tabs: [
                          "/your-data",
                          "/data-management/object-types",
                          "/data-management/object-types/new",
                          "/data-management/object-types/:objectType",
                      ],
                      isVisible: adminContactAttributes,
                  },
              ]),
        { key: "integrations", title: "Integrations", href: "/integrations" },
    ];

    return <SidenavItems sidenavTitle="Settings" items={settings} basePath="/settings" />;
}
