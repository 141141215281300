import { combineReducers } from "redux";

import selectedContactReducer from "./ActiveContactStateProvider";
import contactsReducer from "./ContactsStateProvider";

export type ContactsState = {
    activeContact: ReturnType<typeof selectedContactReducer>;
    contacts: ReturnType<typeof contactsReducer>;
};

export default combineReducers<ContactsState>({
    activeContact: selectedContactReducer,
    contacts: contactsReducer,
});
