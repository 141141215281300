import { useCallback, useState } from "react";
import type { RefObject } from "react";

import { Popover } from "antd";

import useEventListener from "Utils/useEventListener/useEventListener";

type HookProps = {
    popoverRef: RefObject<typeof Popover>;
    visibilitySideEffect: () => void;
};

// This is just a simple hook that gives us a bit more control over the popover's lifecycle.
// it could be extended and made more fault tolerant if we wanted to use it in more places and more complex ways.
export const usePopoverVisibilityManager = ({ popoverRef, visibilitySideEffect }: HookProps) => {
    // antd doesnt expose these props, so we have to track them ourselves
    const [isPopoverVisible, setIsPopoverVisible] = useState(false);
    const [finishedOpening, setFinishedOpening] = useState(false);

    // @ts-expect-error - update the type of the ref
    const popoverEl = popoverRef.current?.getComponent().ref?.current?.getElement();

    const handlePopoverVisibilityChange = useCallback(
        (visible: boolean) => {
            if (visible) {
                visibilitySideEffect();
            }
            setIsPopoverVisible(visible);
        },
        [visibilitySideEffect]
    );

    const handleAnimationEnd = useCallback(() => {
        if (isPopoverVisible) {
            setFinishedOpening(true);
        } else if (!isPopoverVisible) {
            setFinishedOpening(false);
        }
    }, [isPopoverVisible]);
    useEventListener("animationend", handleAnimationEnd, popoverEl);

    return {
        isPopoverVisible,
        finishedOpening,
        handlePopoverVisibilityChange,
    };
};
