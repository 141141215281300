import { combineReducers } from "redux";

import conversationsReducer from "./ConversationsStateProvider";
import unreadConversationsReducer from "./UnreadConversationsSlice";

export type ConversationState = {
    activeConversations: ReturnType<typeof conversationsReducer>;
    unreadConversations: ReturnType<typeof unreadConversationsReducer>;
};

export default combineReducers<ConversationState>({
    activeConversations: conversationsReducer,
    unreadConversations: unreadConversationsReducer,
});
