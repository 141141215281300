import { useState, useCallback } from "react";

import { RVButton } from "Components/elements/RVButton/RVButton";
import SearchableDialPad from "Components/shared/SearchableDialPad/SearchableDialPad";
import { useFlags } from "Services/FeatureFlagService";
import useEventListener from "Utils/useEventListener/useEventListener";

import { AgentStats } from "./AgentStats/AgentStats";

import styles from "./AgentNavBarButtons.module.scss";
export default function AgentNavBarButtons(): JSX.Element {
    const [searchableDialPadVisible, setSearchableDialPadVisible] = useState<boolean>(false);
    const { showAgentDesktopStats, showAgentDesktopStatsAdvanced } = useFlags();

    const [defaultPhone, setDefaultPhone] = useState<string>();

    const openDialpad = useCallback(() => {
        setSearchableDialPadVisible(true);
    }, []);

    const iframeMessageHandler = useCallback((event: MessageEvent) => {
        if (event.data && event.data.source === "salesforce") {
            const action = event.data.action;
            const phone = event.data.phoneNumber;
            if (action === "open-dialer" && phone) {
                // resets the dialpad when new messages are sent with different phone numbers
                setSearchableDialPadVisible(false);
                setDefaultPhone(phone);
                setSearchableDialPadVisible(true);
            }
        }
    }, []);

    useEventListener("message", iframeMessageHandler);

    return (
        <>
            <span className={styles.buttonContainer}>
                <RVButton data-testid="dialpad-button" type="primary" shape="circle" icon="add" onClick={openDialpad} />
                {(showAgentDesktopStats || showAgentDesktopStatsAdvanced) && (
                    <AgentStats showAdvancedStats={showAgentDesktopStatsAdvanced} />
                )}
            </span>
            {searchableDialPadVisible && (
                <SearchableDialPad
                    onCancel={() => setSearchableDialPadVisible(false)}
                    defaultSearchInput={defaultPhone}
                />
            )}
        </>
    );
}
