import { gql } from "@apollo/client";

export const createContactListMutation = gql`
    mutation CreateContactList($createSavedViewInput: CreateSavedViewInput!) {
        createSavedView(createSavedViewInput: $createSavedViewInput) {
            name
            page
            pageQuery
            id
        }
    }
`;

export const updateContactListMutation = gql`
    mutation UpdateContactList($updateSavedViewInput: UpdateSavedViewInput!) {
        updateSavedView(updateSavedViewInput: $updateSavedViewInput) {
            id
            name
            page
            pageQuery
            isDefault
        }
    }
`;

export const deleteContactListMutation = gql`
    mutation DeleteContactList($id: String!) {
        deleteSavedView(id: $id) {
            id
        }
    }
`;
