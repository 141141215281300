import { useEffect } from "react";

import { useQuery } from "@apollo/client";
import { Typography } from "antd";
import { useSelector } from "react-redux";

import { selectBusinessHours } from "Services/state/brand";

import { GET_AGENT_TASK_STATS } from "../../../queries";
import { totalCount } from "../../../utils";
import { StatPanel } from "../StatPanel";
import { CompletedTaskTotals } from "./Charts/CompletedTaskTotals";
import { TasksPerHour } from "./Charts/TasksPerHour";

import type { GetAgentStatsState } from "../../../queries";

import styles from "./AdvancedTotals.module.scss";

type StatProps = {
    shouldRefetch: boolean;
};

type DisplayProps = {
    loading: boolean;
    count: number;
};

export const StatsError = (): JSX.Element => {
    return <Typography.Text>There was an error loading the stats.</Typography.Text>;
};

export const StatsDisplay = ({ loading, count }: DisplayProps): JSX.Element => {
    const businessHours = useSelector(selectBusinessHours);
    return (
        <div className={styles.advancedDisplay}>
            <CompletedTaskTotals loading={loading} count={count}></CompletedTaskTotals>
            <TasksPerHour loading={loading} count={count} businessHours={businessHours}></TasksPerHour>
        </div>
    );
};

export const AdvancedTotals = ({ shouldRefetch }: StatProps): JSX.Element => {
    const { data, loading, error, refetch } = useQuery<GetAgentStatsState>(GET_AGENT_TASK_STATS, {});
    useEffect(() => {
        if (shouldRefetch) {
            refetch();
        }
    }, [shouldRefetch, refetch]);

    const total = totalCount(data);

    return (
        <StatPanel>{error ? <StatsError /> : <StatsDisplay loading={loading} count={total}></StatsDisplay>}</StatPanel>
    );
};
