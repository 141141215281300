import { ReactNode, Fragment } from "react";

import { Form } from "antd";
import { FormListFieldData, FormListProps } from "antd/lib/form/FormList";
import { NamePath } from "antd/lib/form/interface";

import { Column } from "Components/shared/Flexbox";
import { JoinType } from "Types/Segment";

import { AddButton } from "./Buttons";
import { JoinSelect, JoinSelectProps } from "./JoinSelect";
import { getGapSize, joinTypeToString } from "./Utils";

import type { LayoutDirection, ListOptions } from "../Types";

type FormListValidatorRule = FormListProps["rules"];

export function FormList({
    name,
    rules,
    direction,
    joinType,
    itemDefaults,
    joinSelectProps,
    listOptions,
    addItem,
    children,
}: {
    name: NamePath;
    rules?: FormListValidatorRule;
    direction: LayoutDirection;
    joinType: JoinType;
    itemDefaults: any;
    joinSelectProps: JoinSelectProps;
    listOptions?: ListOptions;
    addItem?: (index: number) => void;
    children: (
        field: FormListFieldData,
        remove: (index: number | number[]) => void,
        index: number,
        fieldsLength: number,
        errors: any
    ) => ReactNode;
}): JSX.Element {
    const joinLabel = joinTypeToString(joinType);
    return (
        <Column gap={getGapSize(direction)}>
            {!listOptions?.hideJoinSelect && <JoinSelect {...joinSelectProps} />}

            <Form.List name={name} rules={rules}>
                {(fields, { add, remove }, { errors }) => (
                    <Column gap={getGapSize(direction)}>
                        {fields.map((field, index) => (
                            <Fragment key={field.name}>
                                {children(field, remove, index, fields.length, errors)}
                            </Fragment>
                        ))}
                        {!listOptions?.hideAddItemButton && (
                            <AddButton
                                label={joinLabel}
                                onClick={() => {
                                    add(itemDefaults);
                                    addItem && addItem(fields.length);
                                }}
                            />
                        )}
                    </Column>
                )}
            </Form.List>
        </Column>
    );
}
