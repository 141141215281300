import { clsx } from "clsx";

import styles from "./Loading.module.scss";

export function Loading({
    text = "Loading your content...",
    small,
}: {
    text?: string | null;
    small?: boolean;
}): JSX.Element {
    return (
        <>
            <div className={clsx(styles.wrapper, { [styles.small]: small || !text })}>
                <div className={styles.inner}>
                    <div className={styles.first}></div>
                    <div className={styles.second}></div>
                    <div className={styles.third}></div>
                </div>
                {text && <div className={clsx(styles.loadingText, { [styles.smallText]: small })}>{text}</div>}
            </div>
        </>
    );
}
