import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { memoize } from "lodash";

import { RootReducerShape } from "../Storage";

type JourneyNote = {
    [noteId: string]: string;
};

type JourneysNotesState = {
    [journeyId: string]: JourneyNote;
};

export const journeysNotesSlice = createSlice({
    name: "journeysNotes",
    initialState: {} as JourneysNotesState,
    reducers: {
        updateNote: (
            state: JourneysNotesState,
            action: PayloadAction<{ journeyId: string; id: string; note?: string }>
        ) => {
            const { journeyId, id, note } = action.payload;
            if (note != undefined) {
                if (!state[journeyId]) {
                    state[journeyId] = {};
                }
                state[journeyId][id] = note;
            } else {
                delete state[journeyId][id];
            }
        },
        clearEditedNotes(state: JourneysNotesState, action: PayloadAction<{ journeyId: string }>) {
            const { journeyId } = action.payload;
            delete state[journeyId];
        },
    },
});

export const { updateNote, clearEditedNotes } = journeysNotesSlice.actions;
export const journeyNotesReducer = journeysNotesSlice.reducer;

const selectNotes = (state: RootReducerShape): JourneysNotesState => state.journey.journeysNotes;

export const selectJourneyNote = createSelector(selectNotes, (journeysNotes) =>
    memoize((journeyId) => journeysNotes[journeyId] || {})
);
