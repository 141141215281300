/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useEffect, useState } from "react";

import useScript from "@charlietango/use-script";
import { datadogLogs, StatusType } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import { loadIntercom, updateIntercom } from "next-intercom";

import { useWaitFor } from "Hooks/useWaitFor";
import { useUserInfo } from "Services/AuthService";
import { useFlags } from "Services/FeatureFlagService";
import { getLogger } from "Services/LoggingService";
import { ZendeskAPI, ZendeskSettings } from "Types/Zendesk";

const logger = getLogger("ThirdPartyServices");

export function useIntercom({ appId, sessionId }: { appId?: string; sessionId?: string }): void {
    if (appId) {
        const userInfo = useUserInfo();
        useEffect(() => {
            loadIntercom({
                appId,
                email: userInfo?.email,
                name: userInfo?.name,
                ssr: false,
                initWindow: true,
                delay: 0,
            });
        }, []);

        useEffect(() => {
            updateIntercom("update", {
                email: userInfo?.email,
                name: userInfo?.name,
                brand: userInfo?.brand,
                ddSessionId: sessionId,
            });
        }, [userInfo?.name, userInfo?.email, userInfo?.brand, sessionId]);
    }
}
/**
 * Options for {@link useZendesk}.
 */
type UseZendeskOptions = {
    /**
     * The Zendesk key for the widget. Obtained from the Agent Dashboard.
     */
    key?: string;
};

export function useZendesk({ key }: UseZendeskOptions): void {
    if (!key) {
        logger.error('Missing "key" for Zendesk widget.');
        return;
    }

    /**
     * The flow is kind of tricky to follow, because of how the Zendesk widget works, plus the use
     * of the feature flag. Here's the gist of it:
     *
     * 1. First, we check if the feature flag is enabled. If it's not, we don't do anything.
     * 2. With the FF enabled, we now define the `zESettings` global variable, which is used to
     *    customize the widget, and it MUST be present before the script is loaded. This happens
     *    in the first `useEffect`.
     * 3. Once the global variable is in place, we flip the `settingsReady` flag, which triggers
     *    the creation of the snippet URL, which fires the script request.
     * 4. After the script is loaded, the second `useEffect`, we use `useWaitFor` to wait for the
     *    global variable function `zE` to be present, as that's the function we use to interact
     *    with the widget.
     * 5. Finally, the second `useEffect` uses the `zE` function to identify the user and pre-fill
     *    its information in the widget forms.
     */

    const hasDOM = typeof window !== "undefined";
    const [settingsReady, setSettingsReady] = useState(false);
    const { showZendeskWidget } = useFlags();
    const userInfo = useUserInfo();
    // If the URL is an empty string, the hook will "skip" its functionality.
    const scriptUrl =
        !hasDOM || !showZendeskWidget || !settingsReady ? "" : `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
    const [scriptReady] = useScript(scriptUrl, {
        attributes: {
            id: "ze-snippet",
        },
    });
    /**
     * When the snippet first loads, the `zE` function is like a placeholder, and calling commands
     * on it won't do anything; probably a bug on their side. After a few ms, the function is
     * replaced with the real one, and the only way to identify them is by checking their length:
     * The "placeholder" function just pushes the parameters into an array, while the real one
     * has "the logic".
     */
    const getWidget = useCallback(() => (window.zE && window.zE.toString().length > 60 ? window.zE : undefined), []);
    const widget = useWaitFor<ZendeskAPI>({
        fn: getWidget,
        skip: !scriptReady,
    });

    useEffect(() => {
        if (!showZendeskWidget || !hasDOM) {
            return;
        }

        window.zESettings = {
            webWidget: <ZendeskSettings>{
                position: {
                    horizontal: "left",
                    vertical: "bottom",
                },
                offset: {
                    vertical: "80px",
                },
            },
        };
        setSettingsReady(true);
        // We only care about the status of the flag.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showZendeskWidget]);

    useEffect(() => {
        if (!widget || !userInfo) {
            return;
        }

        widget("webWidget", "identify", {
            name: userInfo.name,
            email: userInfo.email,
        });
        widget("webWidget", "prefill", {
            name: {
                value: userInfo.name,
            },
            email: {
                value: userInfo.email,
            },
        });
        // We only care about the global variable and the user information.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [widget, userInfo]);
}

export function useDatadog({
    applicationId,
    clientToken,
    env,
    version,
    appInstanceId,
    allowedTracingOrigins,
    isIframed,
}: {
    applicationId?: string;
    clientToken?: string;
    env?: string;
    version?: string;
    appInstanceId?: string;
    allowedTracingOrigins?: string;
    isIframed: boolean;
}): { sessionId: string | undefined } {
    const [sessionId, setSessionId] = useState<string | undefined>();
    if (clientToken && applicationId && env) {
        const userInfo = useUserInfo();
        const {
            sessionReplayRecording,
            sessionReplaySampleRate,
            dataDogRum,
            dataDogRumSampleRate,
            dataDogBrowserLogs,
            dataDogBrowserLogsLevel,
            dataDogBrowserLogsSampleRate,
        } = useFlags();
        const service = `rv-${env}-ui`;
        useEffect(() => {
            if (dataDogRum) {
                datadogRum.init({
                    applicationId,
                    clientToken,
                    env,
                    version,
                    site: "datadoghq.com",
                    service,
                    sampleRate: dataDogRumSampleRate,
                    replaySampleRate: sessionReplaySampleRate,
                    trackInteractions: true,
                    allowedTracingOrigins: allowedTracingOrigins ? [new RegExp(allowedTracingOrigins)] : undefined,
                    silentMultipleInit: true,
                    enableExperimentalFeatures: ["feature_flags"],
                    storeContextsAcrossPages: isIframed,
                    allowFallbackToLocalStorage: isIframed,
                });
                datadogRum.addRumGlobalContext("screenSize", `${window.screen.width}x${window.screen.height}`);
                setSessionId((window as any).DD_RUM?.getInternalContext()?.session_id);
            }

            if (userInfo?.sub && dataDogBrowserLogs) {
                datadogLogs.init({
                    clientToken,
                    env,
                    version,
                    service,
                    site: "datadoghq.com",
                    forwardReports: "all",
                    sampleRate: dataDogBrowserLogsSampleRate,
                    silentMultipleInit: true,
                });
                datadogLogs.logger.setLevel(dataDogBrowserLogsLevel as StatusType);
            }
        }, [
            userInfo?.sub,
            sessionReplaySampleRate,
            dataDogRum,
            dataDogRumSampleRate,
            dataDogBrowserLogs,
            dataDogBrowserLogsLevel,
            dataDogBrowserLogsSampleRate,
        ]);

        useEffect(() => {
            const email = userInfo?.email?.replace(/^(.{3}).*(.{2})@(.+)$/, "$1***$2@$3");
            datadogRum.addRumGlobalContext("brand", userInfo?.brand);
            datadogRum.addRumGlobalContext("email", email);
            datadogRum.setUser({
                id: userInfo?.sub,
                email,
                brand: userInfo?.brand,
            });

            datadogLogs.addLoggerGlobalContext("brand", userInfo?.brand);
            datadogLogs.addLoggerGlobalContext("email", email);

            // only start session replay recording when user is logged in
            if (userInfo?.sub && sessionReplayRecording) {
                datadogRum.startSessionReplayRecording();
            }
        }, [userInfo?.sub, sessionReplayRecording]);

        useEffect(() => {
            datadogRum.addRumGlobalContext("appInstanceId", appInstanceId);
        }, [appInstanceId]);
    }

    return { sessionId };
}
