import { useMemo } from "react";

import { useQuery } from "@apollo/client";
import { partition } from "lodash";

import { renderErrorMessage } from "Services/LoggingService";

import { SAVED_VIEW_NAMESPACE } from "../constants";
import { getSidePanelContactViewsQuery } from "../queries";

import type { ContactListPanelSavedView } from "../queries";

export const useSavedViewsContactListsData = () => {
    const { data, refetch } = useQuery<{ getAllSavedViewsForPage: { items: Array<ContactListPanelSavedView> } }>(
        getSidePanelContactViewsQuery,
        {
            fetchPolicy: "cache-and-network",
            variables: { savedViewsQueryInput: { page: SAVED_VIEW_NAMESPACE } },
            onError(error) {
                renderErrorMessage({
                    content: "Couldn't retrieve contact views.",
                    error,
                    loggerContext: "query GetContactViews",
                });
            },
        }
    );

    const { privateViews, sharedViews } = useMemo(() => {
        const filtered = partition(
            data?.getAllSavedViewsForPage?.items,
            (item: ContactListPanelSavedView) => item.shareWithAllUsers === true || (item.teams?.length ?? 0) > 0
        );

        const sharedViews = Array.from((filtered[0] as ContactListPanelSavedView[]) || []).sort((a, b) => {
            return a.name > b.name ? 1 : -1;
        });

        const privateViews = Array.from((filtered[1] as ContactListPanelSavedView[]) || []).sort((a, b) => {
            return a.name > b.name ? 1 : -1;
        });

        return { privateViews, sharedViews };
    }, [data?.getAllSavedViewsForPage?.items]);

    return { privateViews, sharedViews, refetch };
};
