import { useCallback, useContext, useState, useMemo } from "react";

import { Form, Input, Modal, Typography } from "antd";
import { useHistory } from "react-router";

import { UserContext } from "App/contexts";
import { RVButton } from "Components/elements/RVButton/RVButton";
import { Column } from "Components/shared/Flexbox";
import {
    useValidation,
    ValidationContextProvider,
    withValidationContext,
} from "Components/shared/FormValidationContext";
import { ConditionsList } from "Pages/segments/Pages/SegmentEditPage/ConditionsList/ConditionsList";
import { contactAttributeDefaults } from "Pages/segments/Pages/SegmentEditPage/constants";
import { getConditionalTypes } from "Pages/segments/Pages/SegmentEditPage/utils";
import { userHasRoles, useUserInfo } from "Services/AuthService";
import { useFlags } from "Services/FeatureFlagService";
import { relativeDisplay } from "Services/TimeService";
import { UserRole } from "Types/Auth";
import { ConditionList, JoinType } from "Types/Segment";

import { ShareableContactsFormItems } from "./Components/ShareableContactsFormItems/ShareableContactsFormItems";
import { useNewContactListMutation } from "./Hooks/useNewContactListMutation";

import type { ConditionalOptions } from "Pages/segments/Pages/SegmentEditPage/ConditionsList/Types";
import type { Segment } from "Types/Segment";

import styles from "./NewContactListModal.module.scss";

type ModalFooterProps = {
    isDisabled: boolean;
    isUpdating: boolean;
    onDelete: () => void;
    deleteDisabled: boolean;
    onClose: () => void;
    onSave: () => void;
};

const ModalFooter = ({ isUpdating, onDelete, deleteDisabled, onClose, onSave, isDisabled }: ModalFooterProps) => {
    return (
        <div className={styles.modalFooter}>
            <RVButton onClick={onDelete} danger disabled={deleteDisabled || isDisabled}>
                Delete
            </RVButton>
            <span className={styles.footerButtonGroup}>
                <RVButton className={styles.basicButton} onClick={onClose} disabled={isDisabled}>
                    Cancel
                </RVButton>
                <RVButton onClick={onSave} type="primary" loading={isUpdating} disabled={isDisabled}>
                    Save
                </RVButton>
            </span>
        </div>
    );
};

export interface ContactFiltersFormProps extends ConditionList {
    name: string;
}

export const EMPTY_SEGMENT_VALUE: ContactFiltersFormProps = {
    name: "",
    dynamicConditions: [
        {
            joinType: JoinType.ANY,
            conditions: [
                {
                    eventOperator: "isSet",
                    filters: [{ propertyOperator: "isSet" }],
                    lastEventOnly: false,
                    conditionalType: "contactAttribute",
                },
            ],
        },
    ],
    joinType: JoinType.ANY,
};

const conditionalOptions: ConditionalOptions = {
    dynamicConditions: [contactAttributeDefaults],
    conditionDefaults: contactAttributeDefaults,
    joinType: JoinType.ALL,
    direction: "vertical",
    conditionalListOptions: {
        hideConditionRemove: true,
        hideJoinSelect: true,
        hideAddItemButton: true,
        // We can't hide the header because it hide the whole form
        // we when we go to edit and there is only one condition
        hideConditionHeader: true,
    },
    eventListOptions: { hideConditionSelector: true },
};
type AuditInfo = {
    name: string;
    id: string;
    timeStamp: string;
};

type NewContactListModalProps = {
    disallowedNames: string[];
    initialFormData: ContactFiltersFormProps;
    id?: string;
    open: boolean;
    onHide: () => void;
    refetch: () => void;
    createdInfo?: AuditInfo;
    updatedInfo?: AuditInfo;
};

function NewContactListModal({
    disallowedNames,
    initialFormData,
    id,
    open,
    onHide,
    refetch,
    createdInfo,
    updatedInfo,
}: NewContactListModalProps): JSX.Element {
    const { push: navigate } = useHistory();
    const { segmentConditionsAllowCustomEvent, segmentConditionsAllowRegalEvent, canShareContactLists } = useFlags();
    const [form] = Form.useForm();

    const { user } = useContext(UserContext);

    const isAdmin = useMemo(() => {
        return userHasRoles(user, [UserRole.ADMIN, UserRole.SUPERVISOR]);
    }, [user]);

    const userInfo = useUserInfo();

    const isNew = !createdInfo?.id;
    const canEdit = createdInfo?.id == userInfo?.sub || isAdmin;
    const readOnly = isNew ? false : !canEdit;
    // it how it works in segments, so I didn't mess with it
    const [formValues, setFormValues] = useState<ContactFiltersFormProps>(initialFormData);

    const { isMutatingData, onFinish, onDelete } = useNewContactListMutation({ id, refetch, navigate });
    const { validationEnabled, enableValidation } = useValidation();

    const name = initialFormData.name || "";
    const isNewList = !id;
    const titleText = isNewList ? "New Contact List" : `Edit ${name}`;

    const handleDelete = useCallback(async () => {
        if (isNewList) {
            return;
        }
        await onDelete(id);
        onHide();
    }, [isNewList, onDelete, id, onHide]);

    const handleSubmit = useCallback(() => {
        enableValidation(true);
        form.validateFields().then(() => {
            form.submit();
        });
    }, [enableValidation, form]);

    const handleValuesChange = useCallback(
        (_changedValues: any, allValues: Segment) => {
            // validate only at save - set validationEnabled to false in ConditionalListContextProvider
            enableValidation(false);
            setFormValues(allValues);
        },
        [enableValidation]
    );

    const nameValidation = useCallback(
        async (rule: unknown, value: string) => {
            if (!validationEnabled) {
                return Promise.resolve();
            }
            const nameTest = value.trim();
            if (nameTest.length === 0) {
                return Promise.reject("Name is required");
            }
            if (disallowedNames.includes(nameTest) && value !== name) {
                return Promise.reject("Name is already in use");
            }
        },
        [disallowedNames, name, validationEnabled]
    );

    const handleHide = useCallback(() => {
        /*
         what seems to happen is that the onValuesChange does NOT get invoked for form.resetFields() but form.setFieldsValue does
         if it did then the handleValuesChange would have taken care of mirroring the internal form state onto our internal state
         as is what is expected intuitively, but again it does not
          - this is as deep as I got https://github.com/react-component/field-form/blob/983df60ce75145e300dbe9df3d99f978f8adeb76/src/Form.tsx#L30
        */
        form.setFieldsValue(initialFormData);
        onHide();
    }, [form, initialFormData, onHide]);

    const nameRules = [
        {
            validator: nameValidation,
        },
    ];

    // this shouldn't be necessary, but the way that we are double tracking state
    // is messing something up, so we need to use our proxy state to get the values
    // and not the form state.
    const handleFinish = useCallback(() => {
        const formData: Segment = {
            ...form.getFieldsValue(),
        };
        onFinish(formData);
        onHide();
    }, [form, onFinish, onHide]);

    return (
        <Modal
            open={open}
            title={
                <Typography.Text className={styles.modalTitle} ellipsis>
                    {titleText}
                </Typography.Text>
            }
            footer={
                <ModalFooter
                    isDisabled={readOnly}
                    deleteDisabled={isNewList}
                    onSave={handleSubmit}
                    onDelete={handleDelete}
                    onClose={handleHide}
                    isUpdating={isMutatingData}
                />
            }
            onCancel={handleHide}
            destroyOnClose={true}
        >
            <>
                <Typography.Text className={styles.formTitle}>Contact List Name</Typography.Text>
                <Form
                    form={form}
                    initialValues={formValues}
                    onValuesChange={handleValuesChange}
                    onFinish={handleFinish}
                    disabled={readOnly}
                >
                    <div className={styles.nameInputWrap}>
                        <Form.Item name={"name"} rules={nameRules}>
                            <Input className={styles.input} placeholder="Contact List Name" />
                        </Form.Item>
                    </div>
                    {canShareContactLists && (
                        <ShareableContactsFormItems form={form} data={formValues} isReadOnly={readOnly} />
                    )}
                    <Typography.Text className={styles.formHeader}>List Conditions</Typography.Text>
                    <ValidationContextProvider validationEnabled={validationEnabled}>
                        <Column gap={4}>
                            <ConditionsList
                                form={form}
                                data={formValues}
                                conditionalTypes={getConditionalTypes(
                                    segmentConditionsAllowCustomEvent,
                                    segmentConditionsAllowRegalEvent
                                )}
                                conditionalOptions={conditionalOptions}
                                validationEnabled={validationEnabled}
                                autoCollapse={false}
                            />
                        </Column>
                    </ValidationContextProvider>
                </Form>
                <Typography.Text className={styles.footnote}>Max 500 contacts displayed per list.</Typography.Text>
                {createdInfo && (
                    <Typography.Text className={styles.auditInfo}>
                        Created by {createdInfo.name} {relativeDisplay(createdInfo.timeStamp)}
                    </Typography.Text>
                )}
                {updatedInfo && (
                    <Typography.Text className={styles.auditInfo}>
                        Updated by {updatedInfo?.name} {relativeDisplay(updatedInfo.timeStamp)}
                    </Typography.Text>
                )}
            </>
        </Modal>
    );
}

function wrappedToEnsureInternalStateClearedOnClose(props: NewContactListModalProps): JSX.Element | null {
    // wraps and destroys the component when not visible to ensure we reset internal state
    if (props.open) {
        return <NewContactListModal {...props} />;
    }
    return null;
}

export default withValidationContext<NewContactListModalProps>(wrappedToEnsureInternalStateClearedOnClose);
