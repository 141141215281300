import { Fragment, useMemo } from "react";

import { FormInstance } from "antd";

import { useValidation, withValidationContext } from "Components/shared/FormValidationContext";
import { JoinType, ConditionList, DynamicCondition, NamedConditionalTypes } from "Types/Segment";

import { EventList } from "./EventList";
import { CollapsePanel } from "./Shared/CollapsePanel/CollapsePanel";
import { ConditionsListContextProvider } from "./Shared/ConditionsListContext";
import { FormList } from "./Shared/FormList";
import { JoinLabel } from "./Shared/JoinLabel";
import { useCollapsedItems } from "./Shared/useCollapsedItems";
import { isValidEventGroup, isValidConditionGroup, joinTypeToString } from "./Shared/Utils";

import type { ConditionalOptions } from "./Types";

const joinSelectProps = {
    name: "joinType",
    prefix: "Contacts have",
    sufix: "of the following:",
};

function getDynamicConditions(
    data: ConditionList | Record<string, any>,
    conditionalOptions: ConditionalOptions,
    fieldName: string
): DynamicCondition[] {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (data[fieldName] as unknown as DynamicCondition[]) || conditionalOptions.dynamicConditions;
}

export interface ConditionsListParams {
    form: FormInstance;
    data: ConditionList;
    fieldName?: string;
    conditionalTypes: NamedConditionalTypes;
    conditionalOptions: ConditionalOptions;
    autoCollapse?: boolean;
}

function Conditions({
    form,
    data,
    fieldName = "dynamicConditions",
    conditionalTypes,
    conditionalOptions,
    autoCollapse = true,
}: ConditionsListParams): JSX.Element {
    const { validationEnabled } = useValidation();
    const dynamicConditions = getDynamicConditions(data, conditionalOptions, fieldName);
    const joinType = data.joinType || conditionalOptions.joinType;

    function getDataLength() {
        return dynamicConditions.length;
    }
    function canCollapseItem(itemIndex: number) {
        // set initial value to false when collapse icon/btn is not visible
        return autoCollapse && isValidConditionGroup(dynamicConditions[itemIndex]);
    }

    const { collapsedItems, collapseItemHandler, collapseAllItems, addCollapsedItem, removeCollapsedItem } =
        useCollapsedItems(getDataLength, canCollapseItem);

    function handleChangeCollapse(panelIndex: number) {
        return async function changeCollapse(_key: string | string[]) {
            const collapse = !collapsedItems[panelIndex];
            collapseItemHandler({ [panelIndex]: collapse });
        };
    }
    function addCollapsed(index: number) {
        // expand added panel
        addCollapsedItem(index);
        collapseAllItems();
    }

    const conditionListRules = useMemo(() => {
        return [
            {
                validator: async (_: any, conditions: DynamicCondition[]) => {
                    if (!validationEnabled) {
                        return Promise.resolve();
                    }
                    // validate each ConditionEvent
                    const validConditionGroups = conditions.map((dynamicCondition) => {
                        // a condition group(DynamicCondition) is valid if every ConditionEvent is valid
                        return dynamicCondition.conditions
                            .map((conditions) => isValidEventGroup(conditions))
                            .every((valid) => valid);
                    });
                    if (validConditionGroups.some((valid) => !valid)) {
                        // expand groups with invalid data
                        const expandGroups = Object.fromEntries(
                            validConditionGroups.map((valid, index) => [index, valid])
                        );
                        collapseItemHandler(expandGroups);
                        return Promise.reject();
                    }
                    return Promise.resolve();
                },
            },
        ];
    }, [validationEnabled, collapseItemHandler]);

    return (
        <ConditionsListContextProvider form={form}>
            <FormList
                name={fieldName}
                direction={conditionalOptions.direction}
                joinType={joinType}
                itemDefaults={conditionalOptions.conditionDefaults}
                joinSelectProps={joinSelectProps}
                listOptions={conditionalOptions?.conditionalListOptions}
                addItem={addCollapsed}
                rules={conditionListRules}
            >
                {/* This is Each new condition group */}
                {(field, remove, index, fieldsLength) => {
                    return (
                        <Fragment key={field.name}>
                            {index > 0 && <JoinLabel text={joinTypeToString(joinType)} type="conditions" />}
                            <CollapsePanel
                                hideConditionRemove={
                                    conditionalOptions.conditionalListOptions?.hideConditionRemove && fieldsLength <= 1
                                }
                                hideConditionHeader={conditionalOptions.conditionalListOptions?.hideConditionHeader}
                                name={field.name}
                                activeKey={collapsedItems[index] ? undefined : index}
                                groupName={conditionalOptions.groupName}
                                remove={() => {
                                    removeCollapsedItem(field.name);
                                    remove(field.name);
                                }}
                                onChange={handleChangeCollapse(field.name)}
                            >
                                <EventList
                                    form={form}
                                    name={field.name}
                                    prefixPath={[fieldName, field.name]}
                                    data={dynamicConditions[field.name]?.conditions || []}
                                    joinType={dynamicConditions[field.name]?.joinType || JoinType.ALL}
                                    conditionalTypes={conditionalTypes}
                                    conditionalOptions={conditionalOptions}
                                />
                            </CollapsePanel>
                        </Fragment>
                    );
                }}
            </FormList>
        </ConditionsListContextProvider>
    );
}

export const ConditionsList = withValidationContext<ConditionsListParams & { validationEnabled?: boolean }>(Conditions);
