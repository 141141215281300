import { useCallback } from "react";

import { Form } from "antd";

import type { FormProps } from "antd";

/**
 * Antd forms allow for neested objects to be passed in. So we
 * need to be able to get the keys for the nested objects so that
 * we can clear the errors the correct fields.
 * @todo: we should update the forms to use the FormList and remove this function
 * @param the changedValues for the form
 * @returns an array of keys that have changed
 */
export const getNestedKeysObj = (obj: { [key: string]: any }): string[] => {
    // Initialize an empty array to hold the keys
    let keys: string[] = [];

    // Get the first key in the object
    const firstKey = Object.keys(obj)[0];

    if (firstKey) {
        // Push the first key to the array
        keys.push(firstKey);

        const firstValue = obj[firstKey];

        // If the value of the first key is an object, recursively get its keys
        if (typeof firstValue === "object" && firstValue !== null) {
            // If the value is an array, consider the first element of the array
            if (Array.isArray(firstValue) && firstValue.length > 0) {
                keys = keys.concat(getNestedKeysObj(firstValue[0]));
            } else {
                keys = keys.concat(getNestedKeysObj(firstValue));
            }
        }
    }

    return keys;
};

/**
 * Antd forms allow for neested objects to be passed in. So we
 * need to be able to get the keys for the nested objects so that
 * we can clear the errors the correct fields.
 * // this is for when we are using the AntD FormList
 * @param the changedValues for the form
 * @returns an array of keys that have changed
 */
export const getNestedKeysList = (obj: { [key: string]: any }): Array<number | string> => {
    let keys: Array<number | string> = [];

    const firstKey = Object.keys(obj)[0];

    if (firstKey) {
        keys.push(firstKey);

        const firstValue = obj[firstKey];

        if (typeof firstValue === "object" && firstValue !== null) {
            if (Array.isArray(firstValue)) {
                for (let i = 0; i < firstValue.length; i++) {
                    if (firstValue[i] !== null && firstValue[i] !== undefined) {
                        keys.push(i);
                        keys = keys.concat(getNestedKeysList(firstValue[i]));
                        break;
                    }
                }
            } else {
                keys = keys.concat(getNestedKeysList(firstValue));
            }
        }
    }

    return keys;
};

type RVFormProps = {
    className?: string;
    children: React.ReactNode;
    // this is an additional prop that we need to use to account for the fact that we.
    // are not always using a form list when we are doing repeatable fields.
    // If you have a form with a repeatable fields try to use the FormList component
    // and pass this as true.
    usesFormList?: boolean;
} & FormProps;

/**
 * A wrapper around the Ant Design Form component that provides a few extra features:
 * - Clears the errors on the form when the values change, This is the UX that product.
 * would like to see so that we dont see errors while typing.
 */
export const RVForm = ({ className, children, usesFormList, ...rest }: RVFormProps) => {
    const { form, onValuesChange } = rest;
    const clearErrorsOnChange = useCallback(
        (changedValues: any) => {
            if (changedValues) {
                const getKeys = usesFormList ? getNestedKeysList : getNestedKeysObj;
                const name = getKeys(changedValues);
                if (name) {
                    form?.setFields([
                        {
                            name,
                            errors: [],
                        },
                    ]);
                }
            }
        },
        [form, usesFormList]
    );

    const handleValuesChange = useCallback(
        (changedValues: any, allValues: any) => {
            onValuesChange?.(changedValues, allValues);
            clearErrorsOnChange(changedValues);
        },
        [clearErrorsOnChange, onValuesChange]
    );

    return (
        <Form {...rest} validateTrigger={"onSubmit"} className={className} onValuesChange={handleValuesChange}>
            {children}
        </Form>
    );
};
