import { parsePlainTextToHandlebarsTemplate } from "@regal-voice/shared-parsers";
import { EditorState, Modifier, SelectionState } from "draft-js";

import { DEFAULT_HANDLEBARS, DEFAULT_HANDLEBARS_END } from "../../constants";

import type { EditorState as EditorStateType } from "draft-js";

/* ----------*
* Range selection
------------*/

export const getInsertRange = (editorState: EditorStateType) => {
    const currentSelectionState = editorState.getSelection();
    const end = currentSelectionState.getAnchorOffset();
    const anchorKey = currentSelectionState.getAnchorKey();
    const currentContent = editorState.getCurrentContent();
    const currentBlock = currentContent.getBlockForKey(anchorKey);
    const blockText = currentBlock.getText();
    const start = blockText.substring(0, end).lastIndexOf("{") - 2;
    return {
        start,
        end,
    };
};

export const getTriggerRange = (editorState: EditorStateType, trigger: string) => {
    const selection = window.getSelection();
    if (selection?.rangeCount === 0) {
        return null;
    }
    const range = selection?.getRangeAt(0);
    const text = range?.startContainer?.textContent?.substring(0, range.startOffset) || "";
    if (/\s+$/.test(text)) {
        return null;
    }
    const startIndex = text.lastIndexOf(trigger);
    if (startIndex === -1) {
        return null;
    }

    if (triggerRangeIsOverlapping(editorState)) {
        return null;
    }
    return {
        text: text.substring(startIndex),
        start: startIndex,
        end: range?.startOffset,
    };
};

function triggerRangeIsOverlapping(editorState: EditorStateType) {
    const currentBlockKey = editorState.getSelection().getStartKey();
    const currentBlocksIndexes = editorState.getCurrentContent().getBlockMap().keySeq();
    const selectionBlockIndex = currentBlocksIndexes.findIndex((k: string | undefined) => k === currentBlockKey);

    const insertRange = getInsertRange(editorState);
    return getEntities(editorState)?.find((entity: any) => {
        const entityBlockRowIndex = currentBlocksIndexes.findIndex((k: string | undefined) => k === entity.blockKey);
        const entitiesAreOnSameRow = entityBlockRowIndex == selectionBlockIndex;
        const entitiesIndexesOverlaps =
            (entity.start <= insertRange.start + 1 && insertRange.start + 1 <= entity.end) ||
            (entity.start <= insertRange.end && insertRange.end <= entity.end);

        return entitiesAreOnSameRow && entitiesIndexesOverlaps;
    });
}

/* ----------*
* Modifiers
------------*/

/**
 * @TODO As an improvement get only blocks that belongs to a row
 */
function getEntities(editorState: EditorStateType, entityType = null) {
    const content = editorState.getCurrentContent();
    const entities: any = [];
    content.getBlocksAsArray().forEach((block: any) => {
        let selectedEntity: any = null;
        block.findEntityRanges(
            (character: any) => {
                if (character.getEntity() !== null) {
                    const entity = content.getEntity(character.getEntity());
                    if (!entityType || (entityType && entity.getType() === entityType)) {
                        selectedEntity = {
                            entityKey: character.getEntity(),
                            blockKey: block.getKey(),
                            entity: content.getEntity(character.getEntity()),
                        };
                        return true;
                    }
                }
                return false;
            },
            (start: number, end: number) => {
                entities.push({ ...selectedEntity, start, end });
            }
        );
    });
    return entities;
}

export const modifyHandlebarsTag = (editorState: EditorStateType, hashtag: string) => {
    const { start, end } = getInsertRange(editorState);
    const currentSelectionState = editorState.getSelection();
    const selection = currentSelectionState.merge({
        // start+1 because the trigger has length 2
        anchorOffset: start + 1,
        focusOffset: end,
    });

    const contentState = editorState.getCurrentContent();

    const contentStateWithEntity = contentState.createEntity("HASHTAG", "IMMUTABLE", {
        hashtag,
    });

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newContentState = Modifier.replaceText(
        contentStateWithEntity,
        selection,
        `${DEFAULT_HANDLEBARS}${hashtag}${DEFAULT_HANDLEBARS_END}`,
        null as any,
        entityKey
    );

    const newEditorState = EditorState.push(editorState, newContentState, `insert-hashtag` as any);

    return EditorState.forceSelection(newEditorState, newContentState.getSelectionAfter());
};

export const findHandlebarsByGrammarDecorator = (contentBlock: any, callback: any, _: any) => {
    const text = contentBlock.getText();
    const results = parsePlainTextToHandlebarsTemplate(text);

    const handlebarsTagsToHighlight: any[] = getChildrenHandlebarsTokens(results);

    handlebarsTagsToHighlight?.map((item) => {
        const startElement = item?.children?.shift();
        const endElement = item?.children?.pop();
        callback(startElement.start_pos, endElement.end_pos);
    });
};

export function getChildrenHandlebarsTokens(rootNodes: any) {
    if (rootNodes?.status == "error" || !rootNodes) {
        return;
    }
    return rootNodes?.reduce((acc: any, node: any) => {
        if (node?.data == "handlebars") {
            acc = [...acc, node];
        } else if (node?.children) {
            const childrenNodes = getChildrenHandlebarsTokens(node?.children);
            if (childrenNodes?.length) {
                acc = [...acc, ...getChildrenHandlebarsTokens(node?.children)];
            }
        }
        return acc;
    }, []);
}

export const findHandlebarsTagEntitiesDecorator = (contentBlock: any, callback: any, contentState: any) => {
    contentBlock.findEntityRanges((character: any) => {
        const entityKey = character.getEntity();
        return entityKey !== null && contentState.getEntity(entityKey).getType() === "HASHTAG";
    }, callback);
};

export const moveSelectionToEnd = (editorState: EditorStateType): EditorState => {
    const content = editorState.getCurrentContent();
    const plainText = content.getPlainText();
    if (plainText != DEFAULT_HANDLEBARS) {
        return editorState;
    }
    const blockMap = content.getBlockMap();

    const key = blockMap.last().getKey();
    const length = blockMap.last().getLength();

    // On Chrome and Safari, calling focus on contenteditable focuses the
    // cursor at the first character. This is something you don't expect when
    // you're clicking on an input element but not directly on a character.
    // Put the cursor back where it was before the blur.
    const selection = new SelectionState({
        anchorKey: key,
        anchorOffset: length,
        focusKey: key,
        focusOffset: length,
    });
    return EditorState.forceSelection(editorState, selection);
};
