import { ComponentPropsWithoutRef } from "react";

import { clsx } from "clsx";

import styles from "./Flexbox.module.scss";

export interface BoxProps extends ComponentPropsWithoutRef<"div"> {
    padding?: number;
    paddingTop?: number;
    paddingRight?: number;
    paddingBottom?: number;
    paddingLeft?: number;
    className?: string;
    flex?: string;
    fullWidth?: boolean;
}

export interface FlexProps extends BoxProps {
    justifyContent?: string;
    alignItems?: string;
    gap?: number;
}

function paramToClass(param: number | undefined, classPrefix: string) {
    return (
        param && {
            [styles[`${classPrefix}-${param}`]]: param,
        }
    );
}

export function Box(props: BoxProps): JSX.Element {
    const { padding, paddingTop, paddingRight, paddingBottom, paddingLeft, flex, className, children, style, ...rest } =
        props;
    const paddingCls = paramToClass(padding, "box-padding");
    const paddingTopCls = paramToClass(paddingTop, "box-padding-top");
    const paddingRightCls = paramToClass(paddingRight, "box-padding-right");
    const paddingBottomCls = paramToClass(paddingBottom, "box-padding-bottom");
    const paddingLeftCls = paramToClass(paddingLeft, "box-padding-left");

    return (
        <div
            className={clsx(className, paddingCls, paddingTopCls, paddingRightCls, paddingBottomCls, paddingLeftCls)}
            style={{ ...style, flex }}
            {...rest}
        >
            {children}
        </div>
    );
}

export function Row(props: FlexProps): JSX.Element {
    const { className, gap, justifyContent, alignItems, style, ...rest } = props;
    const gapCls = paramToClass(gap, "flex-gap");
    return (
        <Box
            className={clsx("flex-row", gapCls, className, {
                ["full-width"]: props.fullWidth,
            })}
            style={{ ...style, alignItems, justifyContent }}
            {...rest}
        />
    );
}

export function Column(props: FlexProps): JSX.Element {
    const { className, gap, justifyContent, alignItems, style, ...rest } = props;
    const gapCls = paramToClass(gap, "flex-gap");

    return (
        <Box
            className={clsx("flex-column", gapCls, className)}
            style={{ ...style, alignItems, justifyContent }}
            {...rest}
        />
    );
}
