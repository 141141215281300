// NOTE: This file is and should remain the only class-based React component we have.
// ErrorBoundaries are not yet implemented in functional React Components, as of React 17

import { Component, ReactNode } from "react";

import { Typography } from "antd";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { getLogger } from "Services/LoggingService";
import { checkActiveVersion } from "Services/VersionsService";

const logger = getLogger("ErrorBoundaryLogger");

class ErrorBoundaryComponent extends Component<any & RouteComponentProps> {
    unlisten: any;
    state: { hasError: boolean; errorId?: string; appOutOfDate?: boolean };

    constructor(props: any) {
        super(props);

        const { history } = props;
        this.state = { hasError: false };

        this.unlisten = history.listen(() => {
            if (this.state.hasError) {
                this.setState({ hasError: false });
            }
        });
    }

    componentWillUnmount() {
        this.unlisten?.();
    }

    static getDerivedStateFromError(): { hasError: boolean; errorId?: string } {
        // Update state so the next render will show the fallback UI.
        return { hasError: true, errorId: URL.createObjectURL(new Blob()).split("/").pop() };
    }

    logDefaultError(error: Error, errorInfo: any) {
        logger.error(error, {
            ...errorInfo,
            errorId: this.state.errorId,
        });
        this.setState({
            ...this.state,
            appOutOfDate: false,
        });
    }

    componentDidCatch(error: Error, errorInfo: any): void {
        if (error.name == "ChunkLoadError") {
            checkActiveVersion().then(({ isActive }) => {
                this.setState({
                    ...this.state,
                    appOutOfDate: !isActive,
                });
                logger.error(error, {
                    ...errorInfo,
                    errorId: this.state.errorId,
                    appOutOfDate: !isActive,
                });
            });
        } else {
            this.logDefaultError(error, errorInfo);
        }
    }

    render(): JSX.Element | ReactNode {
        if (this.state?.hasError) {
            return (
                <div style={{ height: "100%" }} className="flex-column justify-center align-middle">
                    {this.state.appOutOfDate ? (
                        <>
                            <h1>Regal Voice has been updated!</h1>
                            <p>
                                You are not on the latest version of the app. Please refresh the page to get the latest
                                version.
                            </p>
                        </>
                    ) : this.state.appOutOfDate == undefined ? (
                        <></>
                    ) : (
                        <>
                            <h1>Oops! We encountered an error!</h1>
                            <p>
                                We are already investigating, but if this appears again, please reach out to &nbsp;{" "}
                                <a href="mailto:support@regalvoice.com">support@regalvoice.com</a>
                                &nbsp; with the code below.
                            </p>
                        </>
                    )}
                    <p style={{ fontSize: "10px", color: "gray", paddingTop: "30px" }}>
                        code:
                        <Typography.Text copyable style={{ paddingLeft: "20px" }}>
                            {this.state.errorId}
                        </Typography.Text>
                    </p>
                </div>
            );
        }

        //eslint-disable-next-line react/prop-types
        return this.props.children;
    }
}

export const ErrorBoundary = withRouter(ErrorBoundaryComponent);
