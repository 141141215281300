import { Status } from "Components/elements/RVStatusBadge/RVStatusBadge";
import { NamedConditionalTypes, Segment, SegmentType } from "Types/Segment";

export function getConditionalTypes(
    segmentConditionsAllowCustomEvent: boolean,
    segmentConditionsAllowRegalEvent: boolean
): NamedConditionalTypes {
    let conditionalTypes: NamedConditionalTypes = [{ value: "contactAttribute", name: "Contact Attribute" }];
    if (segmentConditionsAllowCustomEvent) {
        conditionalTypes = conditionalTypes.concat({ value: "customEvent", name: "Custom Event" });
    }
    if (segmentConditionsAllowRegalEvent) {
        conditionalTypes = conditionalTypes.concat({ value: "regalVoiceEvent", name: "Regal Voice Event" });
    }
    return conditionalTypes;
}

export function getSegmentState(segment: Segment): { label: string; status: Status } {
    return {
        label: segment.type === SegmentType.DYNAMIC ? "dynamic" : "static",
        status: segment.type === SegmentType.DYNAMIC ? Status.DYNAMIC : Status.STATIC,
    };
}
