import { useMemo, ReactNode, SetStateAction, Dispatch, Ref, useEffect, useState } from "react";

import { SearchOutlined } from "@ant-design/icons";
import { Input, InputRef } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import { clsx } from "clsx";
import { debounce } from "lodash";

import styles from "./DebouncedInput.module.scss";

type DebouncedInputProps = {
    action: Dispatch<SetStateAction<string | null>> | Dispatch<string>;
    placeholder?: string;
    suffix?: ReactNode;
    autoFocus?: boolean;
    debounceMs?: number;
    name?: string;
    inputRef?: Ref<InputRef>;
    addonAfter?: JSX.Element;
    size?: SizeType;
    className?: string;
    "data-testid"?: string;
    disabled?: boolean;
    defaultValue?: string;
    autocomplete?: string;
};

export function DebouncedInput({
    action,
    placeholder,
    suffix,
    autoFocus,
    name,
    inputRef,
    debounceMs = 700,
    addonAfter,
    size,
    className,
    "data-testid": dataTestId,
    disabled = false,
    defaultValue,
    autocomplete,
}: DebouncedInputProps): JSX.Element {
    const debounceInputAction = useMemo(
        () => debounce((e: any): void => action(e.target.value), debounceMs),
        [action, debounceMs]
    );
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    // we use a spread to conditionally pass a ref to the input if one was provided
    const extraProps: Record<string, unknown> = typeof inputRef === "undefined" ? {} : { ref: inputRef };
    if (name) {
        extraProps.name = name;
    }
    return (
        <Input
            value={value}
            placeholder={placeholder}
            className={clsx(styles.searchInput, className)}
            prefix={<SearchOutlined className={styles.searchIcon} />}
            addonAfter={addonAfter}
            onChange={(e) => {
                e.persist();
                debounceInputAction(e);
                setValue(e.target.value);
            }}
            onPressEnter={(e) => action(e.currentTarget.value)}
            suffix={suffix}
            autoFocus={autoFocus}
            size={size || "middle"}
            data-testid={dataTestId}
            disabled={disabled}
            autoComplete={autocomplete}
            {...extraProps}
        />
    );
}
