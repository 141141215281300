import { useContext } from "react";

import { Col, Row } from "antd";

import { UserContext } from "App/contexts";
import { AgentIcon } from "Components/shared/AgentIcon/AgentIcon";
import { useTimeElapsed } from "Hooks/useTimeElapsed";
import { Activity } from "Types/Activity";

interface AgentStatusDisplayProps {
    userActivityInformation: Activity;
}

export default function AgentStatusDisplay({ userActivityInformation }: AgentStatusDisplayProps): JSX.Element {
    const { user } = useContext(UserContext);
    const { timeDisplay } = useTimeElapsed({
        startTime: userActivityInformation.startTime,
    });

    return (
        <>
            <Row justify="space-between" align="middle" gutter={[16, 0]}>
                <Col style={{ textAlign: "right" }}>
                    <div>
                        {user?.firstName as string} {user?.lastName as string}
                    </div>
                    <small className="subcopy">
                        {userActivityInformation.name}
                        &nbsp;&nbsp;|&nbsp;&nbsp;
                        {timeDisplay}
                    </small>
                </Col>
                <Col>
                    <AgentIcon available={userActivityInformation.available} />
                </Col>
            </Row>
        </>
    );
}
