import { DEFAULT_HANDLEBARS } from "../RVJSONHandlebarsAutosuggest/constants";
import { RVJSONHandlebarsAutosuggest, Variant } from "../RVJSONHandlebarsAutosuggest/RVJSONHandlebarsAutosuggest";

export interface HandelbarAutosuggestHOCProps {
    value?: string;
    eventOptions: Array<{ value: string; label: string; dataType: string }>;
    dataTestid?: string;
}

/**
antd Select doesn't allow customisation of input element and I choosed to switch controls
at runtime between Select and RVJSONHandlebarsAutosuggest when value starts with '{{'
the problem is that, after switching controls, editing cannot continue because focus is lost and 
user mast click again on input
 */
export function withHandlebarAutosuggest<TProps extends HandelbarAutosuggestHOCProps>(
    Component: React.ComponentType<TProps>
) {
    return function withHandlebarAutosuggest(props: TProps): React.ReactElement {
        const { value, eventOptions, dataTestid, ...rest } = props;
        const showHandlebarsAutosugest =
            value != null &&
            typeof value === "string" &&
            value.startsWith(DEFAULT_HANDLEBARS) &&
            eventOptions.length > 0;

        return showHandlebarsAutosugest ? (
            <RVJSONHandlebarsAutosuggest
                contactEventOptions={[]}
                variant={Variant.Input}
                autofocus={true}
                value={value}
                eventOptions={eventOptions}
                dataTestid={`${dataTestid}-with-hb`}
                // TODO: fix z-index issue: dropdown is draw bellow the following input
                // when popoverPlacement is "bottom"
                // popoverPlacement="bottom"
                {...rest}
            />
        ) : (
            <Component autofocus={true} {...props} />
        );
    };
}
