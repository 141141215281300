import { useMemo } from "react";

import dayjs from "dayjs";

import { FillChart } from "Components/shared/AgentNavBarButtons/AgentStats/AdvancedStats/Charts/FillChart/FillChart";
import { convertHoursMinsToDec } from "Services/TimeService";

import type { BusinessHours } from "@regal-voice/shared-types";

type StatProps = {
    loading: boolean;
    count: number;
    businessHours?: BusinessHours;
};

const TARGET_PER_HOUR = 15;
export const TasksPerHour = ({ loading, count, businessHours }: StatProps): JSX.Element => {
    const elapsedTime = useMemo(() => {
        // if we we have no business hours then
        // our pace maxes out
        if (!businessHours) {
            return 1;
        }

        const todaysDay: string = dayjs().format("dddd");
        const { start: startTime, end: endTime } = businessHours[todaysDay.toLowerCase() as keyof BusinessHours];

        // if we have business hours but they are 0:00 then we are closed
        // our pace maxes out
        if (startTime == "0:00" || endTime == "0:00") {
            return 1;
        }

        const todaysTime = dayjs().format("HH:mm");
        const convertedStart = convertHoursMinsToDec(startTime);
        const convertedNow = convertHoursMinsToDec(todaysTime);
        const diff = convertedNow - convertedStart;
        // if it is before the start then our pace maxes out
        if (diff < 0) {
            return 1;
        }

        return diff;
    }, [businessHours]);

    const percentage = count / (elapsedTime * TARGET_PER_HOUR);
    let pace = percentage * TARGET_PER_HOUR;
    // lock the decimal to 2 places
    pace = Math.round(pace * 100) / 100;
    const desctiptor = pace === 1 ? "Task" : "Tasks";

    return (
        <FillChart
            headerTooltip={
                "Calculated using the total number of tasks you’ve completed today divided by the number of business hours your company has been open."
            }
            loading={loading}
            chartHeader="Completed Tasks Per Business Hour"
            chartHeaderSubtitle="Today"
            chartHeaderInfoValue={`${pace}`}
            chartHeaderInfoDescriptor={desctiptor}
            chartSubtextZero={"0 Tasks"}
            chartSubtextFull={`Target: ${TARGET_PER_HOUR} Tasks per hour`}
            percentage={percentage}
        />
    );
};
