export const REGAL_VOICE_DEFAULT_EVENTS = [
    "call.ivr.triggered",
    "call.completed",
    "call.placed",
    "call.recording.available",
    "call.transcript.available",
    "call.wrapup",
    "cancel.all.automated.tasks",
    "contact.attribute.edited",
    "contact.created",
    "contact.email.created",
    "contact.experiment.assigned",
    "contact.phone.created",
    "contact.subscribed",
    "contact.unsubscribed",
    "contacted.after.hours",
    "custom.task.completed",
    "email.received",
    "email.sent",
    "mms.received",
    "mms.sent",
    "scheduled.callback.requested",
    "scheduled.reminder.created",
    "sms.conversation.completed",
    "sms.failed",
    "sms.queued",
    "sms.received",
    "sms.sent",
    "sms.undelivered",
    "task.canceled",
    "task.created",
    "task.reservation.accepted",
    "task.reservation.created",
    "voicemail.recording.available",
    "voicemail.transcript.available",
];
