import { createContext, useContext, useMemo, ReactNode } from "react";

import { FormInstance } from "antd/es/form/Form";

export interface ConditionsListContextInterface {
    form: FormInstance;
}

export const ConditionsListContext = createContext<ConditionsListContextInterface | null>(null);

export function ConditionsListContextProvider(
    props: ConditionsListContextInterface & {
        children: ReactNode;
    }
): JSX.Element {
    const { form } = props;
    const contextValue = useMemo(() => ({ form }), [form]);

    return <ConditionsListContext.Provider value={contextValue}>{props.children}</ConditionsListContext.Provider>;
}

export function useConditionsList(): ConditionsListContextInterface {
    const context = useContext(ConditionsListContext);
    if (context == null) {
        throw new Error("useConditionsList must be used within a ConditionsListContextProvider");
    }
    return context;
}
