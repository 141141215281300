import { useCallback, useMemo, useState } from "react";

import { Select, SelectProps } from "antd";
import { debounce } from "lodash";

import { HighlightedString } from "../../elements/HighlightedString/HighlightedString";

export interface RVDebouncedSelectProps extends Omit<SelectProps<string | string[]>, "options" | "children"> {
    options: string[];
    loading: boolean;
    onDebouncedSearch: (value: string) => void;
    debounceMs?: number;
}

/**
 * Lets you use a debounced search within a Select component.
 * Highlights options with partial matches.
 */
export function RVDebouncedSelect({
    options = [],
    onSearch,
    onDebouncedSearch,
    autoClearSearchValue,
    onSelect,
    onDeselect,
    onBlur,
    loading,
    debounceMs = 300,
    ...props
}: RVDebouncedSelectProps): JSX.Element {
    const [search, setSearch] = useState("");

    const debouncedSearch = useMemo(() => debounce(onDebouncedSearch, debounceMs), [debounceMs, onDebouncedSearch]);

    const handleSearch = useCallback<NonNullable<SelectProps["onSearch"]>>(
        (searchText) => {
            setSearch(searchText);
            onSearch?.(searchText);
            debouncedSearch(searchText);
        },
        [onSearch, debouncedSearch]
    );
    const handleSelect = useCallback<NonNullable<SelectProps["onSelect"]>>(
        (value, option) => {
            if (autoClearSearchValue) {
                setSearch("");
            }
            onSelect?.(value, option);
        },
        [onSelect, autoClearSearchValue]
    );
    const handleDeselect = useCallback<NonNullable<SelectProps["onDeselect"]>>(
        (value, option) => {
            if (autoClearSearchValue) {
                setSearch("");
            }
            onDeselect?.(value, option);
        },
        [onDeselect, autoClearSearchValue]
    );

    // antd decides to clear the search value on blur regardless of autoClearSearchValue
    const handleBlur = useCallback<NonNullable<SelectProps["onBlur"]>>(
        (e) => {
            // we make sure we keep UI and state in sync
            handleSearch("");
            onBlur?.(e);
        },
        [handleSearch, onBlur]
    );

    const selectOptions = useMemo<SelectProps["options"]>(() => {
        return options.map((opt) => ({
            label: <HighlightedString val={opt} highlightVal={search} />,
            value: opt,
        }));
    }, [options, search]);

    return (
        <Select
            {...props}
            data-testid={"debounced-select"}
            filterOption={false}
            autoClearSearchValue={autoClearSearchValue}
            showSearch
            onSearch={handleSearch}
            onSelect={handleSelect}
            onDeselect={handleDeselect}
            onBlur={handleBlur}
            /**
             * this better deals with loading state, suggested by antd docs
             * @see https://4x.ant.design/components/select/#components-select-demo-select-users
             */
            notFoundContent={loading ? "Loading..." : "No results found"}
            options={loading ? [] : selectOptions}
        />
    );
}
