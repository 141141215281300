import { NullOr } from "@regal-voice/shared-types";
import { stringify } from "qs";

import { configureServer } from "../AbstractApiService";
import { getIngestUrl } from "../ProxyConfig";

type BaseDynamoLastEvaluatedKeyData = {
    id: string;
    info: string;
};

type DynamoLastEvaluatedKey = NullOr<BaseDynamoLastEvaluatedKeyData>;

export type AutocompleteOption = {
    lastEvaluatedKey: { id: string; info: string };
    items: { info: string }[];
};

const makeRequest = ({ performCaseConversion = true, performResponseCaseConversion = true } = {}) => {
    return configureServer({
        host: getIngestUrl(),
        performCaseConversion,
        performResponseCaseConversion,
    });
};

export async function autocompleteOptions(
    params: {
        searchTerm: string;
        lastEvaluatedKey: DynamoLastEvaluatedKey;
        limit: number;
        fieldId: string;
    },
    signal?: AbortSignal
) {
    const queryParams = stringify(params, { encode: true });
    return makeRequest()<AutocompleteOption>({
        endpoint: `/v1/autocomplete-events/search?${queryParams}`,
        errors: {
            notFound: "Could not load events",
            other: "Error fetching the events value",
        },
        signal,
    });
}
