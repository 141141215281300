import { FieldType } from "@regal-voice/shared-types";

export interface ConditionalNodeOperator {
    value: string;
    name: string;
}

export const defaultOperators: ConditionalNodeOperator[] = [
    { value: "isNotSet", name: "Does Not Exist" },
    { value: "isSet", name: "Exists" },
];

export const eventOperators: ConditionalNodeOperator[] = [
    { value: "isNotSet", name: "Does Not Exist" },
    { value: "isSet", name: "Exists" },
];

// Potentially confusing renaming isNotNull operator to Is Set which is also an existing operator
export const allAvailableOperators: Record<FieldType, ConditionalNodeOperator[]> = {
    string: [
        { value: "contains", name: "Contains" },
        { value: "notEquals", name: "Does Not Equal" },
        { value: "isNotSet", name: "Does Not Exist" },
        { value: "equals", name: "Equals" },
        { value: "isSet", name: "Exists" },
        { value: "isNotNull", name: "Is Set" },
        { value: "startsWith", name: "Starts With" },
        { value: "endsWith", name: "Ends With" },
        { value: "isOneOf", name: "Is One Of" },
    ],
    boolean: [
        { value: "notEquals", name: "Does Not Equal" },
        { value: "isNotSet", name: "Does Not Exist" },
        { value: "equals", name: "Equals" },
        { value: "isSet", name: "Exists" },
        { value: "isNotNull", name: "Is Set" },
    ],
    number: [
        { value: "notEquals", name: "Does Not Equal" },
        { value: "isNotSet", name: "Does Not Exist" },
        { value: "equals", name: "Equals" },
        { value: "isSet", name: "Exists" },
        { value: "isNotNull", name: "Is Set" },
        { value: "greaterThan", name: "Is Greater Than" },
        { value: "greaterThanEquals", name: "Is Greater Than or Equal To" },
        { value: "lessThan", name: "Is Less Than" },
        { value: "lessThanEquals", name: "Is Less Than or Equal To" },
        { value: "isOneOf", name: "Is One Of" },
    ],
    datetime: [
        { value: "isNotSet", name: "Does Not Exist" },
        { value: "isSet", name: "Exists" },
        { value: "isNotNull", name: "Is Set" },
        { value: "after", name: "Is After" },
        { value: "before", name: "Is Before" },
        { value: "onOrAfter", name: "Is On or After" },
        { value: "onOrBefore", name: "Is On or Before" },
    ],
    list: [
        { value: "contains", name: "Contains" },
        { value: "isNotSet", name: "Does Not Exist" },
        { value: "isNotNull", name: "Is Set" },
        { value: "isSet", name: "Exists" },
    ],
    object: [
        { value: "isNotSet", name: "Does Not Exist" },
        { value: "isSet", name: "Exists" },
        { value: "isNotNull", name: "Is Set" },
    ],
};

const stringNoEndsWith: ConditionalNodeOperator[] = allAvailableOperators["string"].filter(
    (operator) => operator.value !== "endsWith"
);

const typeToOptions: Record<string, Record<FieldType, ConditionalNodeOperator[]>> = {
    contactAttribute: allAvailableOperators,
    triggeringEvent: allAvailableOperators,
    regalVoiceEvent: { ...allAvailableOperators, string: stringNoEndsWith },
    customEvent: { ...allAvailableOperators, string: stringNoEndsWith },
    // required because we'll need a larger refactor
    // for conditional framework we have the CUSTOM_EVENT key defined as 'custom_event',
    // but in trigger node we have it define as `custom`
    custom: { ...allAvailableOperators, string: stringNoEndsWith },
};

for (const conditionalType in typeToOptions) {
    for (const fieldType in typeToOptions[conditionalType]) {
        typeToOptions[conditionalType][fieldType as FieldType].sort(sortOperators);
    }
}

function sortOperators(a: ConditionalNodeOperator, b: ConditionalNodeOperator) {
    const x = a.name;
    const y = b.name;
    if (x < y) {
        return -1;
    }
    if (x > y) {
        return 1;
    }
    return 0;
}

function compileAllOperators() {
    const operators: Set<string> = new Set();
    for (const fieldType in allAvailableOperators) {
        allAvailableOperators[fieldType as FieldType].forEach((x) => operators.add(JSON.stringify(x)));
    }
    const allOperators: ConditionalNodeOperator[] = Array.from(operators).map((x) => JSON.parse(x));
    allOperators.sort(sortOperators);
    return allOperators;
}

export const allOperators = compileAllOperators();

export type ConditionalType = "contactAttribute" | string;

export function conditionalOptions(
    condType?: ConditionalType,
    attributeName?: string,
    propertyType?: FieldType,
    flags?: Record<string, boolean>
): ConditionalNodeOperator[] {
    let operators: ConditionalNodeOperator[] = [];
    if (!attributeName) {
        operators = eventOperators;
    } else if (!condType || !propertyType) {
        if (attributeName) {
            operators = allOperators;
        } else {
            operators = defaultOperators;
        }
    } else {
        operators = typeToOptions[condType][propertyType] || allOperators;
    }
    if (!flags?.conditionalNodeIsOneOf) {
        operators = operators.filter((operator) => operator.value !== "isOneOf");
    }

    return operators;
}
