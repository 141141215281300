import { clsx } from "clsx";
import { ReactSVG } from "react-svg";

import { icon as iconPath } from "Services/AssetManagementService";

import styles from "./Icon.module.scss";

export enum IconVariants {
    DEFAULT = "",
    CIRCLE = "circle",
    LARGE_CIRCLE = "large-circle",
}

export enum IconSizes {
    BUTTON = "button",
    LARGE_BUTTON = "large-button",
    SMALL = "small",
    XSMALL = "xsmall",
    XXSMALL = "xxsmall",
    DEFAULT = "",
    LARGE = "large",
    AVATAR = "avatar",
}

export default function Icon({
    icon,
    variant = IconVariants.DEFAULT,
    size = IconSizes.DEFAULT,
    color = "",
    bgColor = "",
    className = "",
    innerRef,
    // passed in by some 3rd parties, omit this from the ...props passed along to the object
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, react/prop-types
    iconClass,
    ...props
}: {
    icon: string;
    variant?: IconVariants;
    size?: IconSizes;
    color?: string;
    bgColor?: string;
    className?: string;
    innerRef?: any;
    [props: string]: any;
}): JSX.Element {
    return (
        <ReactSVG
            src={iconPath(icon)}
            className={clsx(styles.icon, className, {
                [styles[color]]: !!color,
                [styles[`bg-${bgColor}`]]: !!bgColor,
                [styles[size]]: !!size,
                [styles[variant]]: !!variant,
            })}
            ref={innerRef}
            {...props}
        />
    );
}
