import { useCallback } from "react";

import { useMutation } from "@apollo/client";
import { message } from "antd";

import { SAVED_VIEW_NAMESPACE } from "Pages/agentDesktop/agentInterface/AgentDesktopSideNav/ContactListSavedViews/constants";
import { renderErrorMessage } from "Services/LoggingService";

import {
    createContactListMutation as CREATE_CONTACT_LIST_MUTATION,
    updateContactListMutation as UPDATE_CONTACT_LIST_MUTATION,
    deleteContactListMutation as DELETE_CONTACT_LIST_MUTATION,
} from "../Queries/mutations";

type HookProps = {
    id?: string;
    refetch: () => void;
    navigate: (path: string) => void;
};

type HookReturn = {
    isMutatingData: boolean;
    onFinish: (formData: any) => void;
    onDelete: (id: string) => Promise<void>;
};

const BASE_PATH = SAVED_VIEW_NAMESPACE;

const shareParser = (shareBy: string, sharedTeams: unknown[]) => {
    if (shareBy === "All") {
        return {
            shareWithAllUsers: true,
            teamIds: [],
        };
    } else if (shareBy === "Teams") {
        // if we have selected or edited a team it is just the value
        if (typeof sharedTeams[0] == "string") {
            return {
                shareWithAllUsers: false,
                teamIds: sharedTeams || [],
            };
        } else {
            return {
                shareWithAllUsers: false,
                // if the team is already there it is an object and we need to get the value
                teamIds: sharedTeams.map((team: any) => team.value),
            };
        }
    }
    // just return default empty array
    return {
        shareWithAllUsers: false,
        teamIds: [],
    };
};

export function useNewContactListMutation({ id, refetch, navigate }: HookProps): HookReturn {
    const isEditMode = Boolean(id);
    const [createContactListMutation, { loading: isCreating }] = useMutation(CREATE_CONTACT_LIST_MUTATION, {
        onCompleted: (data) => {
            navigate(`${BASE_PATH}/${data.createSavedView.id}`);
        },
    });
    const [updateContactlistMutation, { loading: isUpdating }] = useMutation(UPDATE_CONTACT_LIST_MUTATION);

    const [deleteContactListMutation, { loading: isDeleting }] = useMutation(DELETE_CONTACT_LIST_MUTATION);

    const errorHandler = useCallback((e: any | Error, error: { message: string }) => {
        renderErrorMessage({
            content: `${error.message}`,
            error: e as Error,
        });
    }, []);

    const createContactList = useCallback(
        async (formData: any) => {
            const { name, joinType, dynamicConditions, sharedTeams, shareBy } = formData;
            const { shareWithAllUsers, teamIds } = shareParser(shareBy, sharedTeams);

            try {
                await createContactListMutation({
                    variables: {
                        createSavedViewInput: {
                            name,
                            pageQuery: JSON.stringify({ joinType, dynamicConditions }),
                            page: SAVED_VIEW_NAMESPACE,
                            shareWithAllUsers,
                            teamIds,
                        },
                    },
                });
                message.success("Contact List Created.");
                refetch();
            } catch (e) {
                errorHandler(e, {
                    message: "Contact List failed to be created.",
                });
            }
        },
        [createContactListMutation, errorHandler, refetch]
    );

    const updateContactList = useCallback(
        async (formData: any) => {
            const { name, joinType, dynamicConditions, sharedTeams, shareBy } = formData;
            const { shareWithAllUsers, teamIds } = shareParser(shareBy, sharedTeams);
            try {
                await updateContactlistMutation({
                    variables: {
                        updateSavedViewInput: {
                            id,
                            name,
                            pageQuery: JSON.stringify({ joinType, dynamicConditions }),
                            page: SAVED_VIEW_NAMESPACE,
                            shareWithAllUsers,
                            teamIds,
                        },
                    },
                });
                message.success("Contact List Updated.");
                refetch();
            } catch (e) {
                errorHandler(e, {
                    message: "Contact List failed to update",
                });
            }
        },
        [updateContactlistMutation, id, refetch, errorHandler]
    );

    const deleteContactList = useCallback(
        async (id: string) => {
            try {
                await deleteContactListMutation({
                    variables: {
                        id,
                    },
                });
                message.success("Contact List Deleted.");
                refetch();
                navigate(BASE_PATH);
            } catch (e) {
                errorHandler(e, {
                    message: "Contact List failed to delete.",
                });
            }
        },
        [deleteContactListMutation, errorHandler, navigate, refetch]
    );

    const onFinish = useCallback(
        (formData: unknown) => {
            if (isEditMode) {
                updateContactList(formData);
            } else {
                createContactList(formData);
            }
        },
        [isEditMode, createContactList, updateContactList]
    );

    return {
        isMutatingData: isCreating || isUpdating || isDeleting,
        onFinish,
        onDelete: deleteContactList,
    };
}
