import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sortBy } from "lodash";

import { SavedView } from "Types/SavedViews";

export type SavedViewsPageState = {
    selected_id: string | null;
    items: Array<SavedView>;
};

export type SavedViewsState = {
    loading: boolean;
    views: Record<string, SavedViewsPageState>;
};

export const initialState: SavedViewsState = {
    loading: false,
    views: {},
};

function sortViews(views: Array<SavedView>) {
    return sortBy(views, (item) => item.name.toLowerCase());
}

const SavedViewsStateSlice = createSlice({
    name: "savedViews",
    initialState,
    reducers: {
        setLoading(state, action: PayloadAction<boolean>) {
            state.loading = action.payload;
        },

        setSavedViews(state, action: PayloadAction<{ page: string; savedViews: Array<SavedView> }>) {
            const { page, savedViews } = action.payload;
            if (!state.views[page]) {
                state.views[page] = {
                    selected_id: null,
                    items: [],
                };
            }

            state.views[page].items = sortViews(savedViews);
        },

        setSelectedSavedView(state, action: PayloadAction<{ page: string; pageQuery: string }>) {
            const { page, pageQuery } = action.payload;
            const savedViews = state.views[page];
            const views = savedViews?.items || ([] as Array<SavedView>);
            const selectedView = views.find((v) => v.pageQuery === pageQuery);
            savedViews.selected_id = selectedView?.id || null;
        },

        addView(state, action: PayloadAction<{ page: string; view: SavedView }>) {
            const { page, view } = action.payload;
            const savedViews = state.views[page];

            if (savedViews) {
                savedViews.items = sortViews(savedViews.items.concat(view));
                savedViews.selected_id = view.id;
            } else {
                state.views[page] = {
                    selected_id: view.id,
                    items: [view],
                };
            }
        },

        deleteView(state, action: PayloadAction<{ page: string; id: string }>) {
            const { id, page } = action.payload;

            state.views[page] = {
                selected_id: state.views[page].selected_id === id ? null : state.views[page].selected_id,
                items: state.views[page].items.filter((view) => view.id !== id),
            };
        },

        updateView(state, action: PayloadAction<{ page: string; view: SavedView }>) {
            const { page, view } = action.payload;

            // even if the data is corrupted, we don't want to crash
            if (!state.views[page]) {
                state.views[page] = {
                    selected_id: null,
                    items: [view],
                };
            }

            const savedViews = state.views[page];

            const oldViewIndex = savedViews.items.findIndex((v) => v.id === view.id);
            // we should always find the old saved view
            // but we're making sure we only change it if it's there
            if (oldViewIndex !== -1) {
                const oldView = savedViews.items[oldViewIndex] || {};
                const newView = {
                    ...oldView,
                    ...view,
                };
                savedViews.items[oldViewIndex] = newView;
                savedViews.items = sortViews(savedViews.items);

                // if this view is marked as default
                // set all other views to not be the default
                if (view.isDefault) {
                    savedViews.items.forEach((v) => {
                        if (v.id !== view.id) {
                            v.isDefault = false;
                        }
                    });
                }
            }
        },
    },
});

export const { setLoading, setSavedViews, setSelectedSavedView, addView, deleteView, updateView } =
    SavedViewsStateSlice.actions;
export default SavedViewsStateSlice.reducer;
