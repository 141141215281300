import { combineReducers } from "redux";

import transcriptionFiltersReducer from "./TranscriptionFiltersStateProvider";
import transcriptionSharedDataReducer from "./TranscriptionSharedDataStateProvider";
import transcriptionInteractionReducer from "./TranscriptionsInteractionsStateProvider";
import transcriptionReducer from "./TranscriptionsStateProvider";

export type TranscriptionState = {
    activeTranscription: ReturnType<typeof transcriptionReducer>;
    transcriptionInteractions: ReturnType<typeof transcriptionInteractionReducer>;
    transcriptionSharedData: ReturnType<typeof transcriptionSharedDataReducer>;
    transcriptionFilters: ReturnType<typeof transcriptionFiltersReducer>;
};

export default combineReducers<TranscriptionState>({
    activeTranscription: transcriptionReducer,
    transcriptionInteractions: transcriptionInteractionReducer,
    transcriptionSharedData: transcriptionSharedDataReducer,
    transcriptionFilters: transcriptionFiltersReducer,
});
