import { useState } from "react";

import { Tooltip } from "antd";
import { clsx } from "clsx";
import { useHotkeys } from "react-hotkeys-hook";
import { Link, useHistory } from "react-router-dom";

import Icon from "Components/elements/Icon";

import styles from "./RVSidebarItem.module.scss";

type SidebarItemProps = {
    icon: string;
    href: string;
    activeRoutes?: string[];
    hotkey?: string | undefined;
    children: JSX.Element | string;
};

const checkActive = (href: string, activeRoutes: string[], pathname: string | undefined) => {
    if (!pathname) {
        return false;
    }

    if (pathname?.match(new RegExp(`^${href}($|/)`))) {
        return true;
    }
    if (activeRoutes) {
        for (const route of activeRoutes) {
            if (pathname?.match(new RegExp(`^${route}($|/)`))) {
                return true;
            }
        }
    }
    return false;
};

// this is currently onlyused in th layouts/SideNavBar.tsx
// so once that directtory is organized this should move to live under that component
export function RVSidebarItem({
    icon,
    href,
    activeRoutes = [],
    children,
    hotkey = undefined,
}: SidebarItemProps): JSX.Element {
    const router = useHistory();

    const [activeTitle, setActiveTitle] = useState<JSX.Element | string>(children);
    useHotkeys(hotkey ? `alt+${hotkey}` : "", () => {
        router.push(href);
    });

    // we need check if we are on our route or on any that we want to look active for
    const isActive = checkActive(href, activeRoutes, router.location?.pathname);

    function onTooltipVisibleChange(visible: boolean): void {
        if (visible) {
            setActiveTitle(children);
            if (hotkey) {
                setTimeout(
                    () =>
                        setActiveTitle(
                            <div translate="no">
                                {children}
                                <div>
                                    <i>
                                        hotkey &lsquo;{/^mac/i.test(window.navigator.platform) ? "Option" : "Alt"} +{" "}
                                        {hotkey}&rsquo;
                                    </i>
                                </div>
                            </div>
                        ),
                    3000
                );
            }
        }
    }

    return (
        <Link to={href}>
            <Tooltip placement="right" title={activeTitle} onOpenChange={onTooltipVisibleChange}>
                <div
                    className={clsx(styles.navItem, {
                        // Pathname must start with href prop (other paths can follow)
                        [styles.active]: isActive,
                    })}
                >
                    <Icon icon={icon} />
                </div>
            </Tooltip>
        </Link>
    );
}
