import { checkValidRelativeDateString } from "@regal-voice/shared-parsers";
import { Input, Select } from "antd";
import { NamePath } from "antd/lib/form/interface";
import { clsx } from "clsx";
import { set, isEmpty, cloneDeep } from "lodash";

import { EnhancedFormItem } from "Components/elements/FloatingLabel/EnhancedFormItem";
import { AutocompleteStringValue } from "Components/shared/AutoCompleteStringValue/AutocompleteStringValue";
import { Box } from "Components/shared/Flexbox";
import { useValidation } from "Components/shared/FormValidationContext";
import { RVSearchableMultiselectField } from "Components/shared/RVSearchableMultiselectField/RVSearchableMultiselectField";
import { DatePickerItem } from "Pages/journeys/JourneyEditPage/Components/JourneyBuilder/Components/JourneyActionsPanel/NodeEditorPanel/Components/ConditionalFormItems/components/DatePickerItem";
import { internalIsDateComparison } from "Pages/journeys/JourneyEditPage/Components/JourneyBuilder/Components/JourneyActionsPanel/NodeEditorPanel/Components/ConditionalFormItems/components/helpers";
import { NumericInputValue } from "Pages/journeys/JourneyEditPage/Components/JourneyBuilder/Components/JourneyActionsPanel/NodeEditorPanel/Components/ConditionalFormItems/components/NumericInputValue";
import { RealtiveDatePopover } from "Pages/journeys/JourneyEditPage/Components/JourneyBuilder/Components/JourneyActionsPanel/NodeEditorPanel/Components/ConditionalFormItems/components/RealtiveDatePopover";

import { useConditionsList } from "./ConditionsListContext";

import type { LayoutDirection } from "../Types";

import styles from "./PropertyValue.module.scss";

const REQUIRED_VALUE = "Input value cannot be blank.";
const EQUALITY_OPERATOR_VALUE_REQUIRED = 'Input value cannot be blank. To match an empty string, enter "" as value.';

const EQUALITY_OPERATORS = ["equals", "notEquals"];

export interface PropertyValueProps {
    parentPropName?: any;
    propertyType?: any;
    propertyOperator: string;
    propertyValueDisabled: boolean;
    propertyFieldId?: string;
    isContactCondtional: boolean;
    dateComparisonType?: string;
    direction?: LayoutDirection;
    hidden?: boolean;
    dependencies?: NamePath[];
    prefixPath: Array<string | number>;
}

export function PropertyValue({
    prefixPath,
    parentPropName,
    propertyType,
    propertyOperator,
    propertyValueDisabled,
    propertyFieldId,
    isContactCondtional,
    dateComparisonType,
    direction,
    hidden,
    dependencies,
}: PropertyValueProps): JSX.Element {
    if (hidden) {
        return <></>;
    }
    return internalIsDateComparison(propertyOperator) ? (
        <DatePropertyValue
            prefixPath={prefixPath}
            parentPropName={parentPropName}
            dateComparisonType={dateComparisonType}
            propertyOperator={propertyOperator}
            direction={direction}
            dependencies={dependencies}
        />
    ) : (
        <SimplePropertyValue
            parentPropName={parentPropName}
            propertyType={propertyType}
            propertyOperator={propertyOperator}
            isContactCondtional={isContactCondtional}
            propertyFieldId={propertyFieldId}
            propertyValueDisabled={propertyValueDisabled}
            dependencies={dependencies}
        />
    );
}

function SimplePropertyValue({
    parentPropName,
    propertyType,
    propertyOperator,
    propertyValueDisabled,
    propertyFieldId,
    isContactCondtional,
    dependencies,
}: {
    parentPropName?: any;
    propertyType?: any;
    propertyOperator: string;
    propertyValueDisabled: boolean;
    propertyFieldId?: string;
    isContactCondtional: boolean;
    dependencies?: NamePath[];
}): JSX.Element {
    const { validationEnabled } = useValidation();
    const { label, initialValue } = getFormItemProps({ isContactCondtional, propertyType });
    const propertyName = [parentPropName, "propertyValue"];

    function getFormItem() {
        switch (propertyType) {
            case "string":
                return propertyOperator === "isOneOf" ? (
                    <RVSearchableMultiselectField
                        autoClearSearchValue={false}
                        maxTagCount={4}
                        propertyFieldId={propertyFieldId || ""}
                        dataTestid={`property-select-propertyValue-string`}
                    />
                ) : (
                    <AutocompleteStringValue
                        rootField={{}}
                        propertyOperator={propertyOperator}
                        propertyValueDisabled={propertyValueDisabled}
                        propertyFieldId={propertyFieldId || ""}
                        isContactCondtional={isContactCondtional}
                        maxTagCount={4}
                        eventOptions={[]}
                    />
                );
            case "number":
                return (
                    <NumericInputValue
                        rootField={{}}
                        propertyOperator={propertyOperator}
                        propertyValueDisabled={propertyValueDisabled}
                    />
                );
            case "boolean":
                return (
                    <Select>
                        <Select.Option value="true">True</Select.Option>
                        <Select.Option value="false">False</Select.Option>
                    </Select>
                );
            default:
                return <Input disabled={propertyValueDisabled} />;
        }
    }

    return (
        <EnhancedFormItem
            key={`${propertyName}-${propertyType}-${propertyOperator}`}
            name={propertyName}
            label={label}
            initialValue={initialValue}
            className={styles.formItem}
            dependencies={dependencies}
            data-testid={propertyName}
            rules={[
                {
                    validator: async (_, value) => {
                        if (!validationEnabled) {
                            return Promise.resolve();
                        }
                        const propertyDoesNotHaveValue = !value || isEmpty(value);
                        if (propertyDoesNotHaveValue) {
                            if (
                                EQUALITY_OPERATORS.includes(propertyOperator) &&
                                !isContactCondtional &&
                                propertyType === "string"
                            ) {
                                return Promise.reject(new Error(EQUALITY_OPERATOR_VALUE_REQUIRED));
                            }
                            return Promise.reject(new Error(REQUIRED_VALUE));
                        }
                        return Promise.resolve();
                    },
                },
            ]}
        >
            {getFormItem()}
        </EnhancedFormItem>
    );
}

export function DatePropertyValue({
    prefixPath,
    parentPropName,
    dateComparisonType,
    propertyOperator,
    direction,
    dependencies,
}: {
    prefixPath: Array<string | number>;
    parentPropName: any;
    dateComparisonType?: string;
    propertyOperator: string;
    direction?: LayoutDirection;
    dependencies?: NamePath[];
}): JSX.Element {
    const { form } = useConditionsList();
    const { validationEnabled } = useValidation();
    return (
        <div
            className={clsx(`flex-${direction === "horizontal" ? "row" : "column"}`)}
            style={{ gap: direction === "horizontal" ? "20px" : "12px" }}
        >
            <Box flex={direction === "horizontal" ? "3" : "1"} style={{ minWidth: "265px" }}>
                <EnhancedFormItem
                    key={`${parentPropName}-${propertyOperator}-dateComparisonType`}
                    name={[parentPropName, "dateComparisonType"]}
                    className={styles.formItem}
                    initialValue="specific"
                    label={"Date Type"}
                >
                    <Select
                        onChange={() => {
                            const value = cloneDeep(form.getFieldsValue());
                            set(value, [...prefixPath, parentPropName, "propertyValue"], undefined);
                            set(value, [...prefixPath, parentPropName, "relativeDateValue"], undefined);
                            form.setFieldsValue(value);
                            form.validateFields([
                                [...prefixPath, parentPropName, "propertyValue"],
                                [...prefixPath, parentPropName, "relativeDateValue"],
                            ]);
                        }}
                    >
                        <Select.Option value="specific">Specific Date (in Eastern Time Zone)</Select.Option>
                        <Select.Option value="relative">Relative Date</Select.Option>
                    </Select>
                </EnhancedFormItem>
            </Box>

            <Box flex={direction === "horizontal" ? "2" : "1"}>
                {/* initialValue is undefined for dateComparisonType */}
                {dateComparisonType === "specific" || dateComparisonType === undefined ? (
                    <EnhancedFormItem
                        key={`${parentPropName}-${propertyOperator}-propertyValue`}
                        name={[parentPropName, "propertyValue"]}
                        label="Date"
                        className={styles.formItem}
                        dependencies={dependencies}
                        rules={[
                            {
                                validator: async (_: any, date: string) => {
                                    if (!validationEnabled) {
                                        return Promise.resolve();
                                    }
                                    if (!date) {
                                        return Promise.reject(new Error("Invalid date"));
                                    }
                                    return Promise.resolve();
                                },
                            },
                        ]}
                    >
                        <DatePickerItem placeholder={!parentPropName ? "Select Date" : undefined} />
                    </EnhancedFormItem>
                ) : (
                    <EnhancedFormItem
                        key={`${parentPropName}-${propertyOperator}-relativeDateValue`}
                        name={[parentPropName, "relativeDateValue"]}
                        className={styles.midFormItem}
                        label="Relative Date"
                        dependencies={dependencies}
                        rules={[
                            {
                                validator: async (_, relativeDateValue: string) => {
                                    if (!validationEnabled) {
                                        return Promise.resolve();
                                    }
                                    if (relativeDateValue && checkValidRelativeDateString(relativeDateValue)) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error("Invalid relative date format"));
                                },
                            },
                        ]}
                    >
                        <Input className={styles.formInput} suffix={<RealtiveDatePopover />} />
                    </EnhancedFormItem>
                )}
            </Box>
        </div>
    );
}

function getFormItemProps({
    isContactCondtional,
    propertyType,
}: {
    isContactCondtional: boolean;
    propertyType: string;
}) {
    let label: string | undefined = isContactCondtional ? "Attribute Value" : "Property Value";
    if (propertyType === "number") {
        label = "Enter Integer or Decimal";
    }

    return { label, initialValue: undefined };
}
