import { useEffect, useState } from "react";

import { Select } from "antd";

import { HighlightedString } from "Components/elements/HighlightedString/HighlightedString";

import { OptionsData } from "../../../shared";
// @TODO make a share RVHighlightSelect
export function HighlightSelect({
    onChange,
    options,
    placeholder,
    value,
    dataTestId,
    ...rest
}: {
    onChange?: (evt: any) => void;
    options: string[];
    placeholder?: string;
    value?: any;
    dataTestId?: string;
    [x: string]: any;
}): JSX.Element {
    const [actualOptions, setActualOptions] = useState<OptionsData>(createEventNameOptionValues(options));

    useEffect(() => {
        setActualOptions(createEventNameOptionValues(options));
    }, [options]);

    function createEventNameValue(v: any, h?: string) {
        return { value: v, label: <HighlightedString key={v} val={v} highlightVal={h} /> };
    }

    function createEventNameOptionValues(values?: Array<string>, highlight?: string): OptionsData {
        return !!values ? values.map((v) => createEventNameValue(v, highlight)) : [];
    }

    function onSearch(value: string) {
        if (!value && onChange) {
            onChange(undefined);
        } else if (value) {
            setActualOptions(createEventNameOptionValues(options, value));
        }
    }

    function onDropDownChange(open: boolean) {
        if (!open) {
            setActualOptions(createEventNameOptionValues(options));
        }
    }

    return (
        <Select
            allowClear
            showSearch
            onChange={onChange}
            onSearch={onSearch}
            onDropdownVisibleChange={onDropDownChange}
            placeholder={placeholder}
            value={value || undefined}
            options={actualOptions}
            data-testid={dataTestId}
            {...rest}
        ></Select>
    );
}
