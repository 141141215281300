import { useEffect, useState } from "react";

import { Select } from "antd";

import { OptionsData } from "../../../shared";
import { flattenSchemaOptions } from "../../AutoCompleteFormItems/shared/AutoCompleteHelpers";

import type { EventSchemaOptionEntry } from "ServerData/EventSchema/eventSchemaTypes";
/**
 * TypeaheadSelect is a custom dropdown component that supports typeahead (search as you type) functionality.
 * It takes an event schema and flattens it into options for the select input.
 * If you need to create new options, you can use the RVSelectCreate component.
 * @component
 * @param {function} [onChange] - Callback function called when the selected value changes.
 * @param {EventSchemaOptionEntry} eventSchema - Schema used to generate options for the select input.
 * @param {string} [placeholder] - Placeholder text for the select input.
 * @param {any} [value] - Current value of the select input.
 * @param {boolean} [hideTypeLabel=false] - Flag to hide type labels in options.
 * @param {string} [dataTestid] - Test ID for the component, useful for testing purposes.
 * @param {string} [className] - Additional CSS class names for the component.
 * @param {boolean} [disabled] - Flag to disable the select input.
 *
 * @returns {JSX.Element} The typeahead select component.
 */
export function TypeaheadSelect({
    onChange,
    eventSchema,
    placeholder,
    value,
    hideTypeLabel = false,
    dataTestid,
    className,
    disabled,
}: {
    onChange?: (evt: any) => void;
    eventSchema: EventSchemaOptionEntry;
    placeholder?: string;
    value?: any;
    hideTypeLabel?: boolean;
    dataTestid?: string;
    className?: string;
    disabled?: boolean;
}): JSX.Element {
    const [options, setOptions] = useState<OptionsData>(flattenSchemaOptions(eventSchema, "", hideTypeLabel));

    useEffect(() => {
        setOptions(flattenSchemaOptions(eventSchema, "", hideTypeLabel));
        // only observe eventSchema
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventSchema]);

    function onSearch(value: string) {
        if (!value && onChange) {
            onChange(undefined);
        } else if (value) {
            setOptions(flattenSchemaOptions(eventSchema, value, hideTypeLabel));
        }
    }

    function onDropDownChange(open: boolean) {
        if (!open) {
            setOptions(flattenSchemaOptions(eventSchema, "", hideTypeLabel));
        }
    }

    return (
        <Select
            allowClear
            showSearch
            onChange={onChange}
            onSearch={onSearch}
            onDropdownVisibleChange={onDropDownChange}
            placeholder={placeholder}
            value={value || undefined}
            options={options}
            data-testid={dataTestid}
            disabled={disabled}
            className={className}
        />
    );
}
