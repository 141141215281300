import { Box, Column, Row } from "Components/shared/Flexbox";

import { LayoutDirection } from "../Types";

export function PropertyContainer({
    left,
    center,
    right,
    direction,
    hidden,
}: {
    left: JSX.Element;
    center?: JSX.Element | null;
    right?: JSX.Element;
    direction: LayoutDirection;
    hidden?: boolean;
}): JSX.Element {
    const hiddenStyle = hidden ? { display: "none" } : {};
    if (direction === "horizontal") {
        return (
            <Row alignItems="stretch" gap={5} style={hiddenStyle}>
                {/* set min width for prop name and prop value to keep them vertical aligned */}
                <Box flex="none" style={{ maxWidth: "310px", minWidth: "310px" }}>
                    {left}
                </Box>
                <Box flex="none" style={{ maxWidth: "210px", minWidth: "210px" }}>
                    {center}
                </Box>
                <Box flex="1">{right}</Box>
            </Row>
        );
    } else {
        return (
            <Column gap={2} style={hiddenStyle}>
                <Box>{left}</Box>
                {center && <Box>{center}</Box>}
                {right && <Box>{right}</Box>}
            </Column>
        );
    }
}
