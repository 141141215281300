import { clsx } from "clsx";

import Icon, { IconSizes } from "Components/elements/Icon";
// TODO move to shared place out of journeys
import { allOperators } from "Pages/journeys/JourneyEditPage/Components/JourneyBuilder/Components/JourneyActionsPanel/NodeEditorPanel/Components/ConditionalFormItems/ConditionalNodeOperators";
import { format as dateFormat, DateFormat } from "Services/TimeService";
import { NodeTriggerType } from "Types/Journey";
import { ConditionalType } from "Types/Segment";

import styles from "./ReadableExpression.module.scss";

export const eventLabel: Record<string, string> = {
    contactAttribute: "Contact Attribute",
    customEvent: "Custom Event",
    regalVoiceEvent: "Regal Voice Event",
    triggeringEvent: "Triggering Event",
};

const operatorNameByValue: Record<string, string> = allOperators.reduce((previous, current) => {
    previous[current.value] = current.name;
    return previous;
}, {} as Record<string, string>);

function getOperatorName(value?: string): string {
    if (value) {
        return operatorNameByValue[value] || value; // fallback on value
    }
    return "";
}

function getValue({
    value,
    dateComparisonType,
    relativeDateValue,
}: {
    value?: string | number;
    dateComparisonType?: string;
    relativeDateValue?: string;
}) {
    if (dateComparisonType === "relative") {
        return relativeDateValue;
    } else if (dateComparisonType === "specific" && typeof value === "string") {
        return dateFormat(value as string, { format: DateFormat.PRETTY_AT });
    } else if (Array.isArray(value)) {
        // we want to make sure we have whitespace so we can wrap
        return value.join(", ");
    }
    return value;
}

export function ReadableExpression({
    property,
    operator,
    value,
    dateComparisonType,
    relativeDateValue,
    hidden,
}: {
    property?: string;
    operator?: string;
    value?: string | number;
    dateComparisonType?: string;
    relativeDateValue?: string;
    hidden: boolean;
}): JSX.Element | null {
    if (hidden) {
        return null;
    }
    return (
        <div className={clsx(styles.container, styles.properties)}>
            <Icon icon="relation" size={IconSizes.SMALL} className={styles.icon} />
            <span>{property}</span>
            <strong>{getOperatorName(operator)}</strong>
            <span className={styles.propValue}>{getValue({ value, dateComparisonType, relativeDateValue })}</span>
        </div>
    );
}

export function ReadableCondition({
    conditionalType,
    eventName,
    operator,
    hidden,
    fieldsLength,
}: {
    conditionalType?: ConditionalType | NodeTriggerType;
    eventName?: string;
    operator?: string | undefined;
    hidden: boolean;
    fieldsLength: number;
}): JSX.Element | null {
    if (hidden) {
        return null;
    }
    const conditionalName = conditionalType ? eventLabel[conditionalType] : "";
    if (conditionalType === "contactAttribute") {
        return (
            <div className={styles.container}>
                <strong>{`${conditionalName}`}</strong>
            </div>
        );
    }
    return (
        <div className={styles.container}>
            <strong>{conditionalName}</strong>
            <span>{eventName}</span>
            <strong>{getOperatorName(operator)}</strong>
            {fieldsLength > 0 ? "where" : ""}
        </div>
    );
}
