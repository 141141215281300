import { gql } from "@apollo/client";

export const getProfilesById = gql`
    query ($profileIds: [String!]!) {
        getProfilesById(profileIds: $profileIds) {
            id
            name
            rvProperties
            optIn
            customProperties
            subscribedSms
            subscribedVoice
            subscribedEmail
            contactPhone
            email
            firstName
            lastName
            externalId
            lastContactedAt
            phones {
                label
                phoneNumber
                capabilities
                isPrimary
                voiceOptIn {
                    subscribed
                }
                smsOptIn {
                    subscribed
                }
            }
            emails {
                label
                email
                isPrimary
                emailOptIn {
                    subscribed
                }
            }
        }
    }
`;
