import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RVTask } from "@regal-voice/shared-types";
import { omit } from "lodash";

export interface TasksState {
    [sid: string]: RVTask;
}

export interface TaskCreatedDate {
    [taskSid: string]: string;
}

export interface WaitingForTaskState {
    waiting: boolean;
    contactPhone?: string;
    taskType?: string;
    waitingSince?: number;
}

export interface InternalTaskState {
    tasks: TasksState;
    // mapping of taskSid to task created date
    taskCreatedDateBySid: TaskCreatedDate;
    waitingForTaskState: WaitingForTaskState;
}

const initialState: InternalTaskState = {
    tasks: {},
    taskCreatedDateBySid: {},
    waitingForTaskState: { waiting: false },
};

const MAX_TASKS = 100;
function updateTasksCreatedDate(taskCreatedDateBySid: TaskCreatedDate, newTasks: TasksState): TaskCreatedDate {
    const mapTasksByTasksid = Object.fromEntries(
        // bug bash clean up, I think our typing is off somewhere.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Object.values(newTasks || {}).map((task) => [task.taskSid, task.reservationCreatedDate!])
    );
    // merge all tasks
    const allTasks = { ...taskCreatedDateBySid, ...mapTasksByTasksid };
    // keep only latest 100 tasks: sort allTasks by reservationCreatedDate and remove oldest
    const latestTasks = Object.entries(allTasks)
        .sort(([_leftTaskSid, leftCreatedDate], [_rightTaskSid, rightCreatedDate]) => {
            return new Date(rightCreatedDate).getDate() - new Date(leftCreatedDate).getDate();
        })
        .slice(0, MAX_TASKS);
    // transform latestTask array to TaskCreatedDate
    return Object.fromEntries(latestTasks);
}

const TasksStateSlice = createSlice({
    name: "TasksState",
    initialState,
    reducers: {
        setWaitingForTask(state, action: PayloadAction<{ contactPhone: string; taskType: string }>) {
            const { contactPhone, taskType } = action.payload;
            state.waitingForTaskState = {
                waiting: true,
                contactPhone,
                taskType,
                waitingSince: Date.now(),
            };
        },
        clearWaitingForTaskState(state) {
            state.waitingForTaskState = {
                waiting: false,
                contactPhone: undefined,
                taskType: undefined,
                waitingSince: undefined,
            };
        },
        setTask(state, action: PayloadAction<RVTask>) {
            state.tasks[action.payload.sid as string] = action.payload;
            state.taskCreatedDateBySid = updateTasksCreatedDate(state.taskCreatedDateBySid, state.tasks);
        },
        setTasksAction(state, action: PayloadAction<TasksState>) {
            state.tasks = action.payload;
            state.taskCreatedDateBySid = updateTasksCreatedDate(state.taskCreatedDateBySid, state.tasks);
        },
        removeTasksBySid(state, action: PayloadAction<string[]>) {
            state.tasks = omit(state.tasks, action.payload);
            state.taskCreatedDateBySid = updateTasksCreatedDate(state.taskCreatedDateBySid, state.tasks);
        },
        clearTasks(state) {
            state.tasks = {};
        },
    },
});

export const tasksStateActions = TasksStateSlice.actions;
export const { setWaitingForTask, clearWaitingForTaskState, setTask, setTasksAction, removeTasksBySid, clearTasks } =
    TasksStateSlice.actions;
export default TasksStateSlice.reducer;
