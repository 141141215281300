import { createSchemaOption } from "Components/shared/RVEventSchemaOption/RVEventSchemaOption";

import { OptionsData } from "../../../shared";

import type { EventSchemaOptionEntry } from "ServerData/EventSchema/eventSchemaTypes";

export function flattenSchemaOptions(
    eventSchema: EventSchemaOptionEntry,
    highlightVal: string,
    hideTypeLabel: boolean
): OptionsData {
    const schemaKeys: OptionsData = [];

    if (!eventSchema) {
        return [];
    }

    Object.entries(eventSchema).forEach(([key, val]) =>
        schemaKeys.push(createSchemaOption(key, val.fieldType, highlightVal, hideTypeLabel))
    );
    return schemaKeys.sort((a, b) =>
        a.value.toLowerCase() > b.value.toLowerCase() ? 1 : b.value.toLowerCase() > a.value.toLowerCase() ? -1 : 0
    );
}
