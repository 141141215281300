import { InfoCircleOutlined } from "@ant-design/icons";
import { Typography, Skeleton, Tooltip } from "antd";

import { Row } from "Components/shared/Flexbox";
import { getFilledPercentage } from "Components/shared/RVData/utils/dataUtils";

import styles from "./FillChart.module.scss";

type FillChartProps = {
    percentage: number;
    hasMinDisplay: boolean;
};

export function FillBar({ percentage, hasMinDisplay }: FillChartProps): JSX.Element {
    const widthPercent = getFilledPercentage(percentage, hasMinDisplay);
    return (
        <div className={styles.fillBar}>
            <div data-testid="fill-bar-display" className={styles.fillDisplay} style={{ width: widthPercent }}></div>
        </div>
    );
}

type ChartProps = {
    loading: boolean;
    chartHeader: string;
    chartHeaderSubtitle: string;
    chartHeaderInfoDescriptor: string;
    chartHeaderInfoValue: string;
    chartSubtextZero: string;
    chartSubtextFull: string;
    percentage: number;
    hasMinDisplay?: boolean;
    headerTooltip?: string;
};

export function FillChart({
    loading,
    chartHeader,
    headerTooltip,
    chartHeaderSubtitle,
    chartHeaderInfoDescriptor,
    chartHeaderInfoValue,
    chartSubtextZero,
    chartSubtextFull,
    percentage,
    hasMinDisplay = false,
}: ChartProps): JSX.Element {
    if (loading) {
        return (
            <div data-testid={`fill-chart-loading-${chartHeader}`}>
                <Skeleton active paragraph={{ rows: 1, width: "100%" }} />
            </div>
        );
    }

    return (
        <div>
            <Row justifyContent="space-between" className={styles.titleRow}>
                <span>
                    <Typography.Text className={styles.boldText}>{chartHeader}</Typography.Text>
                    {headerTooltip ? (
                        <Tooltip title={headerTooltip} trigger="hover" placement="bottom">
                            <InfoCircleOutlined className={styles.info} />
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                </span>
                <Typography.Text className={styles.slimText}>{chartHeaderSubtitle}</Typography.Text>
            </Row>
            <Row className={styles.titleInfo}>
                <span>
                    <Typography.Text className={styles.boldText}>{chartHeaderInfoValue}</Typography.Text>
                    &nbsp;
                    <Typography.Text className={styles.slimTextDark}>{chartHeaderInfoDescriptor}</Typography.Text>
                </span>
            </Row>
            <FillBar percentage={percentage} hasMinDisplay={hasMinDisplay}></FillBar>
            <Row className={styles.subtextInfo} justifyContent="space-between">
                <Typography.Text className={styles.slimText}>{chartSubtextZero}</Typography.Text>
                <Typography.Text className={styles.slimText}>{chartSubtextFull}</Typography.Text>
            </Row>
        </div>
    );
}
