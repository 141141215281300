import { RVNumericArrayInput } from "Components/FormItems/Inputs/RVNumericArrayInput/RVNumericArrayInput";

import { NumericOnlyInput } from "../../Shared/SharedInputs/NumericOnlyInput";

export function NumericInputValue({
    rootField,
    propertyOperator,
    propertyValueDisabled,
    ...rest
}: {
    rootField?: any;
    propertyOperator: string;
    propertyValueDisabled: boolean;
}): JSX.Element {
    return propertyOperator === "isOneOf" ? (
        <RVNumericArrayInput {...rest} />
    ) : (
        <NumericOnlyInput
            placeholder={!rootField ? "Enter Integer or Decimal" : undefined}
            disabled={propertyValueDisabled}
            {...rest}
        />
    );
}
