import { useContext, useMemo } from "react";

import { Layout } from "antd";

import { UserContext } from "App/contexts";
// todo: the RVSidebarItem is only used here, move it to live within the SideNavBar component's directory
import { RVSidebarItem } from "Components/containers/RVSidebarItem/RVSidebarItem";
import { userHasRoles } from "Services/AuthService";
import { useFlags } from "Services/FeatureFlagService";
import { UserRole } from "Types/Auth";

import { Subnav } from "./Subnav/Subnav";

import layout from "./Layout.module.scss";

export default function SideNavBar(): JSX.Element {
    const { user } = useContext(UserContext);
    const {
        segments,
        campaigns,
        recentActivity,
        tasks,
        journeys,
        adminSettings,
        dashboards,
        audiencePage,
        agentsPage,
        queuesPage,
        brandNumbers,
        regalLivePage,
        transcriptionsPage,
        hasAiAgentsDemo,
    } = useFlags();

    const isAdmin = useMemo(() => {
        return userHasRoles(user, [UserRole.ADMIN, UserRole.SUPERVISOR]);
    }, [user]);

    return (
        <>
            <Layout.Sider collapsed={true} collapsedWidth={64} className={layout.sidebar}>
                {regalLivePage && (
                    <RVSidebarItem href="/regal-live" icon="regal-live">
                        Regal Live
                    </RVSidebarItem>
                )}
                <RVSidebarItem href="/agent" hotkey="d" icon="headset">
                    Agent Desktop
                </RVSidebarItem>

                {campaigns && (
                    <RVSidebarItem href="/campaigns" hotkey="c" icon="campaign">
                        Campaigns
                    </RVSidebarItem>
                )}

                {journeys && (
                    <RVSidebarItem href="/journeys" hotkey="j" icon="journey">
                        Journeys
                    </RVSidebarItem>
                )}

                {audiencePage && (
                    <RVSidebarItem href="/audience" hotkey="u" icon="audience">
                        Audience
                    </RVSidebarItem>
                )}

                {segments && (
                    <RVSidebarItem href="/segments" hotkey="s" icon="segments">
                        Segments
                    </RVSidebarItem>
                )}

                {agentsPage && (
                    <RVSidebarItem href="/agents" hotkey="a" icon="agents">
                        Agents
                    </RVSidebarItem>
                )}

                {queuesPage && (
                    <RVSidebarItem href="/queues" hotkey="q" icon="queues">
                        Queues
                    </RVSidebarItem>
                )}

                {tasks && (
                    <RVSidebarItem href="/tasks" activeRoutes={["/recent-tasks"]} hotkey="t" icon="tasks">
                        Tasks
                    </RVSidebarItem>
                )}

                {brandNumbers && (
                    <RVSidebarItem href="/phone-numbers" hotkey="p" icon="number">
                        Phone Numbers
                    </RVSidebarItem>
                )}

                {transcriptionsPage && (
                    <RVSidebarItem href="/transcripts" hotkey="l" icon="transcripts">
                        Conversation Intelligence
                    </RVSidebarItem>
                )}

                {recentActivity && (
                    <RVSidebarItem href="/recent-activity" hotkey="r" icon="recent-activity">
                        Recent Activity
                    </RVSidebarItem>
                )}
                {hasAiAgentsDemo && (
                    <RVSidebarItem href="/ai-agents" hotkey="i" icon="ai-agents">
                        AI Agents
                    </RVSidebarItem>
                )}
                <div className={layout.sidebarBottomItem}>
                    {dashboards && (
                        <RVSidebarItem href="/reporting" hotkey="e" icon="bar-chart">
                            Reporting
                        </RVSidebarItem>
                    )}

                    {adminSettings && isAdmin && (
                        <RVSidebarItem
                            href="/settings/general-settings"
                            hotkey="g"
                            icon="settings"
                            // we want all subroutes of /settings to display the settings sidebar item as active
                            activeRoutes={["/settings/*"]}
                        >
                            Settings
                        </RVSidebarItem>
                    )}
                </div>
            </Layout.Sider>
            <Subnav />
        </>
    );
}
