import { createAction } from "@reduxjs/toolkit";
import { message } from "antd";
import { Middleware } from "redux";

import { renderErrorMessage } from "Services/LoggingService";

export const KNOWN_ERRORS = {
    TaskNotAvailable: "This task is no longer available",
    MissingAgentUri: "Reservation could not be accepted. Try logging out and logging back in.",
    CanceledTransfer: "Transfer was cancelled.",
    CallEnded: "Call was ended by contact.",
    CallConnectionFailure: "Call could not be connected",
    CallCreationFailure: "Could not create the outbound call",
} as const;

export type KnownError = keyof typeof KNOWN_ERRORS;

export type ErrorPayload = {
    error?: Error;
    extraData?: Record<string, unknown>;
} & ({ type: KnownError } | { type: "unknown"; content: string });

export type SuccessMessagePayload = {
    content: string;
    duration?: number;
};

const errorActionType = "ERROR";
const successActionType = "SUCCESS";
const TOAST_DISPLAY_LENGTH_DEFAULT_SEC = 3;

export const handleError = createAction<ErrorPayload>(errorActionType);
export const handleSuccess = createAction<SuccessMessagePayload>(successActionType);

export const globalMessageMiddleware: Middleware = () => (next) => (action) => {
    if (action.type === errorActionType) {
        const { type, error, extraData } = action.payload as ErrorPayload;
        renderErrorMessage({
            content: type == "unknown" ? action.payload.content : KNOWN_ERRORS[type],
            error,
            extraData,
        });
    } else if (action.type === successActionType) {
        const { content, duration } = action.payload as SuccessMessagePayload;
        message.success(content, duration || TOAST_DISPLAY_LENGTH_DEFAULT_SEC);
    }

    return next(action);
};
