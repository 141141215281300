import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { NewEmailMeta } from "./EmailUISlice";

export type EmailUIPersistState = Record<string, NewEmailMeta>;

export function getInitialReducerState(): EmailUIPersistState {
    return {};
}

// The slice
const emailUIPersistSlice = createSlice({
    name: "emailUIPersistSlice",
    initialState: getInitialReducerState(),
    reducers: {
        persistDataForThread: (
            state,
            action: PayloadAction<{
                key: string;
                data: NewEmailMeta;
            }>
        ) => {
            state[action.payload.key] = action.payload.data;
        },

        clearPersistedDataForThread: (state, action: PayloadAction<string>) => {
            delete state[action.payload];
        },
    },
});

export const { persistDataForThread, clearPersistedDataForThread } = emailUIPersistSlice.actions;

export default emailUIPersistSlice.reducer;
