// adapted from https://github.com/launchdarkly/js-eventsource/commit/a127dfe13ee523d0dfea28304552d9f73b4dc1a6

type MonitorAndRetryConfig = {
    jitterRatio?: number; // each delay will be reduced by a randomized jitter of up to 50%
    maxBackoffMillis?: number;
    currentBaseDelay: number;
    resetIntervalMillis?: number;
};

type GetBackOffMillis = (baseDelayMillis: number, retryCount: number) => number;
type GetJitterMillis = ((computedDelayMillis: number) => number) | undefined;

type RetryDelay = {
    setGoodSince: (goodSinceTimeMillis?: number) => void;
    getNextRetryDelay: (currentTimeMillis?: number) => number;
};

function retryDelay(
    config: MonitorAndRetryConfig = {
        jitterRatio: 0.5,
        maxBackoffMillis: 30000, // enables backoff, with a maximum of 30 seconds
        currentBaseDelay: 0, // sets initial retry delay to 1 seconds;
        resetIntervalMillis: 60000, // backoff will reset to initial level if stream got an event at least 60 seconds before failing
    }
): RetryDelay {
    let goodSince: number | null = null;
    let retryCount = 0; // used to calculate exponential backoff
    const getBackoff = config.maxBackoffMillis ? defaultBackoff(config.maxBackoffMillis) : undefined;
    const getJitter = config.jitterRatio ? defaultJitter(config.jitterRatio) : undefined;

    function setGoodSince(goodSinceTimeMillis = Date.now()): void {
        goodSince = goodSinceTimeMillis;
    }

    function defaultJitter(ratio: number): GetJitterMillis {
        return function (computedDelayMillis: number) {
            return computedDelayMillis - Math.trunc(Math.random() * ratio * computedDelayMillis);
        };
    }

    function defaultBackoff(maxDelayMillis: number): GetBackOffMillis {
        return function (baseDelayMillis: number, retryCount: number): number {
            const delay = baseDelayMillis * Math.pow(2, retryCount);
            return delay > maxDelayMillis ? maxDelayMillis : delay;
        };
    }

    /**
     * getNextRetryDelay will return the delay calculate with the specified backoff and jitter
     * Note: backoff will reset to initial level (i.e. retryCount=0) if stream got an event at least resetIntervalMillis before failing
     * @param {number} [currentTimeMillis=now] - the time from when the next delay should be calculated
     * @returns the delay in ms to use for the next retry request
     */
    function getNextRetryDelay(currentTimeMillis = Date.now()): number {
        if (goodSince && config.resetIntervalMillis && currentTimeMillis - goodSince >= config.resetIntervalMillis) {
            retryCount = 0;
        }
        goodSince = null;
        const delay = getBackoff ? getBackoff(config.currentBaseDelay, retryCount) : config.currentBaseDelay;
        retryCount++;
        return getJitter ? getJitter(delay) : delay;
    }

    return {
        setGoodSince,
        getNextRetryDelay,
    };
}

export default retryDelay;
