import { useReducer } from "react";

export function useToggle(initialValue = false): [boolean, (nextState: boolean) => void] {
    // const [toggle, setToggle] = useToggle(initialValue);
    // setToggle function is stable (it will not change on every render)
    // so it does not need to be wrapped in useCallback
    return useReducer(
        // toggle if nothing passed to dispatch
        // else use the value passed to dispatch
        (state: boolean, nextState: boolean) => nextState ?? !state,
        initialValue
    );
}
