import { useMemo } from "react";

import { useQuery } from "@apollo/client";

import { GetTeamsData } from "../queries";

import type { Team } from "Types/Team";

type HookReturn = {
    teamsList: Array<Team>;
    loading: boolean;
};

export const useShareableContactsTeamData = (): HookReturn => {
    const { data, loading } = useQuery<{
        getTeams: Array<Team>;
    }>(GetTeamsData, {
        fetchPolicy: "network-only",
    });

    const teamsList = useMemo(() => {
        return data?.getTeams ?? [];
    }, [data]);

    return {
        loading,
        teamsList,
    } as const;
};
