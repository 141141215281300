import { useMemo, useState } from "react";

import { TooltipPlacement } from "antd/lib/tooltip";
import { debounce, uniqBy } from "lodash";

import { AutosuggestEditor } from "./Components/AutosuggestEditor/AutosuggestEditor";
import { HandlebarsTag } from "./Components/HandlebarsTag/HandlebarsTag";
import { WebhookAttributesAutosuggest } from "./Components/WebhookAutosuggest/WebhookAttributesAutosuggest";
import { DEFAULT_HANDLEBARS } from "./constants";
import {
    modifyHandlebarsTag,
    findHandlebarsByGrammarDecorator,
    findHandlebarsTagEntitiesDecorator,
} from "./Shared/Helpers/handlebarHelpers";

export enum Variant {
    Editor = "EDITOR",
    Input = "INPUT",
}
type AutosuggestFormProps = {
    value?: string;
    onChange?: (value: string | undefined) => void;
    contactEventOptions: { value: string; label: string; dataType: string }[];
    eventOptions: { value: string; label: string; dataType: string }[];
    variant?: Variant;
    placeholder?: string;
    autofocus?: boolean;
    hideOverlayArrow?: boolean;
    sufix?: JSX.Element;
    popoverPlacement?: TooltipPlacement;
    dataTestid?: string;
};

export const JSON_DECORATORS = [
    {
        strategy: findHandlebarsTagEntitiesDecorator,
        component: HandlebarsTag,
    },
    {
        strategy: findHandlebarsByGrammarDecorator,
        component: HandlebarsTag,
    },
];

export const RVJSONHandlebarsAutosuggest: React.FC<AutosuggestFormProps> = ({
    value,
    onChange,
    contactEventOptions,
    eventOptions,
    variant = Variant.Editor,
    placeholder,
    autofocus,
    hideOverlayArrow,
    sufix,
    popoverPlacement,
    dataTestid,
}: AutosuggestFormProps) => {
    const [autoSuggestData, setAutoSuggestData] = useState<{ searchTerm?: string; renderSuggestion: any }>({
        searchTerm: undefined,
        renderSuggestion: undefined,
    });

    const filterResults = useMemo(() => {
        const searchTerm = autoSuggestData?.searchTerm?.split(DEFAULT_HANDLEBARS)?.[1]?.trim() || "";
        const items = [...contactEventOptions, ...eventOptions].filter(
            (item) => !searchTerm || item?.value?.toLowerCase().indexOf(searchTerm?.toLowerCase()) >= 0
        );
        return {
            searchTerm,
            items: uniqBy(items, "value"),
        };
    }, [autoSuggestData?.searchTerm, contactEventOptions, eventOptions]);

    const onSearchTermChanges = useMemo(
        () =>
            debounce((newValues) => {
                setAutoSuggestData(newValues);
            }, 300),
        []
    );

    return (
        <AutosuggestEditor
            hasSuggestions={!!filterResults?.items?.length}
            value={value}
            onChange={onChange}
            decorators={JSON_DECORATORS}
            trigger={DEFAULT_HANDLEBARS}
            renderTagFn={modifyHandlebarsTag}
            onSeachTermChange={onSearchTermChanges}
            variant={variant}
            placeholder={placeholder}
            autofocus={autofocus}
            hideOverlayArrow={hideOverlayArrow}
            sufix={sufix}
            popoverPlacement={popoverPlacement}
            dataTestid={dataTestid}
        >
            <WebhookAttributesAutosuggest
                searchTerm={filterResults?.searchTerm}
                items={filterResults?.items}
                renderSuggestion={autoSuggestData.renderSuggestion}
            />
        </AutosuggestEditor>
    );
};
