import { combineReducers } from "redux";

import activeTaskReducer from "./ActiveTaskSlice";
import tasksReducer from "./TasksStateSlice";
import tasksToAutocompleteReducer from "./TasksToAutocompleteSlice";
import taskSummaryReducer from "./TaskSummaryModalSlice";

export type TasksState = {
    tasks: ReturnType<typeof tasksReducer>;
    activeTask: ReturnType<typeof activeTaskReducer>;
    taskSummaryModal: ReturnType<typeof taskSummaryReducer>;
    tasksToAutocomplete: ReturnType<typeof tasksToAutocompleteReducer>;
};

export default combineReducers<TasksState>({
    tasks: tasksReducer,
    activeTask: activeTaskReducer,
    taskSummaryModal: taskSummaryReducer,
    tasksToAutocomplete: tasksToAutocompleteReducer,
});
