import { gql } from "@apollo/client";

type StatsData = {
    smsCount: number;
    callCount: number;
    emailCount: number;
    targetSms: number;
    targetCall: number;
    targetEmail: number;
};

export type GetAgentStatsState = {
    getDailyStats: StatsData;
};

export const GET_AGENT_TASK_STATS = gql`
    query {
        getDailyStats {
            smsCount
            callCount
            targetSms
            targetCall
            emailCount
            targetEmail
        }
    }
`;
