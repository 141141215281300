import { gql } from "@apollo/client";

import { Campaign, CommunicationSources } from "Types/Campaign";

export const getCampaigns = gql`
    query ($queryParams: QueryParams!) {
        campaigns(queryParams: $queryParams) {
            id
            friendlyId
            name
            state
            messageType
            type
            description
            createdAt
            updatedAt
            createdBy
            updatedBy
        }
    }
`;

export const getCampaignsWithPreview = gql`
    query ($queryParams: QueryParams!) {
        campaigns(queryParams: $queryParams) {
            id
            friendlyId
            name
            state
            messageType
            type
            smsCampaign {
                id
                content
                mediaURL
                fromNumber
            }
        }
    }
`;

export const getPaginatedCampaigns = gql`
    query GetPaginatedCampaigns($queryParams: QueryParams!) {
        getCampaigns(queryParams: $queryParams) {
            hasMore
            items {
                id
                friendlyId
                name
                state
                messageType
                type
                description
                phoneCallCampaign {
                    id
                    dialingMode
                    priority
                }
                createdAt
                updatedAt
                createdBy
                updatedBy
            }
        }
    }
`;

export const getCampaignAgents = gql`
    query ($queryParams: QueryParams!) {
        campaigns(queryParams: $queryParams) {
            createdBy
            updatedBy
        }
    }
`;

export type CampaignByIdResponse = {
    campaignWithUnhiddenJourneys: Campaign;
};

export const getCampaignById = gql`
    query ($id: String!) {
        campaignWithUnhiddenJourneys(id: $id) {
            id
            friendlyId
            name
            description
            state
            messageType
            type
            conversionEvent
            bypassOptIn
            segments {
                id
                count
            }
            campaignsJourneys {
                journey {
                    id
                    friendlyId
                    name
                    state
                }
            }
            smsCampaign {
                id
                content
                mediaURL
                fromNumber
            }
            phoneCallCampaign {
                id
                nickname
                fromNumber
                dialingMode
                goal
                scriptUrl
                voicemailInstructions
                prerecordedVoiceMessage {
                    id
                    friendlyName
                }
                abandonedRecording {
                    id
                    friendlyName
                }
                greetingRecording {
                    id
                    friendlyName
                }
                defaultVoicemail
                eligibleOffer
                scriptUrl
                targetQueueSid
                maxParallelCalls
                priority
            }
        }
    }
`;

export type CommunicationSourcesResponse = {
    getCommunicationSources: CommunicationSources[];
};

export const getCommunicationSources = gql`
    query ($channel: String) {
        getCommunicationSources(channel: $channel) {
            internalDisplayName
            externalDisplayName
            source
        }
    }
`;

export const getRenderedSMSCampaignTemplate = gql`
    query ($renderedSMSTemplateInput: RenderedSMSTemplateInput!) {
        getRenderedSMSCampaignTemplate(renderedSMSTemplateInput: $renderedSMSTemplateInput) {
            smsContentTemplate
            renderedSMSContentTemplate
        }
    }
`;
