import { Form, Select } from "antd";
import { NamePath } from "antd/lib/form/interface";
import { clsx } from "clsx";

import { useFlags } from "Services/FeatureFlagService";
import { JoinType } from "Types/Segment";

import styles from "./JoinSelect.module.scss";

export type JoinSelectProps = {
    name: NamePath;
    prefix: string;
    sufix: string;
};
export function JoinSelect({ name, prefix, sufix }: JoinSelectProps): JSX.Element {
    const { showNorOperator } = useFlags();
    return (
        <div className={clsx(styles.joinContainer, "flex-row align-center")}>
            {prefix}&nbsp;&nbsp;
            <Form.Item name={name} noStyle>
                <Select size="middle" className={styles.joinSelect} data-testid={`join-select-${name}`}>
                    <Select.Option value={JoinType.ALL}>All</Select.Option>
                    <Select.Option value={JoinType.ANY}>Any</Select.Option>
                    {showNorOperator && <Select.Option value={JoinType.NONE}>None</Select.Option>}
                </Select>
            </Form.Item>
            &nbsp;&nbsp;{sufix}
        </div>
    );
}
