import { combineReducers } from "redux";

import businessProfilesReducer from "./BusinessProfilesStateProvider";

export type PhoneNumbersState = {
    businessProfiles: ReturnType<typeof businessProfilesReducer>;
};

export default combineReducers<PhoneNumbersState>({
    businessProfiles: businessProfilesReducer,
});
