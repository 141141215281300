// there are still a few edge cases that this regex doesn't cover
// (a single-word domain without TLD)
// (IPv4 address as domain)
// (IPv6 address as domain)
/**
 * @todo all the versions of this and move to a shared file
 */
const STRICT_EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function validateEmail(email: string) {
    return STRICT_EMAIL_REGEX.test(email);
}
